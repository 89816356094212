import { Box, Stack, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IUser } from '@shared/types'
import { CopyButton } from '@shared/ui'
import { convertStatusValueToColoredCircle } from './convert-status-value-to-colored-circle'

interface IProps {
  user?: IUser
  sx?: SxProps
}

export function MainUserInfoList({ user, sx }: IProps) {
  const { t } = useTranslation('pages')

  return (
    <Box sx={{ p: 2, ...sx }}>
      <Stack direction="row">
        <Box>
          <Stack direction="row" sx={{ mb: 1 }} gap={2} alignItems="center">
            <Typography variant="h5">
              {user?.firstName} {user?.lastName}
            </Typography>
          </Stack>

          <Box>
            <Typography variant="subtitle2" color="GrayText">
              id: {user?.id}
            </Typography>

            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="subtitle2" color="GrayText">
                {user?.emailAddress}
              </Typography>

              <CopyButton value={user?.emailAddress} />
            </Stack>
          </Box>

          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="subtitle2" color="GrayText">
              {user?.phoneNumber}
            </Typography>

            <CopyButton value={user?.phoneNumber} />
          </Stack>
        </Box>

        <Stack direction="column" sx={{ ml: 'auto' }}>
          <Typography variant="caption" color="GrayText">
            {t('user.object keys.keycloackId')}: {user?.keycloackId}
          </Typography>

          <Typography variant="caption" color="GrayText">
            {t('user.object keys.createdAt')}: {user?.createdAt}
          </Typography>

          <Stack direction="row" gap={1} alignItems="center" mt={2}>
            <Typography variant="subtitle2">
              {t('user.object keys.statusOndato')}:
            </Typography>

            {convertStatusValueToColoredCircle({
              value: user?.statusOndato,
              objectKey: 'statusOndato',
            })}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
