import { ListItemButton, styled } from '@mui/material'

interface IProps {
  open?: boolean
}

export const SidebarMenuListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'open',
})<IProps>(({ theme, open }) => ({
  padding: '12px 16px',
  transition: 'background-color 0.3s',
  borderRadius: '10px',
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    '& .MuiSvgIcon-root': {
      color: theme.palette.background.paper,
    },
  },
  fontSize: '16px',
  fontWeight: 500,
  marginBottom: 8,
  borderBottom: 0,
  ...(!open && {
    padding: '12px 14px',
  }),

  '& .MuiListItemIcon-root': {
    width: 28,
    height: 28,
    minWidth: 'auto',
    display: 'flex',
    alignItems: 'center',

    '& .MuiListItemText-root': {
      margin: 0,
    },
  },
}))
