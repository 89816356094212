import { useState } from 'react'

import {
  TFilterStatusType,
  TFilterIncomingTypeType,
  TFilterTransactionTypeType,
} from '@entities/transaction'

import {
  cryptoTransactionStatuses,
  cryptoTransactionTypes,
  incomingTypes,
} from '@shared/types'
import { IFilterOptions } from '@shared/ui'

const statusFilterOptions: TFilterStatusType[] = [
  'all',
  ...cryptoTransactionStatuses,
]

const incomingTypeFilterOptions: TFilterIncomingTypeType[] = [
  'all',
  ...incomingTypes,
]

const typeFilterOptions: TFilterTransactionTypeType[] = [
  'all',
  ...cryptoTransactionTypes,
]

export function useCryptoTransactionsFilters(withIncomingTypeFilter?: boolean) {
  const [currentStatus, setCurrentStatus] = useState<TFilterStatusType>('all')

  const [currentType, setCurrentType] =
    useState<TFilterTransactionTypeType>('all')

  const [currentIncomingType, setCurrentIncomingType] =
    useState<TFilterIncomingTypeType>('all')

  const filtersOptions: IFilterOptions[] = [
    {
      options: statusFilterOptions,
      currentOption: currentStatus,
      setCurrentOption: setCurrentStatus,
      filterName: 'status',
    },
    {
      options: typeFilterOptions,
      currentOption: currentType,
      setCurrentOption: setCurrentType,
      filterName: 'type',
    },
    ...(withIncomingTypeFilter
      ? [
          {
            options: incomingTypeFilterOptions,
            currentOption: currentIncomingType,
            setCurrentOption: setCurrentIncomingType,
            filterName: 'incoming type',
          },
        ]
      : []),
  ]

  return { filtersOptions, currentType, currentStatus, currentIncomingType }
}
