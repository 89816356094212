import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { ExchangePage } from '../ui'

export const ExchangePageRouter: RouteObject = {
  path: routePaths.exchanges.exchange,
  element: <ExchangePage />,
}
