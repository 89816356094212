import { SxProps } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IExchange } from '@entities/exchange'
import { useExchangeDetails } from '../model'
import { ExchangeCardInfo, IExchangeCardInfoData } from './components'

interface IProps {
  exchange?: IExchange
  sx?: SxProps
}

export function ExchangeBaseInfo({ exchange, sx }: IProps) {
  const { t } = useTranslation('widgets')
  const { transformKeyToDataRow } = useExchangeDetails({ exchange })

  const baseInfo: IExchangeCardInfoData[] = useMemo(() => {
    if (!exchange) {
      return []
    }

    const keys: Array<keyof Omit<IExchange, 'wallet'>> = [
      'status',
      'type',
      'method',
      'sellingAmount',
      'sendToKraken',
      'rate',
      'getFromKraken',
      'buyingAmount',
      'createdAt',
      'ip',
    ]

    return keys.map(transformKeyToDataRow)
  }, [exchange])

  return (
    <ExchangeCardInfo
      title={t('exchange-details.base-info-title')}
      data={baseInfo}
      sx={sx}
    />
  )
}
