import { Trans } from 'react-i18next'

import { useTimer } from '@shared/hooks'

import { TimerText } from '../styled'

interface IProps {
  timer?: number
  onFinish?: () => void
}

export function Timer({ timer, onFinish }: IProps) {
  const { time } = useTimer({ timer, onFinish: onFinish })

  return (
    <TimerText sx={{ mt: { xs: '36px', md: '28px' } }}>
      <Trans
        ns="shared"
        i18nKey="otp dialog.timer text"
        components={{ span: <span /> }}
        values={{
          seconds: time,
        }}
      />
    </TimerText>
  )
}
