import { FormControl, styled } from '@mui/material'

export const StyledFormControl = styled(FormControl)(() => ({
  flexDirection: 'row',
  gap: 16,
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 4,
  border: '1px solid blue',
  borderRadius: 6,
  '& .MuiInputBase-root': {
    marginTop: 0,
  },
}))
