import { styled } from '@mui/material'

export const StyledInfoBox = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  background: theme.palette.primary.light,
  borderRadius: '10px',
  width: 288,
}))
