import { CircularProgress, StepIconProps, Typography } from '@mui/material'
import { LoadingIconWrapper, StyledCircleIcon } from './styled'

export const getLoadingStepIcon = (activeStep: number) => {
  const LoadingStepIcon = (props: StepIconProps) => {
    return (
      <LoadingIconWrapper sx={{ ...props.sx }}>
        <CircularProgress size={24} sx={{ position: 'absolute' }} />
        <StyledCircleIcon />

        <Typography
          component={'span'}
          sx={{
            mt: 0.25,
            fontSize: 12,
            position: 'relative',
            color: 'common.white',
          }}
        >
          {activeStep}
        </Typography>
      </LoadingIconWrapper>
    )
  }

  return LoadingStepIcon
}
