import { Card, CardContent, Stack, SxProps, Typography } from '@mui/material'
import { Fragment, ReactNode } from 'react'
import { ExchangeRowInfo } from './exchange-row-info'

export interface IExchangeCardInfoData {
  title: string
  value: string | ReactNode
  color?: 'error' | 'warning'
  show?: boolean
}

interface IProps {
  confirmRejectButtons?: ReactNode
  title?: string
  description?: string
  data: IExchangeCardInfoData[]
  extraData?: ReactNode
  sx?: SxProps
}

export function ExchangeCardInfo({
  confirmRejectButtons,
  title,
  description,
  data,
  extraData,
  sx,
}: IProps) {
  return (
    <Card sx={sx}>
      <CardContent sx={{ width: '100%', padding: '18px 16px' }}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          width={'100%'}
          gap={2}
        >
          <Stack className={'first-part'} width={'100%'}>
            <Typography variant="h6" fontWeight={600}>
              {title}
            </Typography>

            <Typography variant="caption" mb={1}>
              {description}
            </Typography>

            <Stack width={'100%'} gap={1}>
              {data.map((item) => (
                <Fragment key={item.title}>
                  {item.show && (
                    <ExchangeRowInfo
                      title={item.title}
                      value={item.value}
                      color={item.color}
                    />
                  )}
                </Fragment>
              ))}
            </Stack>

            {extraData}
          </Stack>

          {confirmRejectButtons && (
            <Stack justifyContent={'flex-end'}>{confirmRejectButtons}</Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
