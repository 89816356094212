import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import isEmail from 'validator/lib/isEmail'
import * as yup from 'yup'

import {
  IBeneficiary,
  IDocument,
  TCountryAbbreviation,
  TDocumentTypeSimple,
} from '@shared/types'
import { isAdult } from '@shared/utils'

import { IBeneficiariesForm, IBeneficiaryForm } from '../types'

const beneficiaryDefaultValues: IBeneficiaryForm = {
  firstName: '',
  lastName: '',
  dateOfBirthday: new Date(),
  nationality: undefined,
  address: {
    city: '',
    countryCode: undefined,
    streetAddress: '',
    zipCode: '',
  },
  documents: [
    {
      issueCountry: undefined,
      number: '',
      type: '',
    },
  ],
  emailAddress: '',
  personalNumber: '',
  phoneNumber: '',
}

export function useBeneficiariesForm() {
  const { t } = useTranslation('common')

  const mainStringValidationSchema = (min = 1, max = 50) => {
    return yup
      .string()
      .trim()
      .required(t('validation.required'))
      .min(
        min,
        t('validation.min-length_interval', {
          count: min,
          postProcess: 'interval',
        })
      )
      .max(max, t('validation.max length', { count: max }))
  }

  const zipCodeSchema = (min = 2, max = 12) =>
    yup.string().test({
      name: 'zipCode',
      exclusive: true,
      params: { max, min },
      message:
        'ZIP code must be at least ${min} and no more than ${max} characters long',
      test: (value) => !value || (value.length >= min && value.length <= max),
    })

  const phoneNumberSchema = yup
    .string()
    .required(t('validation.required'))
    .transform((originalValue) => {
      return originalValue ? originalValue.replace('+', '') : originalValue
    })
    .matches(/^\d{9,15}$/, 'Phone number must be valid')

  const addressSchema: yup.ObjectSchema<IBeneficiary['address']> = yup
    .object()
    .shape({
      city: mainStringValidationSchema(),
      countryCode: yup
        .string<TCountryAbbreviation>()
        .required(t('validation.required')),
      streetAddress: mainStringValidationSchema(2, 100),
      zipCode: zipCodeSchema(),
    })

  const documentsSchema: yup.ObjectSchema<Omit<IDocument, 'images'>> = yup
    .object()
    .shape({
      type: yup
        .string<TDocumentTypeSimple>()
        .required(t('validation.required')),
      number: mainStringValidationSchema(),
      issueCountry: yup
        .string<TCountryAbbreviation>()
        .required(t('validation.required')),
    })

  const schema: yup.ObjectSchema<IBeneficiariesForm> = yup.object().shape({
    beneficiaries: yup.lazy(() =>
      yup
        .array()
        .of(
          yup.object().shape({
            firstName: mainStringValidationSchema(),
            lastName: mainStringValidationSchema(),
            dateOfBirthday: yup
              .date()
              .required(t('validation.required'))
              .test(
                'is-adult',
                t('validation.Must be 18 years or older'),
                isAdult
              ),
            nationality: yup
              .string<TCountryAbbreviation>()
              .required('Required'),
            personalNumber: mainStringValidationSchema(),
            documents: yup.lazy(() =>
              yup.array().of(documentsSchema).required()
            ),
            address: addressSchema,
            phoneNumber: phoneNumberSchema,
            emailAddress: yup
              .string()
              .required(t('validation.required'))
              .email(t('validation.email'))
              .test({
                name: 'emailAddress',
                exclusive: true,
                message: t('validation.email'),
                test: (value) => isEmail(value),
              }),
          })
        )
        .required(t('validation.required'))
    ),
  })

  const form = useForm<IBeneficiariesForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      beneficiaries: [beneficiaryDefaultValues],
    },
    mode: 'onSubmit',
  })

  const { control } = form

  const {
    fields: beneficiaries,
    append,
    remove: removeBeneficiary,
  } = useFieldArray({
    control,
    name: 'beneficiaries',
  })

  const addBeneficiary = () => {
    append(beneficiaryDefaultValues)
  }

  return {
    form,
    beneficiaries,
    addBeneficiary,
    removeBeneficiary,
  }
}
