import { Chip, Stack, TableCell } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { getWithdrawStatusColor } from '@entities/withdraw'

import { routePaths } from '@shared/constants'
import { IWithdraw } from '@shared/types'
import {
  DateWithTodayYesterdayBadge,
  ShowEuro,
  // StyledTableLink,
  StyledTableRow,
} from '@shared/ui'

interface IProps
  extends Omit<
    IWithdraw,
    | 'accountId'
    | 'bankAccount'
    | 'signAmlId'
    | 'signId'
    | 'transactionId'
    | 'updatedAt'
  > {
  isOwnPage?: boolean
}

export function WithdrawListItem({
  amount,
  createdAt,
  id,
  status,
  displayName,
  isOwnPage,
}: IProps) {
  const navigate = useNavigate()

  return (
    <StyledTableRow
      hover
      tabIndex={-1}
      key={id}
      onClick={() => navigate(`${routePaths.withdraws.list}/${id}`)}
    >
      <TableCell>{id}</TableCell>
      {isOwnPage && <TableCell>{displayName}</TableCell>}

      <TableCell>
        <Chip
          size="small"
          label={status}
          color={getWithdrawStatusColor(status)}
          sx={{ minWidth: 180 }}
        />
      </TableCell>

      <TableCell>
        <ShowEuro cents={amount} />
      </TableCell>

      <TableCell sx={{ py: 0 }}>
        <Stack direction="row" alignItems="center" gap={2}>
          <DateWithTodayYesterdayBadge date={createdAt} />
          {/* <StyledTableLink href={`${routePaths.withdraws.list}/${id}`} /> */}
        </Stack>
      </TableCell>
    </StyledTableRow>
  )
}
