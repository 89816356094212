import { Fade } from '@mui/material'
import { ReactNode } from 'react'

import { ListIconWrapper } from '../styled'

interface IProps {
  children: ReactNode
  show: boolean
}

export function FadeIcon({ show, children }: IProps) {
  return (
    <Fade
      in={show}
      unmountOnExit
      timeout={{
        appear: 0,
        enter: 100,
        exit: 100,
      }}
    >
      <ListIconWrapper>{children}</ListIconWrapper>
    </Fade>
  )
}
