import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { Button, Container, LinearProgress, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import {
  ExchangeSteps,
  ExchangeBaseInfo,
  ExchangeIdInfo,
  ExchangeFeeInfo,
  ExchangeFinalInfo,
  ExchangeAdvancedOptions,
} from '@widgets/exchange-details'

import { BackButton } from '@features/buttons'
import { ConfirmCancelExchange } from '@features/exchange'

import { IExchange } from '@entities/exchange'
import { UserButton } from '@entities/user'

import { TParamsKeys, routePaths } from '@shared/constants'
import { ContentPaper, StyledToolbar } from '@shared/ui'

import { getExchange } from '../model'

interface IErrorResponse {
  statusCode: number
  message: string
}

export function ExchangePage() {
  const { t } = useTranslation('pages')
  const { exchangeId } = useParams<TParamsKeys>()

  const {
    data: exchange,
    isLoading,
    isError,
    failureReason,
    refetch,
  } = useQuery<IExchange, AxiosError<IErrorResponse>>({
    queryKey: ['get-exchange', exchangeId],
    queryFn: () => getExchange(exchangeId),
    refetchInterval: 10 * 1000,
  })

  useEffect(() => {
    isError && toast.error(`${failureReason?.response?.data.message}`)
  }, [failureReason])

  const walletName = useMemo(() => {
    const wallet = exchange?.wallet

    return wallet?.label || ''
  }, [exchange])

  const isShowConfirmCancelExchange = useMemo(() => {
    const status = exchange?.status

    return (
      status === 'EXCHANGE_TECH_DONE' ||
      status === 'EXCHANGE_ORDER_DONE' ||
      status === 'CRYPTO_TX_DONE'
    )
  }, [exchange])

  return (
    <>
      <StyledToolbar variant="dense">
        <BackButton />

        <Button
          variant={'contained'}
          href={`${routePaths.wallets.list}/${exchange?.wallet.id}`}
          endIcon={<ArrowOutwardIcon />}
        >
          {t('exchange.button-wallet', { name: walletName })}
        </Button>
      </StyledToolbar>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}

      <Container maxWidth={false} sx={{ pb: 3 }}>
        <ContentPaper sx={{ mb: 5 }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <UserButton userId={exchange?.userId} />
            <ExchangeIdInfo exchange={exchange} />
          </Stack>

          <ExchangeSteps
            exchange={exchange}
            confirmRejectButtons={
              isShowConfirmCancelExchange &&
              exchange && (
                <ConfirmCancelExchange
                  refetchExchanges={refetch}
                  {...exchange}
                />
              )
            }
          />

          <Box
            display={'grid'}
            gridTemplateColumns="repeat(auto-fill, minmax(450px, 1fr))"
            gap={2}
          >
            <ExchangeBaseInfo exchange={exchange} />
            <ExchangeFeeInfo exchange={exchange} />
            <ExchangeFinalInfo exchange={exchange} />
          </Box>
        </ContentPaper>

        <ExchangeAdvancedOptions
          refetchExchanges={refetch}
          exchange={exchange}
        />
      </Container>
    </>
  )
}
