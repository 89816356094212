import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { WalletsListPage } from '../ui'

export const WalletsListRouter: RouteObject = {
  path: routePaths.wallets.list,
  element: <WalletsListPage />,
}
