import {
  SxProps,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CountrySelectInput, DateField, DocumentSelectInput } from '@shared/ui'

import { IBeneficiariesForm } from '../../model'

interface IProps {
  sx?: SxProps
  index: number
  remove: () => void
  disabled: boolean
}

export function BeneficiaryListItem({ sx, index, remove, disabled }: IProps) {
  const { t } = useTranslation('widgets')
  const { control } = useFormContext<IBeneficiariesForm>()

  return (
    <Box sx={sx} maxWidth={800}>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('corporate-application.beneficiaries list.form.personal info')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.firstName`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                ref={null}
                inputRef={field.ref}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'corporate-application.beneficiaries list.form.first name'
                )}
                placeholder={t(
                  'corporate-application.beneficiaries list.form.first name'
                )}
                fullWidth
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.lastName`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                ref={null}
                inputRef={field.ref}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'corporate-application.beneficiaries list.form.last name'
                )}
                placeholder={t(
                  'corporate-application.beneficiaries list.form.last name'
                )}
                fullWidth
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.dateOfBirthday`}
            render={({ field, fieldState }) => (
              <DateField
                {...field}
                format="dd.MM.yyyy"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'corporate-application.beneficiaries list.form.date of birthday'
                )}
                fullWidth
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.nationality`}
            render={({ field, fieldState }) => (
              <CountrySelectInput
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'corporate-application.beneficiaries list.form.nationality'
                )}
                placeholder={t(
                  'corporate-application.beneficiaries list.form.placeholder country'
                )}
                fullWidth
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.personalNumber`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                ref={null}
                inputRef={field.ref}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'corporate-application.beneficiaries list.form.personal number'
                )}
                placeholder={t(
                  'corporate-application.beneficiaries list.form.personal number'
                )}
                fullWidth
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('corporate-application.beneficiaries list.form.documents')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.documents.0.type`}
            render={({ field, fieldState }) => (
              <DocumentSelectInput
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'corporate-application.beneficiaries list.form.document type'
                )}
                purpose="personal"
                value={field.value}
                onChange={field.onChange}
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.documents.0.number`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                ref={null}
                inputRef={field.ref}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'corporate-application.beneficiaries list.form.document number'
                )}
                placeholder={t(
                  'corporate-application.beneficiaries list.form.document number'
                )}
                fullWidth
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.documents.0.issueCountry`}
            render={({ field, fieldState }) => (
              <CountrySelectInput
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'corporate-application.beneficiaries list.form.issue country'
                )}
                placeholder={t(
                  'corporate-application.beneficiaries list.form.placeholder country'
                )}
                fullWidth
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('corporate-application.beneficiaries list.form.address')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.address.countryCode`}
            render={({ field, fieldState }) => (
              <CountrySelectInput
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'corporate-application.beneficiaries list.form.country'
                )}
                placeholder={t(
                  'corporate-application.beneficiaries list.form.placeholder country'
                )}
                fullWidth
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.address.city`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                ref={null}
                inputRef={field.ref}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t('corporate-application.beneficiaries list.form.city')}
                placeholder={t(
                  'corporate-application.beneficiaries list.form.city'
                )}
                fullWidth
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.address.streetAddress`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                ref={null}
                inputRef={field.ref}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'corporate-application.beneficiaries list.form.address line'
                )}
                placeholder={t(
                  'corporate-application.beneficiaries list.form.address line'
                )}
                fullWidth
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.address.zipCode`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                ref={null}
                inputRef={field.ref}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'corporate-application.beneficiaries list.form.ZIP code'
                )}
                placeholder={t(
                  'corporate-application.beneficiaries list.form.ZIP code'
                )}
                fullWidth
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('corporate-application.beneficiaries list.form.contacts')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.phoneNumber`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                ref={null}
                inputRef={field.ref}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t(
                  'corporate-application.beneficiaries list.form.phone number'
                )}
                placeholder={t(
                  'corporate-application.beneficiaries list.form.phone number'
                )}
                fullWidth
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.emailAddress`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                ref={null}
                inputRef={field.ref}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={t('corporate-application.beneficiaries list.form.email')}
                placeholder={t(
                  'corporate-application.beneficiaries list.form.email'
                )}
                fullWidth
                disabled={disabled}
              />
            )}
          />
        </Grid>
      </Grid>

      {!disabled && (
        <Button variant="contained" onClick={remove}>
          {t(
            'corporate-application.beneficiaries list.form.remove beneficiary'
          )}
        </Button>
      )}
    </Box>
  )
}
