import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { DashboardPage } from '../ui'

export const DashboardPageRouter: RouteObject = {
  path: routePaths.dashboard,
  element: <DashboardPage />,
}
