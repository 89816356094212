import { useEffect } from 'react'

import { ExchangesTable } from '@widgets/exchanges-table'

import { useExchangesFilters } from '@features/exchange'

import { useGetExchangesList } from '@entities/exchange'

import { useCustomPagination } from '@shared/ui'

export function ExchangesListPage() {
  const { currentMethod, currentStatus, currentType, filtersOptions } =
    useExchangesFilters()

  const {
    page,
    rowsPerPage,
    paginationNode,
    setTotalPages,
    setListLength,
    setPage,
  } = useCustomPagination()

  const { data: exchanges, isLoading } = useGetExchangesList({
    page,
    perPage: rowsPerPage,
    method: currentMethod,
    type: currentType,
    status: currentStatus,
  })

  useEffect(() => {
    if (exchanges) {
      setTotalPages(exchanges.totalPages)

      setListLength(exchanges.list.length)
    }
  }, [exchanges?.totalPages])

  useEffect(() => {
    setPage(1)
  }, [currentMethod, currentStatus, currentType])

  return (
    <ExchangesTable
      filtersOptions={filtersOptions}
      isLoading={isLoading}
      rowsPerPage={+rowsPerPage}
      currentType={currentType}
      currentStatus={currentStatus}
      currentMethod={currentMethod}
      exchangesList={exchanges?.list}
      paginationNode={paginationNode}
    />
  )
}
