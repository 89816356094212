import {
  Box,
  Container,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useUsersFilters } from '@features/user'
import {
  FiltersMenu,
  ListLoader,
  StyledToolbar,
  useCustomPagination,
} from '@shared/ui'
import { useTableColumnsNames, useUsersData } from '../model'
import { TableBodyListItem, TableHeadListItem } from './components'

export function UsersListPage() {
  const { t } = useTranslation('pages')

  const { page, rowsPerPage, paginationNode, setTotalPages, setListLength } =
    useCustomPagination()

  const { currentStatus, filtersOptions } = useUsersFilters()

  const tableColumnsNames = useTableColumnsNames()

  const { data: users, isLoading } = useUsersData({
    page,
    rowsPerPage,
    currentStatus,
  })

  useEffect(() => {
    if (users) {
      setTotalPages(users.totalPages)

      setListLength(users.list.length)
    }
  }, [users?.totalPages])

  return (
    <>
      <StyledToolbar>
        <Typography variant="h6" color="GrayText" fontWeight={100}>
          {t('users list.title')}
        </Typography>

        <FiltersMenu filtersOptions={filtersOptions} />
      </StyledToolbar>

      <Container maxWidth={false}>
        <Paper variant="elevation">
          {isLoading ? (
            <Box>
              <LinearProgress />

              <Box sx={{ p: 2 }}>
                <ListLoader skeletonsNumber={+rowsPerPage} />
              </Box>
            </Box>
          ) : (
            <TableContainer sx={{ mb: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {tableColumnsNames.map((columnName) => (
                      <TableHeadListItem
                        key={columnName.text}
                        cellName={columnName.cellName}
                        text={columnName.text}
                      />
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {users?.list.map((user) => (
                    <TableBodyListItem key={user.id} user={user} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {paginationNode}
        </Paper>
      </Container>
    </>
  )
}
