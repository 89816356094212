import { TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { MapObjectValue } from '@entities/application'

import {
  IAddress,
  IMainApplicationInfo,
  LegalDocumentsImage,
  TCorporateApplicationKeys,
} from '@shared/types'
import { ConvertTermsAndConditions, StyledTableRow } from '@shared/ui'
import { convertValueToDate } from '@shared/utils'

import { CorporateApplicationDetails } from './corporate-application-details'

interface IProps {
  objectKey: string
  value?:
    | string
    | IAddress
    | IMainApplicationInfo
    | null
    | LegalDocumentsImage[]
}

type TReturnedConvertedValue =
  | JSX.Element
  | JSX.Element[]
  | string
  | number
  | undefined
  | null

export function RowCorporateApplicationInfo({ objectKey, value }: IProps) {
  const { t } = useTranslation('pages')
  const typedObjectKey = objectKey as TCorporateApplicationKeys

  const convertValue = (): TReturnedConvertedValue => {
    if (typeof value === 'object' && value !== null) {
      return <MapObjectValue object={value} type="corporate" />
    }

    if (typeof value === 'boolean') {
      return JSON.stringify(value)
    }

    switch (typedObjectKey) {
      case 'createdAt':
      case 'updatedAt':
        return convertValueToDate({ value })
      case 'termsAndConditions':
        return (
          <ConvertTermsAndConditions
            value={value}
            mainTranslateKey="pages:corporate-application.object keys"
          />
        )
      case 'details':
        return <CorporateApplicationDetails details={value} />
      default:
        return value
    }
  }

  return (
    <StyledTableRow hover tabIndex={-1} key={objectKey}>
      <TableCell>
        {t(`corporate-application.object keys.${objectKey}` as never)}
      </TableCell>

      <TableCell>{convertValue()}</TableCell>
    </StyledTableRow>
  )
}
