import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'
import AddCardIcon from '@mui/icons-material/AddCard'
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin'
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined'
import DescriptionIcon from '@mui/icons-material/Description'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import EuroIcon from '@mui/icons-material/Euro'
import LogoutIcon from '@mui/icons-material/Logout'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import TransformIcon from '@mui/icons-material/Transform'

import { SxProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon/SvgIcon'
import { useKeycloak } from '@react-keycloak/web'
import { FC, SVGProps } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetAllApplications } from '@entities/applications-list'
import { useGetWithdrawsList } from '@entities/withdraw'
import { routePaths } from '@shared/constants'

interface IMenuItem {
  id: number
  title: string

  navigationLink?: string
  action?: () => void

  icon: FC<SVGProps<SVGSVGElement>> | typeof SvgIcon
  iconFill: FC<SVGProps<SVGSVGElement>> | typeof SvgIcon

  actionsQuantity?: number
  disabled?: boolean
  divider?: boolean
  sx?: SxProps
}

export const useSidebarItems = (): IMenuItem[] => {
  const { t } = useTranslation('widgets')
  const { keycloak } = useKeycloak()

  const { data: applications } = useGetAllApplications({
    currentStatus: 'received',
    page: 1,
    rowsPerPage: '1000',
  })

  const { data: withdraws } = useGetWithdrawsList({
    currentStatus: 'CONFIRM_BY_USER',
    page: 1,
    rowsPerPage: '1000',
  })

  return [
    {
      id: 1,
      title: t('appbar.links.users'),
      navigationLink: routePaths.users.list,
      icon: PeopleAltOutlinedIcon,
      iconFill: PeopleAltIcon,
    },
    {
      id: 2,
      title: t('appbar.links.accounts'),
      navigationLink: routePaths.accounts.list,
      icon: AccountBoxOutlinedIcon,
      iconFill: AccountBoxIcon,
    },
    {
      id: 3,
      title: t('appbar.links.wallets'),
      navigationLink: routePaths.wallets.list,
      icon: AccountBalanceWalletOutlinedIcon,
      iconFill: AccountBalanceWalletIcon,
      divider: true,
    },
    {
      id: 4,
      title: t('appbar.links.applications'),
      navigationLink: routePaths.applications.list,
      icon: DescriptionOutlinedIcon,
      iconFill: DescriptionIcon,
      actionsQuantity: applications?.list.length,
    },
    {
      id: 5,
      title: t('appbar.links.exchanges'),
      navigationLink: routePaths.exchanges.list,
      icon: CurrencyExchangeOutlinedIcon,
      iconFill: CurrencyExchangeOutlinedIcon,
    },
    {
      id: 6,
      title: t('appbar.links.top-ups'),
      navigationLink: routePaths.topUps.list,
      icon: AddCardIcon,
      iconFill: AddCardIcon,
    },
    {
      id: 7,
      title: t('appbar.links.withdraws'),
      navigationLink: routePaths.withdraws.list,
      icon: TransformIcon,
      iconFill: TransformIcon,
      actionsQuantity: withdraws?.list.length,
      divider: true,
    },
    {
      id: 8,
      title: t('appbar.links.fiat-transactions'),
      navigationLink: routePaths.fiatTransactions.list,
      icon: EuroIcon,
      iconFill: EuroIcon,
    },
    {
      id: 9,
      title: t('appbar.links.crypto-transactions'),
      navigationLink: routePaths.cryptoTransactions.list,
      icon: CurrencyBitcoinIcon,
      iconFill: CurrencyBitcoinIcon,
    },

    {
      id: 10,
      title: t('appbar.actions.logout'),
      action: () => keycloak.logout(),
      icon: LogoutIcon,
      iconFill: LogoutIcon,
      sx: { mt: 4 },
    },
  ]
}
