import { ChipOwnProps } from '@mui/material'
import { TOndatoStatuses } from '@shared/types'

export const getStatusOndatoColor = (
  status: TOndatoStatuses
): ChipOwnProps['color'] => {
  switch (status) {
    case 'APPROVED':
      return 'success'
    case 'CANCELED':
    case 'REJECTED':
      return 'error'
    case 'AWAITING':
    case 'NEW':
    case 'SENT_CALLBACK':
    default:
      return 'warning'
  }
}
