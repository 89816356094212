export const green = {
  50: '#E9FBF1',
  100: '#D4F7E4',
  200: '#A8F0C8',
  300: '#7DE8AD',
  400: '#51E192',
  500: '#23C76D',
  600: '#1EAE5F',
  700: '#178247',
  800: '#0F572F',
  900: '#0F572F',
  950: '#04160C',
}
