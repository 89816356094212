import { Button, SxProps } from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ITopUpOptions } from '@entities/top-up-account'

import { useTopUpForm } from '../../model'
import { ModalTopUp } from './modal-top-up'

interface IProps {
  accountUuid?: string
  setIsShowOtp: Dispatch<SetStateAction<boolean>>
  setTopUpOptions: Dispatch<SetStateAction<ITopUpOptions | null>>
  sx?: SxProps
}

export function CreateTopUpAccount({
  accountUuid,
  setIsShowOtp,
  setTopUpOptions,
  sx,
}: IProps) {
  const { t } = useTranslation(['features', 'common'])
  const [isShow, setIsShow] = useState<boolean>(false)
  const { topUpForm } = useTopUpForm()
  const { setValue } = topUpForm

  const handleClick = () => {
    setIsShow(true)

    accountUuid && setValue('accountUuid', accountUuid)
  }

  return (
    <>
      <FormProvider {...topUpForm}>
        <ModalTopUp
          isShow={isShow}
          setIsShow={setIsShow}
          setIsShowOtp={setIsShowOtp}
          setTopUpOptions={setTopUpOptions}
          accountUuid={accountUuid}
        />
      </FormProvider>

      <Button variant="contained" onClick={handleClick} sx={sx}>
        {!accountUuid ? (
          <>{t('top up account.top up by uuid')}</>
        ) : (
          <>{t('top up account.top up')}</>
        )}
      </Button>
    </>
  )
}
