import { SxProps, Typography, Grid } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Dispatch, SetStateAction, useEffect, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { StyledGridItem } from './styled'

interface IProps {
  sx?: SxProps
  to?: Date
  from?: Date | null
  setTo: Dispatch<SetStateAction<Date | undefined>>
  setFrom: Dispatch<SetStateAction<Date | undefined | null>>
}

const paramNameTo = 'to'
const paramNameFrom = 'from'

export function DateFilterPicker({ from, setFrom, setTo, to, sx }: IProps) {
  const { t } = useTranslation('shared')
  const [searchParams, setSearchParams] = useSearchParams()

  useLayoutEffect(() => {
    const toParams = searchParams.get(paramNameTo)
    const fromParams = searchParams.get(paramNameFrom)

    fromParams && setFrom(new Date(fromParams))

    toParams && setTo(new Date(toParams))
  }, [])

  useEffect(() => {
    to && searchParams.set(paramNameTo, to.toISOString())

    from
      ? searchParams.set(paramNameFrom, from.toISOString())
      : searchParams.delete(paramNameFrom)

    setSearchParams(searchParams)
  }, [to, from])

  return (
    <Grid container spacing={1} sx={sx}>
      <Grid item xs={5.5}>
        <Typography>{t('date-filter.from')}</Typography>

        <DatePicker
          value={from}
          onChange={(e) => setFrom(e || undefined)}
          format="dd.MM.yyyy"
          maxDate={new Date()}
          sx={{ width: '100%' }}
        />
      </Grid>

      <StyledGridItem item xs={1}>
        —
      </StyledGridItem>

      <Grid item xs={5.5}>
        <Typography>{t('date-filter.to')}</Typography>

        <DatePicker
          value={to}
          onChange={(e) => setTo(e || undefined)}
          format="dd.MM.yyyy"
          maxDate={new Date()}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>
  )
}
