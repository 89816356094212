import { instance } from '@shared/requester'
import { IGetListResponse, TAccountType } from '@shared/types'

import { IAccount } from '../types'

interface IRequestParams {
  page: number
  perPage: string
  userId?: string
  type?: TAccountType
}

export async function getAccountsList({
  page,
  perPage,
  type,
  userId,
}: IRequestParams) {
  return instance
    .get<IGetListResponse<IAccount>>('/accounts', {
      params: {
        'cur-page': page,
        'per-page': perPage,
        'user-id': userId,
        type,
      },
    })
    .then((response) => response.data)
}
