export const isYesterday = (inputDate: Date | string | number) => {
  try {
    const convertedInputDate = new Date(inputDate)
    const currentDate = new Date()

    const yesterdayDate = new Date(currentDate)

    yesterdayDate.setDate(currentDate.getDate() - 1)

    const isYesterday =
      yesterdayDate.getFullYear() === convertedInputDate.getFullYear() &&
      yesterdayDate.getMonth() === convertedInputDate.getMonth() &&
      yesterdayDate.getDate() === convertedInputDate.getDate()

    return isYesterday
  } catch {
    return false
  }
}
