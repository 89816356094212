import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { CorporateApplicationPage } from '../ui'

export const CorporateApplicationRouter: RouteObject = {
  path: routePaths.applications.corporateApplication,
  element: <CorporateApplicationPage />,
}
