import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { WithdrawsTable } from '@widgets/withdraw'

import { useWithdrawsFilters } from '@features/withdraws'

import { getWithdrawsList } from '@entities/withdraw'

import { TParamsKeys } from '@shared/constants'
import { useCustomPagination } from '@shared/ui'

export function AccountWithdrawsTable() {
  const { accountId } = useParams<TParamsKeys>()
  const { currentStatus, filtersOptions } = useWithdrawsFilters()

  const {
    page,
    rowsPerPage,
    paginationNode,
    setTotalPages,
    setListLength,
    setPage,
  } = useCustomPagination()

  const { data: withdraws, isLoading } = useQuery({
    queryKey: [
      'get-account-withdraws-list',
      page,
      rowsPerPage,
      accountId,
      currentStatus,
    ],
    queryFn: () =>
      getWithdrawsList({
        page,
        perPage: rowsPerPage,
        accountId,
        status: currentStatus !== 'all' ? currentStatus : undefined,
      }),
    refetchInterval: 30 * 1000,
  })

  useEffect(() => {
    if (withdraws) {
      setTotalPages(withdraws.totalPages)

      setListLength(withdraws.list.length)
    }
  }, [withdraws?.totalPages])

  useEffect(() => {
    setPage(1)
  }, [currentStatus])

  return (
    <WithdrawsTable
      isLoading={isLoading}
      currentStatus={currentStatus}
      filterMenuOptions={filtersOptions}
      rowsPerPage={+rowsPerPage}
      paginationNode={paginationNode}
      withdrawsList={withdraws?.list}
      isOwnPage={false}
    />
  )
}
