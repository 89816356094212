import { useQuery } from '@tanstack/react-query'

import {
  TApplicationsStatusFilterType,
  getAllApplications,
} from '@entities/applications-list'

interface IProps {
  page: number
  rowsPerPage: string
  currentStatus: TApplicationsStatusFilterType
}

export function useGetAllApplications({
  page,
  rowsPerPage,
  currentStatus,
}: IProps) {
  return useQuery({
    queryKey: ['get-all-applications', page, rowsPerPage, currentStatus],
    queryFn: () =>
      getAllApplications({
        page,
        perPage: rowsPerPage,
        status: currentStatus !== 'all' ? currentStatus : undefined,
      }),
    refetchInterval: 30 * 1000,
  })
}
