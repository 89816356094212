import { useEffect } from 'react'

import { WithdrawsTable } from '@widgets/withdraw'

import { useWithdrawsFilters } from '@features/withdraws'

import { useGetWithdrawsList } from '@entities/withdraw'

import { useCustomPagination } from '@shared/ui'

export function WithdrawsListPage() {
  const { currentStatus, filtersOptions } = useWithdrawsFilters()

  const {
    page,
    rowsPerPage,
    paginationNode,
    setTotalPages,
    setListLength,
    setPage,
  } = useCustomPagination()

  const { data: withdraws, isLoading } = useGetWithdrawsList({
    currentStatus,
    page,
    rowsPerPage,
  })

  useEffect(() => {
    if (withdraws) {
      setTotalPages(withdraws.totalPages)

      setListLength(withdraws.list.length)
    }
  }, [withdraws?.totalPages])

  useEffect(() => {
    setPage(1)
  }, [currentStatus])

  return (
    <WithdrawsTable
      isLoading={isLoading}
      currentStatus={currentStatus}
      filterMenuOptions={filtersOptions}
      rowsPerPage={+rowsPerPage}
      paginationNode={paginationNode}
      withdrawsList={withdraws?.list}
    />
  )
}
