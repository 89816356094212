import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import {
  UserAccountsTable,
  UserApplicationsTable,
  UserExchangesTable,
  UserFees,
  UserPage,
  UserStatistics,
  UserTopUpsTable,
  UserTransactionsTable,
  UserWalletsTable,
  UserWithdrawsTable,
} from '../ui'
import { userLists } from './constants'

const mainPath = routePaths.users.user

export const UserRouter: RouteObject = {
  path: mainPath,
  element: <UserPage />,
  children: [
    {
      path: `${mainPath}/${userLists.applications}`,
      element: <UserApplicationsTable />,
    },
    {
      path: `${mainPath}/${userLists.accounts}`,
      element: <UserAccountsTable />,
    },
    {
      path: `${mainPath}/${userLists.topUps}`,
      element: <UserTopUpsTable />,
    },
    {
      path: `${mainPath}/${userLists.wallets}`,
      element: <UserWalletsTable />,
    },
    {
      path: `${mainPath}/${userLists.withdraws}`,
      element: <UserWithdrawsTable />,
    },
    {
      path: `${mainPath}/${userLists.exchanges}`,
      element: <UserExchangesTable />,
    },
    {
      path: `${mainPath}/${userLists.transactions}`,
      element: <UserTransactionsTable />,
    },
    {
      path: `${mainPath}/${userLists.fees}`,
      element: <UserFees />,
    },
    {
      path: `${mainPath}/${userLists.statistic}`,
      element: <UserStatistics />,
    },
  ],
}
