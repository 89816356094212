import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

interface ISidebarStore {
  open: boolean
  setOpen: (open: boolean) => void
}

export const useSidebarStore = create<ISidebarStore>()(
  devtools(
    persist(
      (set) => ({
        open: false,
        setOpen: (open) => set(() => ({ open })),
      }),
      {
        name: 'sidebar',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
)
