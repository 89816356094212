import { IExchange } from '@entities/exchange'

import { instance } from '@shared/requester'

interface IMainProps {
  id: string
}

interface IBuyProps extends IMainProps {
  type: Extract<IExchange['type'], 'BUY'>
  crypto?: false
}

interface ISellProps extends IMainProps {
  type: Extract<IExchange['type'], 'SELL'>
  crypto: boolean
}

type TProps = IBuyProps | ISellProps

export async function cancelExchange({ id, type, crypto }: TProps) {
  const cryptoParam = crypto ? '/crypto' : ''

  return instance
    .patch(`/exchanges/${id}/${type.toLowerCase()}/reject${cryptoParam}`)
    .then((response) => response.data)
}
