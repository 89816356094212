import { styled } from '@mui/material'

export const TimerText = styled('div')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '30px',
  textAlign: 'center',
  '.text-blue': {
    color: '#0155B7',
    fontWeight: 500,
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: 275,
    fontSize: 20,
  },
}))
