import { instance } from '@shared/requester'
import { TAccountType } from '@shared/types'

interface IRequestParams {
  userId: string
  userType: TAccountType
  tariffId: string
}

export async function changeTariff(body: IRequestParams) {
  return instance
    .post('/exchanges/tariffs/change', body)
    .then((response) => response.data)
}
