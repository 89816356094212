import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { ApplicationsTable } from '@widgets/applications'

import { useApplicationsFilters } from '@features/applications'

import { getAllApplications } from '@entities/applications-list'

import { TParamsKeys } from '@shared/constants'
import { useCustomPagination } from '@shared/ui'

export function UserApplicationsTable() {
  const { userId } = useParams<TParamsKeys>()
  const { currentStatus, filtersOptions } = useApplicationsFilters()

  const {
    page,
    rowsPerPage,
    paginationNode,
    setTotalPages,
    setListLength,
    setPage,
  } = useCustomPagination()

  const { data: applications, isLoading } = useQuery({
    queryKey: [
      'get-user-applications',
      userId,
      page,
      rowsPerPage,
      currentStatus,
    ],
    queryFn: () =>
      getAllApplications({
        page,
        perPage: rowsPerPage,
        userId,
        status: currentStatus !== 'all' ? currentStatus : undefined,
      }),
    refetchInterval: 90 * 1000,
  })

  useEffect(() => {
    if (applications) {
      setTotalPages(applications.totalPages)

      setListLength(applications.list.length)
    }
  }, [applications?.totalPages])

  useEffect(() => {
    setPage(1)
  }, [currentStatus])

  return (
    <ApplicationsTable
      isLoading={isLoading}
      currentStatus={currentStatus}
      filterMenuOptions={filtersOptions}
      rowsPerPage={+rowsPerPage}
      paginationNode={paginationNode}
      applicationsList={applications?.list}
      isOwnPage={false}
    />
  )
}
