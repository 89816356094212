import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { TopUpsTable } from '@widgets/top-up'

import { useTopUpsFilters } from '@features/top-up'

import { getTopUpsList } from '@entities/top-up'

import { useCustomPagination } from '@shared/ui'

export function TopUpsListPage() {
  const { currentStatus, filtersOptions } = useTopUpsFilters()

  const {
    page,
    rowsPerPage,
    paginationNode,
    setTotalPages,
    setListLength,
    setPage,
  } = useCustomPagination()

  const {
    data: topUpsList,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['get-top-ups-list', page, rowsPerPage, currentStatus],
    queryFn: () =>
      getTopUpsList({
        page,
        perPage: rowsPerPage,
        status: currentStatus !== 'all' ? currentStatus : undefined,
      }),
    refetchInterval: 30 * 1000,
  })

  useEffect(() => {
    if (topUpsList) {
      setTotalPages(topUpsList.totalPages)

      setListLength(topUpsList.list.length)
    }
  }, [topUpsList?.totalPages])

  useEffect(() => {
    setPage(1)
  }, [currentStatus])

  return (
    <TopUpsTable
      refetchList={refetch}
      isLoading={isLoading}
      currentStatus={currentStatus}
      filterMenuOptions={filtersOptions}
      rowsPerPage={+rowsPerPage}
      paginationNode={paginationNode}
      topUpsList={topUpsList?.list}
    />
  )
}
