import { useQuery } from '@tanstack/react-query'

import {
  TCryptoTransactionStatus,
  TCryptoTransactionType,
  TTransactionIncomingType,
} from '@shared/types'

import { getCryptoTransactionsList } from '../../api'
import {
  TFilterIncomingTypeType,
  TFilterStatusType,
  TFilterTransactionTypeType,
} from '../types'

interface IProps {
  dateFrom?: string | null
  dateTo?: string
  currentIncomingType?: TFilterIncomingTypeType
  page?: number
  rowsPerPage?: string
  currentStatus?: TFilterStatusType
  currentType?: TFilterTransactionTypeType
  walletAddress?: string
  enabled?: boolean
}

export function useGetCryptoTransactions({
  dateFrom,
  dateTo,
  currentIncomingType,
  page,
  rowsPerPage,
  currentStatus,
  currentType,
  walletAddress,
  enabled,
}: IProps) {
  // const deleteAllFromParam = <T,>(param: T): Omit<T, 'all'> | undefined => {
  //   return param !== 'all' ? param : undefined
  // }
  // TODO: need to fix typing
  const deleteAllFromParam = (param: unknown): unknown | undefined => {
    return param !== 'all' ? param : undefined
  }

  const query = useQuery({
    queryKey: [
      'get-crypto-transactions-list',
      page,
      rowsPerPage,
      currentType,
      currentStatus,
      currentIncomingType,
      dateFrom,
      dateTo,
      walletAddress,
    ],
    queryFn: () =>
      getCryptoTransactionsList({
        page,
        perPage: rowsPerPage,
        incomingType: deleteAllFromParam(currentIncomingType) as
          | TTransactionIncomingType
          | undefined,
        status: deleteAllFromParam(currentStatus) as
          | TCryptoTransactionStatus
          | undefined,
        transactionType: deleteAllFromParam(currentType) as
          | TCryptoTransactionType
          | undefined,
        dateFrom: dateFrom || undefined,
        dateTo,
        walletAddress,
      }),
    refetchInterval: 30 * 1000,
    enabled,
  })

  return query
}
