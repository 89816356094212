import { useState } from 'react'

import { TTopUpsStatusFilterType } from '@entities/top-up'

import { IFilterOptions } from '@shared/ui'

const filterOptions: TTopUpsStatusFilterType[] = ['all', 'CREATED', 'SUCCESS']

export function useTopUpsFilters() {
  const [currentStatus, setCurrentStatus] =
    useState<TTopUpsStatusFilterType>('all')

  const filtersOptions: IFilterOptions[] = [
    {
      options: filterOptions,
      currentOption: currentStatus,
      setCurrentOption: setCurrentStatus,
      filterName: 'status',
    },
  ]

  return { filtersOptions, currentStatus }
}
