export async function saveDataToCSV(data: Blob, fileName: string) {
  const blob = await new Blob([data], {
    type: 'text/csv;charset=utf-8;',
  })

  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')

  a.href = url

  a.download = `${fileName}.csv`

  document.body.appendChild(a)

  a.click()

  document.body.removeChild(a)

  window.URL.revokeObjectURL(url)
}
