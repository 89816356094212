import { instance } from '@shared/requester'
import { IGetListResponse, IFiatTransaction } from '@shared/types'

interface IRequestParams {
  page: number
  perPage: string
  accountId?: string
}

export async function getFiatTransactionsList({
  page,
  perPage,
  accountId,
}: IRequestParams) {
  const accountIsParam = accountId ? `${accountId}/` : ''

  return instance
    .get<IGetListResponse<IFiatTransaction>>(
      `/accounts/${accountIsParam}transactions`,
      {
        params: {
          'cur-page': page,
          'per-page': perPage,
        },
      }
    )
    .then((response) => response.data)
}
