import { instance } from '@shared/requester'
import { TWithdrawStatus } from '@shared/types'

interface IRequest {
  code: string
  withdrawId: string
}

interface IResponse {
  id: string
  status: TWithdrawStatus
}

export async function verifySmsWithdraw({ withdrawId, code }: IRequest) {
  return instance
    .patch<IResponse>(`accounts/withdraw/${withdrawId}/confirm/verify-sms`, {
      code,
    })
    .then((response) => response.data)
}
