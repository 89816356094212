import { instance } from '@shared/requester'
import { IBaseConfirmationResponse } from '@shared/types'

interface IRequest {
  withdrawId: string
}

export async function sendSmsWithdraw({ withdrawId }: IRequest) {
  return instance
    .patch<IBaseConfirmationResponse>(
      `accounts/withdraw/${withdrawId}/confirm/send-sms`
    )
    .then((response) => response.data)
}
