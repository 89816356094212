import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Autocomplete, Box, Paper, SxProps, TextField } from '@mui/material'
import { useEffect, useState, FocusEvent, forwardRef } from 'react'

import { TDocumentType } from '@shared/types'

import { getDocumentTypesList, TDocumentPurpose } from '../model'

interface IProps {
  name?: string
  onChange?: (...event: unknown[]) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  value?: string
  label?: string
  placeholder?: string
  error?: boolean
  helperText?: string | boolean
  sx?: SxProps
  variant?: 'filled' | 'outlined' | 'standard'
  purpose?: TDocumentPurpose
  disabled?: boolean
}

type TDocTypesSelectOption = { type: TDocumentType; name: string }

export const DocumentSelectInput = forwardRef<HTMLInputElement, IProps>(
  function Named(props, ref) {
    const {
      name,
      placeholder,
      error,
      helperText,
      label,
      onChange,
      sx,
      value,
      variant,
      purpose,
      onBlur,
      disabled,
    } = props

    const documentTypes = getDocumentTypesList(purpose)

    const [autocompleteValue, setAutocompleteValue] =
      useState<TDocTypesSelectOption | null>(
        documentTypes.find((item) => item.type === value) || null
      )

    useEffect(() => {
      setAutocompleteValue(
        documentTypes.find((item) => item.type === value) || null
      )
    }, [value])

    return (
      <Box sx={sx}>
        <Autocomplete
          disabled={disabled}
          fullWidth
          value={autocompleteValue}
          defaultValue={null}
          onBlur={onBlur}
          clearOnBlur
          disablePortal
          options={documentTypes}
          getOptionLabel={(option) => option.name}
          popupIcon={!disabled && <ExpandMoreIcon />}
          PaperComponent={({ children }) => <Paper>{children}</Paper>}
          onChange={(_, option) => {
            if (onChange) {
              onChange(option?.type)
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              variant={variant}
              fullWidth
              label={label}
              placeholder={placeholder}
              error={error}
              helperText={helperText || ''}
              inputRef={ref}
              InputProps={params.InputProps}
            />
          )}
        />
      </Box>
    )
  }
)
