export const matchIsString = (value: string) => {
  return typeof value === 'string'
}

export const matchIsNumeric = (text: string) => {
  const isNumber = typeof text === 'number'
  const isString = matchIsString(text)

  return (isNumber || (isString && text !== '')) && !isNaN(Number(text))
}

export const validateChar = (value: string) => {
  return matchIsNumeric(value)
}
