import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { IDocument, TDocumentKeys } from '@shared/types'

import { DocumentListItem } from './document-list-item'

interface IProps {
  documents: IDocument[]
}

export function DocumentsList({ documents }: IProps) {
  const { t } = useTranslation('pages')

  return (
    <>
      {documents.map((document, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1" fontWeight={700}>
              {`${t('corporate-application.object keys.document')} ${
                index + 1
              }`}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {_.map(document, (item, objectKey) => (
                    <DocumentListItem
                      value={item}
                      objectKey={objectKey as TDocumentKeys}
                      key={objectKey}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}
