import { styled } from '@mui/material'

export const ErrorText = styled('p')(({ theme }) => ({
  margin: 0,
  color: '#D60033',
  textAlign: 'center',
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '30px',
  [theme.breakpoints.up('md')]: {
    fontSize: 20,
  },
}))
