import { IStep } from '../types'

export const sellKraken: IStep[] = [
  {
    defaultTitle: 'Created',
    conditionalStatuses: [
      {
        key: 'NEW',
        title: 'New',
        additionalText: 'User created a request',
      },
      {
        key: 'CANCELED',
        title: 'Canceled',
        additionalText: 'Exchange request is exists',
      },
    ],
  },
  {
    defaultTitle: 'User approved',
    conditionalStatuses: [
      {
        key: 'USER_APPROVED',
        title: 'User approved',
        additionalText: 'Approved by user',
      },
      {
        key: 'USER_REJECTED',
        title: 'User rejected',
        additionalText: 'Rejected by user',
      },
    ],
  },
  {
    defaultTitle: 'Send to KRAKEN',
    conditionalStatuses: [
      {
        key: 'CRYPTO_TX_SEND',
        title: 'Send to KRAKEN',
        additionalText: 'Send to main wallet and then send to KRAKEN',
        loading: true,
      },
    ],
  },
  {
    defaultTitle: 'sent-to-kraken-successfully',
    conditionalStatuses: [
      {
        key: 'CRYPTO_TX_DONE',
        title: 'Crypto transaction done',
        additionalText: 'Check balance on Kraken and confirm operation',
      },
      {
        key: 'CRYPTO_REJECTED',
        title: 'Crypto transaction rejected',
        additionalText: 'Rejected by AML-officer',
      },
    ],
  },
  {
    defaultTitle: 'KRAKEN order creation',
    conditionalStatuses: [
      {
        key: 'CRYPTO_CONFIRMED',
        title: 'KRAKEN order creation',
        additionalText: 'Waiting for KRAKEN order creation',
        loading: true,
      },
    ],
  },
  {
    defaultTitle: 'KRAKEN order creation error',
    optional: true,
    conditionalStatuses: [
      {
        key: 'EXCHANGE_ORDER_FAIL',
        title: 'KRAKEN order creation error',
        additionalText: 'Error during KRAKEN order creation',
      },
    ],
  },
  {
    defaultTitle: 'KRAKEN order done',
    conditionalStatuses: [
      {
        key: 'EXCHANGE_ORDER_DONE',
        title: 'KRAKEN order done',
        additionalText: 'Order verification required',
      },
      {
        key: 'AML_APPROVED',
        title: 'KRAKEN order done',
        additionalText: 'Order verification approved by AML-officer',
        loading: true,
      },
      {
        key: 'AML_REJECTED',
        title: 'KRAKEN order done',
        additionalText: 'Order verification rejected by AML-officer',
      },
    ],
  },
  {
    defaultTitle: 'Transaction sent',
    conditionalStatuses: [
      {
        key: 'TRANSACTION_SENT',
        title: 'Transaction sent',
      },
    ],
  },
]
