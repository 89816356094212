import { instance } from '@shared/requester'
import {
  IGetListResponse,
  TCryptoTransactionType,
  TCryptoTransactionStatus,
  ICryptoTransaction,
  TTransactionIncomingType,
} from '@shared/types'

interface IRequestParams {
  page?: number
  perPage?: string
  walletAddress?: string
  dateFrom?: string // YYYY-MM-DD
  dateTo?: string // YYYY-MM-DD
  status?: TCryptoTransactionStatus
  transactionType?: TCryptoTransactionType
  incomingType?: TTransactionIncomingType
}

export async function getCryptoTransactionsList(
  {
    page,
    perPage,
    dateFrom,
    dateTo,
    incomingType,
    status,
    transactionType,
    walletAddress,
  }: IRequestParams = { perPage: '25' }
) {
  return instance
    .get<IGetListResponse<ICryptoTransaction>>('/crypto-transactions/list', {
      params: {
        'cur-page': page,
        'per-page': perPage,
        'wallet-address': walletAddress,
        'date-from': dateFrom,
        'date-to': dateTo,
        'transaction-type': transactionType,
        'incoming-type': incomingType,
        status,
      },
    })
    .then((response) => response.data)
}
