import DirectionsIcon from '@mui/icons-material/Directions'
import {
  FormLabel,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Stack,
  SxProps,
  Typography,
} from '@mui/material'
import { useState, useEffect, useLayoutEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { StyledFormControl, StyledPaper, StyledTextField } from './styled'

interface IPropsPag {
  onChangePage: (page: number) => void
  onChangePerPage: (perPage: string) => void
  totalPages?: number
  rowsPerPage: string
  currentPage: number
  listLength: number
  sx?: SxProps
}

const perPageList = ['5', '10', '25', '50', '100', '500', '1000']

export function CustomPagination({
  onChangePage,
  onChangePerPage,
  totalPages,
  rowsPerPage,
  currentPage,
  listLength,
  sx,
}: IPropsPag) {
  const { t } = useTranslation('features')
  const [destinationPage, setDestinationPage] = useState<number>()
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [searchParams, setSearchParams] = useSearchParams()

  const handleChangePage = (page: number) => {
    if (totalPages && page > totalPages) {
      onChangePage(totalPages)

      return
    }

    if (page < 1) {
      onChangePage(1)

      return
    }

    onChangePage(Math.trunc(page))
  }

  useLayoutEffect(() => {
    const preloadedPage = searchParams.get('page')
    const preloadedPerPage = searchParams.get('per-page')

    preloadedPerPage &&
      Number(preloadedPerPage) &&
      onChangePerPage(preloadedPerPage)

    preloadedPage && Number(preloadedPage) && onChangePage(+preloadedPage)
  }, [])

  useEffect(() => {
    if (isFirstRender && currentPage <= 1) {
      setIsFirstRender(false)
    } else {
      searchParams.set('page', currentPage?.toString())

      searchParams.set('per-page', rowsPerPage)

      setSearchParams(searchParams)
    }
  }, [currentPage, rowsPerPage])

  const isShowPerPageSelect = useMemo(
    () => listLength >= 4,
    [listLength, totalPages]
  )

  return (
    <>
      {(listLength > 4 || !!totalPages) && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          px={2}
          py={isShowPerPageSelect ? 1 : 0}
          sx={sx}
        >
          {isShowPerPageSelect && (
            <StyledFormControl variant="standard">
              <FormLabel>{t('custom pagination.rows per page')}</FormLabel>

              <Select
                value={rowsPerPage}
                onChange={(e) => {
                  onChangePerPage(e.target.value)

                  onChangePage(1)
                }}
                sx={{ width: 62 }}
              >
                {perPageList.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          )}

          {totalPages && totalPages > 1 && (
            <>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(_, value) => handleChangePage(value)}
                color="primary"
              />

              <StyledPaper>
                <Typography>{t('custom pagination.go to page')}</Typography>

                <StyledTextField
                  variant="standard"
                  type="number"
                  onChange={(e) =>
                    setDestinationPage(+e.target.value || undefined)
                  }
                  value={destinationPage}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()

                      destinationPage && handleChangePage(destinationPage)
                    }
                  }}
                />

                <IconButton
                  color="primary"
                  onClick={() => {
                    destinationPage && handleChangePage(destinationPage)
                  }}
                >
                  <DirectionsIcon />
                </IconButton>
              </StyledPaper>
            </>
          )}
        </Stack>
      )}
    </>
  )
}
