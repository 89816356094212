import { Link, styled } from '@mui/material'

interface IProps {
  open?: boolean
}

export const LogoWrapper = styled(Link)<IProps>(({ theme, open }) => ({
  maxWidth: 80,
  width: open ? '100%' : 56,
  height: 80,
  transition: theme.transitions.create(['width', 'height', 'margin']),
  marginRight: 'auto',
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))
