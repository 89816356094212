import { instance } from '@shared/requester'
import { IIndividualApplication } from '@shared/types'

interface IRequestParams {
  applicationId?: string
}

export async function getIndividualApplication({
  applicationId,
}: IRequestParams) {
  if (!applicationId) {
    throw new Error()
  }

  return instance
    .get<IIndividualApplication>(`/applications/${applicationId}/individual`)
    .then((response) => response.data)
}
