import { IWithdraw } from '@shared/types'

import { WithdrawListItem } from './withdraw-list-item'

interface IProps {
  withdraws?: IWithdraw[]
  isOwnPage?: boolean
}

export function WithdrawsList({ withdraws, isOwnPage }: IProps) {
  return (
    <>
      {withdraws?.map((withdraw) => (
        <WithdrawListItem
          {...withdraw}
          isOwnPage={isOwnPage}
          key={withdraw.id}
        />
      ))}
    </>
  )
}
