import { instance } from '@shared/requester'
import {
  IGetListResponse,
  IUsersListItem,
  TOndatoStatuses,
} from '@shared/types'

interface IRequestParams {
  page: number
  perPage: string
  statusOndato?: TOndatoStatuses
}

export async function getAllUsers({
  page,
  perPage,
  statusOndato,
}: IRequestParams) {
  const statusOndatoParam = statusOndato ? `&status-ondato=${statusOndato}` : ''

  return instance
    .get<
      IGetListResponse<IUsersListItem>
    >(`/users?cur-page=${page}&per-page=${perPage}${statusOndatoParam}`)
    .then((response) => response.data)
}
