import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { ExchangesListPage } from '../ui'

export const ExchangesListRouter: RouteObject = {
  path: routePaths.exchanges.list,
  element: <ExchangesListPage />,
}
