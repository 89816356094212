import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { TopUpsListPage } from '../ui'

export const TopUpsListRouter: RouteObject = {
  path: routePaths.topUps.list,
  element: <TopUpsListPage />,
}
