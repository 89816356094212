import { ChipOwnProps } from '@mui/material'

import { TTopUpStatus } from '@shared/types'

export const getTopUpStatusColor = (
  status: TTopUpStatus
): ChipOwnProps['color'] => {
  switch (status) {
    case 'SUCCESS':
      return 'success'
    case 'CREATED':
      return 'primary'
    default:
      return 'error'
  }
}
