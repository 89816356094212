import { Box, Table, TableBody, TableCell, TableContainer } from '@mui/material'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { RenderWalletBalance } from '@entities/wallet'

import { IWallet, IWalletBalance } from '@shared/types'
import { CopyButton, CustomAccordion, StyledTableRow } from '@shared/ui'
import { convertValueToDate } from '@shared/utils'

import { IConvertValueProps } from '../wallet-page'

interface IProps {
  wallet?: IWallet
  isLoading: boolean
}

export function WalletMainInfo({ isLoading, wallet }: IProps) {
  const { t } = useTranslation('pages')

  const convertValue = ({ value, objectKey }: IConvertValueProps) => {
    const typedObjectKey = objectKey as keyof IWallet

    switch (typedObjectKey) {
      case 'createdAt':
      case 'updatedAt':
        return convertValueToDate({ value })
      case 'balances':
        return (
          <>
            {(value as IWalletBalance[])?.map((balance, index) => (
              <RenderWalletBalance
                balance={balance.balance}
                name={balance.name}
                type={balance.type}
                key={index}
              />
            ))}
          </>
        )
      default:
        return value
    }
  }

  return (
    <CustomAccordion title="Main info:" loading={isLoading}>
      <TableContainer sx={{ mb: 2 }}>
        <Table size="small">
          <TableBody>
            {_.map(wallet, (value, objectKey) => (
              <StyledTableRow hover tabIndex={-1} key={objectKey}>
                <TableCell>
                  {t(`wallet.object-keys.${objectKey}` as never)}
                </TableCell>

                <TableCell>
                  <Box>
                    {convertValue({ value, objectKey })}

                    {objectKey === 'address' && (
                      <CopyButton value={value as string} />
                    )}
                  </Box>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomAccordion>
  )
}
