import { ListItemIcon, styled } from '@mui/material'

interface IProps {
  open?: boolean
}

export const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'open',
})<IProps>(({ theme, open }) => ({
  marginRight: open ? 12 : 0,
  display: 'inline',
  position: 'relative',
  transition: theme.transitions.create('margin'),
}))
