import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  TExchangesTypeFilterType,
  TExchangesStatusFilterType,
  TExchangesMethodFilterType,
  IExchange,
} from '@entities/exchange'

import {
  FiltersMenu,
  ListLoader,
  StyledToolbar,
  IFilterOptions,
  FilterSelect,
} from '@shared/ui'

import { ExchangesList, ExchangesListTableHead } from './components'

interface IProps {
  filtersOptions: IFilterOptions[]
  isLoading: boolean
  rowsPerPage: number
  currentType: TExchangesTypeFilterType
  currentStatus: TExchangesStatusFilterType
  currentMethod: TExchangesMethodFilterType
  exchangesList?: IExchange[]
  paginationNode: JSX.Element
  walletAddressFilterOptions?: IFilterOptions
}

export function ExchangesTable({
  currentMethod,
  currentStatus,
  currentType,
  filtersOptions,
  isLoading,
  rowsPerPage,
  exchangesList,
  paginationNode,
  walletAddressFilterOptions,
}: IProps) {
  const { t } = useTranslation('widgets')

  return (
    <>
      <StyledToolbar variant="dense">
        <Typography>{t('exchanges-table.title')}</Typography>

        {walletAddressFilterOptions && (
          <FilterSelect
            {...walletAddressFilterOptions}
            sx={{ width: 'initial' }}
          />
        )}

        <FiltersMenu filtersOptions={filtersOptions} />
      </StyledToolbar>

      {isLoading && <ListLoader skeletonsNumber={rowsPerPage} />}

      {!isLoading && (
        <>
          <TableContainer sx={{ mb: 2 }}>
            <Table size="small">
              <TableHead>
                <ExchangesListTableHead
                  currentType={currentType}
                  currentStatus={currentStatus}
                  currentMethod={currentMethod}
                />
              </TableHead>

              <TableBody>
                <ExchangesList exchanges={exchangesList} />
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {paginationNode}
    </>
  )
}
