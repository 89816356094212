import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getAccount } from '../api'
import { IAccount } from '../types'

interface IProps {
  accountId?: string
}

interface IErrorResponse {
  statusCode: number
  message: string
}

export function useGetAccount({ accountId }: IProps) {
  const query = useQuery<IAccount, AxiosError<IErrorResponse>>({
    queryKey: ['get-account', accountId],
    queryFn: () => getAccount(accountId),
    refetchInterval: 90 * 1000,
  })

  return query
}
