import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { WalletsTable } from '@widgets/wallet'

import { useWalletsFilters } from '@features/wallet'

import { useGetWallets } from '@entities/wallet'

import { TParamsKeys } from '@shared/constants'
import { useCustomPagination } from '@shared/ui'

export function UserWalletsTable() {
  const { userId } = useParams<TParamsKeys>()

  const { currentType, filtersOptions } = useWalletsFilters()

  const {
    page,
    rowsPerPage,
    paginationNode,
    setTotalPages,
    setListLength,
    setPage,
  } = useCustomPagination()

  const { data: wallets, isLoading } = useGetWallets({
    page,
    rowsPerPage,
    userId,
    type: currentType !== 'all' ? currentType : undefined,
  })

  useEffect(() => {
    if (wallets) {
      setTotalPages(wallets.totalPages)

      setListLength(wallets.list.length)
    }
  }, [wallets?.totalPages])

  useEffect(() => {
    setPage(1)
  }, [currentType])

  return (
    <WalletsTable
      isLoading={isLoading}
      currentType={currentType}
      filterMenuOptions={filtersOptions}
      rowsPerPage={+rowsPerPage}
      paginationNode={paginationNode}
      walletsList={wallets?.list}
    />
  )
}
