import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import {
  AccountPage,
  AccountTopUpsTable,
  AccountWithdrawsTable,
  AccountExchangesTable,
} from '../ui'
import { accountLists } from './constants'

const mainPath = routePaths.accounts.account

export const AccountPageRouter: RouteObject = {
  path: mainPath,
  element: <AccountPage />,
  children: [
    {
      path: `${mainPath}/${accountLists.topUps}`,
      element: <AccountTopUpsTable />,
    },
    {
      path: `${mainPath}/${accountLists.withdraws}`,
      element: <AccountWithdrawsTable />,
    },
    {
      path: `${mainPath}/${accountLists.exchanges}`,
      element: <AccountExchangesTable />,
    },
  ],
}
