export const isToday = (inputDate: Date | string | number) => {
  try {
    const convertedInputDate = new Date(inputDate)
    const currentDate = new Date()

    const isToday =
      currentDate.getFullYear() === convertedInputDate.getFullYear() &&
      currentDate.getMonth() === convertedInputDate.getMonth() &&
      currentDate.getDate() === convertedInputDate.getDate()

    return isToday
  } catch {
    return false
  }
}
