import EastRoundedIcon from '@mui/icons-material/EastRounded'
import { Chip, ChipOwnProps, Stack, TableCell, Typography } from '@mui/material'

import {
  ICryptoTransaction,
  IFiatTransaction,
  TCryptoTransactionStatus,
  TCryptoTransactionType,
  TFiatTransactionType,
  TFiatTransactionStatus,
} from '@shared/types'
import { DateWithTodayYesterdayBadge, ShowEuro } from '@shared/ui'

interface IMainProps {
  id: string
  displayNameFrom?: string
  displayNameTo?: string
  amount: number | string
  type: TFiatTransactionType | TCryptoTransactionType
  status: TFiatTransactionStatus | TCryptoTransactionStatus
  createdAt: string
  isOwnPage: boolean
  isCrypto: boolean
  addressFrom?: string
  addressTo?: string
  from?: string | null
  to?: string | null
  currency?: string
  displayName?: string
}

export function TransactionsTableRow({
  addressFrom,
  addressTo,
  amount,
  createdAt,
  from,
  id,
  status,
  to,
  type,
  isOwnPage,
  isCrypto,
  currency,
  displayNameFrom,
  displayNameTo,
  displayName,
}: IMainProps) {
  const getTransactionStatusColor = (
    status: IFiatTransaction['status'] | ICryptoTransaction['status']
  ): ChipOwnProps['color'] => {
    switch (status) {
      case 'SUCCESS':
      case 'DONE':
        return 'success'
      case 'CREATED':
      case 'SENT':
        return 'primary'
      case 'FAIL':
      case 'REJECTED':
      case 'REJECTION':
      default:
        return 'error'
    }
  }

  return (
    <>
      <TableCell>{id}</TableCell>

      {isOwnPage &&
        (isCrypto ? (
          <>
            <TableCell>{displayNameFrom}</TableCell>
            <TableCell>{displayNameTo}</TableCell>
          </>
        ) : (
          <TableCell>{displayName}</TableCell>
        ))}

      <TableCell>
        <Stack direction="row" spacing={1}>
          <Typography>{isCrypto ? addressFrom : from}</Typography>
          <EastRoundedIcon />
        </Stack>
      </TableCell>

      <TableCell>
        <Typography>{isCrypto ? addressTo : to}</Typography>
      </TableCell>

      <TableCell>
        {isCrypto ? (
          `${amount} ${currency}`
        ) : (
          <ShowEuro cents={amount} currency={currency} />
        )}
      </TableCell>

      <TableCell>{type}</TableCell>

      <TableCell>
        <Chip
          size="small"
          label={status}
          color={getTransactionStatusColor(status)}
          sx={{ minWidth: 80 }}
        />
      </TableCell>

      <TableCell>
        <DateWithTodayYesterdayBadge date={createdAt} />
      </TableCell>
    </>
  )
}
