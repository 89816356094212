import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material'
import { memo } from 'react'

interface IProps<T extends string> {
  options: T[]
  selected: T[]
  setSelected: (payload: T[]) => void
  label?: string
}

function Named<T extends string>({
  options,
  selected,
  setSelected,
  label,
}: IProps<T>) {
  const handleChange = (event: SelectChangeEvent<T[]>) => {
    const {
      target: { value },
    } = event

    setSelected(typeof value === 'string' ? (value.split(',') as T[]) : value)
  }

  return (
    <FormControl sx={{ width: 300 }}>
      <InputLabel>{label}</InputLabel>

      <Select
        multiple
        value={selected as T[]}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.join(', ')}
      >
        {options.map((operation) => (
          <MenuItem key={operation} value={operation}>
            <Checkbox checked={selected.indexOf(operation) > -1} />
            <ListItemText primary={operation} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const StatisticsMultipleSelect = memo(Named) as typeof Named
