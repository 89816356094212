import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { ITopUpOptions } from '@entities/top-up-account'

import { useFormAmountHelpers } from '@shared/hooks'
import { getTimeToLeftFromTimestamp } from '@shared/utils'

import { ITopUpForm, createTopUp } from '../../model'

interface IProps {
  setIsShow: Dispatch<SetStateAction<boolean>>
  setIsShowOtp: Dispatch<SetStateAction<boolean>>
  setTopUpOptions: Dispatch<SetStateAction<ITopUpOptions | null>>
  isShow: boolean
  accountUuid?: string
}

export function ModalTopUp({
  setIsShow,
  setIsShowOtp,
  setTopUpOptions,
  isShow,
  accountUuid,
}: IProps) {
  const { t } = useTranslation(['features', 'common'])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { control, handleSubmit, setValue, getValues, reset } =
    useFormContext<ITopUpForm>()

  const { handleChangeAmount: changeAmountUtil, handleKeyUpAmount } =
    useFormAmountHelpers()

  const onChangeAmount = (value: string) => {
    setValue('amount', value)
  }

  const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.valueAsNumber

    changeAmountUtil(e, onChangeAmount)
  }

  const handleCreateTopUp = async () => {
    try {
      setIsLoading(true)

      const response = await createTopUp({
        amount: Math.round(+getValues('amount') * 100), // convert to euro-cents
        accountUuid: getValues('accountUuid'),
      })

      setTopUpOptions({
        id: response.id,
        TTL: getTimeToLeftFromTimestamp(response.confirmation.confirmTo),
      })

      setTimeout(setIsShowOtp, 100, true)

      setTimeout(reset, 300)
    } catch {
      reset()

      toast.error(t('common:oops'))
    } finally {
      setIsShow(false)

      setIsLoading(false)
    }
  }

  const handleClose = () => {
    setIsShow(false)

    setTimeout(reset, 300)
  }

  return (
    <Dialog
      open={isShow}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      sx={{ '& .MuiPaper-root': { pb: 2 } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography component="span">
          <Trans
            ns="features"
            i18nKey="top up account.title"
            values={{ accountUuid }}
            components={{
              span: <Typography component="span" color="primary" />,
            }}
          />
        </Typography>
      </DialogTitle>

      <form onSubmit={handleSubmit(handleCreateTopUp)}>
        <DialogContent sx={{ py: '8px !important' }}>
          {!accountUuid && (
            <Controller
              control={control}
              name="accountUuid"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  ref={null}
                  inputRef={field.ref}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  label="Account uuid"
                  placeholder="Input account uuid"
                  fullWidth
                  autoFocus
                  sx={{ mb: 4 }}
                />
              )}
            />
          )}

          <Controller
            control={control}
            name="amount"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                ref={null}
                inputRef={field.ref}
                type="tel"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label="Amount"
                placeholder="Input amount"
                fullWidth
                autoFocus
                onKeyUp={handleKeyUpAmount}
                onChange={handleChangeAmount}
              />
            )}
          />
        </DialogContent>

        <DialogActions sx={{ px: 3, justifyContent: 'center' }}>
          <Button onClick={handleClose} variant="outlined" fullWidth>
            {t('top up account.cancel')}
          </Button>

          <LoadingButton
            type="submit"
            loading={isLoading}
            variant="contained"
            autoFocus
            fullWidth
          >
            {t('top up account.top up')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
