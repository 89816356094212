import {
  SxProps,
  SelectChangeEvent,
  Select,
  MenuItem,
  FormLabel,
} from '@mui/material'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'

import { IFilterOptions } from '@shared/ui'
import { StyledFormControl } from './styled'

interface IProps extends IFilterOptions {
  sx?: SxProps
}

export function FilterSelect({
  options,
  currentOption,
  setCurrentOption,
  filterName,
  sx,
}: IProps) {
  const { t } = useTranslation('shared')
  const labelSelectId = useId()

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentOption(event.target.value)
  }

  return (
    <StyledFormControl sx={sx} variant="standard" fullWidth>
      <FormLabel id={labelSelectId}>
        {`${t('filter select.filter by')} ${filterName}`}
      </FormLabel>

      <Select
        labelId={labelSelectId}
        value={currentOption}
        label={t('filter select.filter by')}
        onChange={handleChange}
        sx={{ minWidth: 100 }}
      >
        {options.map((option, index) => (
          <MenuItem value={option} key={index}>
            {option.toLowerCase()}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )
}
