import { Stack } from '@mui/material'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { IExchange } from '@entities/exchange'
import { useStepsByMethodType } from '../model'
import {
  ExchangeStepper,
  ExchangeCardInfo,
  IExchangeCardInfoData,
} from './components'

interface IProps {
  exchange?: IExchange
  confirmRejectButtons?: ReactNode
}

export function ExchangeSteps({ exchange, confirmRejectButtons }: IProps) {
  const {
    activeStep,
    activeStepMessage,
    activeStepAdditionalMessage,
    activeStepStatusValue,
    activeStepStatusColor,
  } = useStepsByMethodType({ exchange })

  const { t } = useTranslation('widgets')

  const data: IExchangeCardInfoData[] = [
    {
      title: t('exchange-stepper.status'),
      value: activeStepStatusValue,
      color: activeStepStatusColor,
    },
    {
      title: t('exchange-stepper.description'),
      value: activeStepAdditionalMessage,
      color: activeStepStatusColor,
      show: !!activeStepStatusColor && !!activeStepAdditionalMessage,
    },
    {
      title: t('exchange-stepper.selling-amount'),
      value: `${exchange?.sellingAmount} ${exchange?.sellingCurrency}`,
      show: !!exchange?.sellingAmount,
    },
    {
      title: t('exchange-stepper.buying-amount'),
      value: `${exchange?.buyingAmountFinal || exchange?.buyingAmount} ${exchange?.buyingCurrency}`,
      show: !!(exchange?.buyingAmountFinal || exchange?.buyingAmount),
    },
  ]

  return (
    <Stack py={8} gap={5}>
      <ExchangeStepper exchange={exchange} />

      <ExchangeCardInfo
        title={`${t('exchange-stepper.current-step', {
          step: activeStep.noOptional + 1,
        })} ${activeStepMessage}`}
        description={'lorem ipsum dolor sit amet consectetur adipisicing elit'}
        confirmRejectButtons={confirmRejectButtons}
        data={data}
        sx={{ '& .first-part': { width: 500 } }}
      />
    </Stack>
  )
}
