import { Stack, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IFiltersState, TExchangesTableColumnName } from '@entities/exchange'

import { ITableHeadCell } from '@shared/types'
import { StyledTableHeadCell } from '@shared/ui'

type TProps = Pick<
  IFiltersState,
  'currentMethod' | 'currentStatus' | 'currentType'
>

export function ExchangesListTableHead({
  currentMethod,
  currentStatus,
  currentType,
}: TProps) {
  const { t } = useTranslation('widgets')

  const tableColumnsNames: ITableHeadCell<TExchangesTableColumnName>[] = [
    {
      cellName: 'id',
      text: t('exchanges-table.id'),
    },
    {
      cellName: 'wallet name',
      text: t('exchanges-table.wallet-name'),
    },
    {
      cellName: 'exchange',
      text: t('exchanges-table.exchange'),
    },
    {
      cellName: 'type',
      text: t('exchanges-table.type'),
    },
    {
      cellName: 'method',
      text: t('exchanges-table.method'),
    },
    {
      cellName: 'status',
      text: t('exchanges-table.status'),
    },
    {
      cellName: 'created at',
      text: t('exchanges-table.created at'),
    },
  ]

  return (
    <TableRow>
      {tableColumnsNames.map((columnName) => (
        <StyledTableHeadCell key={columnName.cellName}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography fontSize="inherit" fontWeight="inherit">
              {columnName.text}

              {columnName.cellName === 'status' &&
                currentStatus !== 'all' &&
                ` (${currentStatus})`}

              {columnName.cellName === 'method' &&
                currentMethod !== 'all' &&
                ` (${currentMethod})`}

              {columnName.cellName === 'type' &&
                currentType !== 'all' &&
                ` (${currentType})`}
            </Typography>
          </Stack>
        </StyledTableHeadCell>
      ))}
    </TableRow>
  )
}
