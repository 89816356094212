export type TParamsKeys =
  | 'userId'
  | 'applicationId'
  | 'accountId'
  | 'topUpId'
  | 'withdrawId'
  | 'walletId'
  | 'exchangeId'
  | 'userList'
  | 'accountList'
  | 'walletList'
  | 'fiatTransactionId'
  | 'cryptoTransactionId'

export const params: Record<TParamsKeys, TParamsKeys> = {
  userId: 'userId',
  applicationId: 'applicationId',
  accountId: 'accountId',
  topUpId: 'topUpId',
  withdrawId: 'withdrawId',
  walletId: 'walletId',
  exchangeId: 'exchangeId',
  userList: 'userList',
  accountList: 'accountList',
  walletList: 'walletList',
  fiatTransactionId: 'fiatTransactionId',
  cryptoTransactionId: 'cryptoTransactionId',
}

export const routePaths = {
  dashboard: '/',
  users: {
    list: '/users',
    user: `/users/:${params.userId}`,
    userList: `/users/:${params.userId}/:${params.userList}`,
  },
  applications: {
    list: '/applications',
    individualApplication: `/applications/:${params.applicationId}/individual`,
    corporateApplication: `/applications/:${params.applicationId}/corporate`,
  },
  exchanges: {
    list: '/exchanges',
    exchange: `/exchanges/:${params.exchangeId}`,
  },
  accounts: {
    list: '/accounts',
    account: `/accounts/:${params.accountId}`,
    accountList: `/accounts/:${params.accountId}/:${params.accountList}`,
  },
  topUps: {
    list: '/top-ups',
    topUp: `/top-ups/:${params.topUpId}`,
  },
  withdraws: {
    list: '/withdraws',
    withdraw: `/withdraws/:${params.withdrawId}`,
  },
  wallets: {
    list: '/wallets',
    wallet: `/wallets/:${params.walletId}`,
    walletList: `/wallets/:${params.walletId}/:${params.walletList}`,
  },
  fiatTransactions: {
    list: '/fiat-transactions',
    transaction: `/fiat-transactions/:${params.fiatTransactionId}`,
  },
  cryptoTransactions: {
    list: '/crypto-transactions',
    transaction: `/crypto-transactions/:${params.cryptoTransactionId}`,
  },
}

type TKeys = keyof typeof routePaths

export type TRoutePaths = (typeof routePaths)[TKeys]
