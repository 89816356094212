import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { AccountsListPage } from '../ui'

export const AccountsListRouter: RouteObject = {
  path: routePaths.accounts.list,
  element: <AccountsListPage />,
}
