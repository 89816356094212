import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { TransactionsTable } from '@widgets/transaction'

import { useCryptoTransactionsFilters } from '@features/transaction'

import { useGetCryptoTransactions } from '@entities/transaction'
import { useGetWalletsForFilter } from '@entities/wallet'

import { TParamsKeys } from '@shared/constants'
import { useCustomPagination, useFilterByDate } from '@shared/ui'

export function UserTransactionsTable() {
  const { userId } = useParams<TParamsKeys>()
  const { dateFilterNode, dateFrom, dateTo, reset } = useFilterByDate()

  const { page, rowsPerPage, paginationNode, setTotalPages, setListLength } =
    useCustomPagination()

  const {
    currentWallet,
    isLoading: isWalletsLoading,
    walletAddressFilterOptions,
    wallets,
  } = useGetWalletsForFilter({ userId })

  const { currentIncomingType, currentStatus, currentType, filtersOptions } =
    useCryptoTransactionsFilters(true)

  const { data: transactions, isLoading: transactionsLoading } =
    useGetCryptoTransactions({
      currentIncomingType,
      currentStatus,
      currentType,
      dateFrom,
      dateTo,
      page,
      rowsPerPage,
      walletAddress: currentWallet.split(' ')[0], // currentWallet = `${wallet.address} (${wallet.blockchain})`
      enabled: !!wallets,
    })

  useEffect(() => {
    if (transactions) {
      setTotalPages(transactions.totalPages)

      setListLength(transactions.list.length)
    }
  }, [transactions?.totalPages])

  return (
    <TransactionsTable
      isLoading={transactionsLoading || isWalletsLoading}
      rowsPerPage={+rowsPerPage}
      paginationNode={paginationNode}
      transactions={transactions?.list}
      filtersOptions={filtersOptions}
      dateFilter={dateFilterNode}
      resetDateFilter={reset}
      type="crypto"
      isOwnPage={false}
      walletAddressFilterOptions={walletAddressFilterOptions}
    />
  )
}
