import {
  Box,
  Container,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { BeneficiariesList, IRef } from '@widgets/corporate-application'

import { StatusApplication } from '@features/application'

import { BackButton, GoToUserPageLink } from '@features/buttons'
import { TParamsKeys } from '@shared/constants'
import {
  IBeneficiary,
  ICorporateApplication,
  IMainApplicationInfo,
} from '@shared/types'
import { StyledTableRow, StyledToolbar } from '@shared/ui'

import { getCorporateApplication } from '../model'
import { RowCorporateApplicationInfo } from './components'

export function CorporateApplicationPage() {
  const { t } = useTranslation('pages')
  const { applicationId } = useParams<TParamsKeys>()
  const benRef = useRef<IRef>(null)
  const [mainInfo, setMainInfo] = useState<IMainApplicationInfo>()
  const [beneficiariesList, setBeneficiariesList] = useState<IBeneficiary[]>()

  const getBeneficiariesAndMainInfoAndTransformApplicationInArrayForRender = (
    corporateApplication: ICorporateApplication
  ) => {
    setBeneficiariesList(
      corporateApplication.finalBeneficiaries
        ? (JSON.parse(
            corporateApplication.finalBeneficiaries
          ) as IBeneficiary[])
        : undefined
    )

    setMainInfo(corporateApplication.application)

    const applicationForRender = JSON.parse(
      JSON.stringify(corporateApplication)
    ) as Omit<
      ICorporateApplication,
      'finalBeneficiaries' | 'application' | 'id'
    >

    if ('finalBeneficiaries' in applicationForRender) {
      delete applicationForRender['finalBeneficiaries']
    }

    if ('application' in applicationForRender) {
      delete applicationForRender['application']
    }

    if ('id' in applicationForRender) {
      delete applicationForRender['id']
    }

    return _.map(applicationForRender, (value, key) => ({ value, key }))
  }

  const {
    data: corporateApplication,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['get-corporate-application', applicationId],
    queryFn: async () => {
      const application = await getCorporateApplication({ applicationId })

      const applicationForRender =
        getBeneficiariesAndMainInfoAndTransformApplicationInArrayForRender(
          application
        )

      // Put the name before the address
      const nameIndex = applicationForRender.findIndex(
        (item) => item.key === 'name'
      )

      const name = applicationForRender.splice(nameIndex, 1)

      const addressIndex = applicationForRender.findIndex(
        (item) => item.key === 'address'
      )

      applicationForRender.splice(addressIndex, 0, ...name)

      return applicationForRender
    },
  })

  return (
    <>
      <StyledToolbar variant="dense">
        <BackButton />

        <Stack direction="row" spacing={1}>
          <Typography>{`${t('corporate-application.title')} of`}</Typography>

          {mainInfo?.userId && (
            <GoToUserPageLink
              userId={mainInfo.userId}
              userName={`${mainInfo?.firstName} ${mainInfo?.lastName}`}
            />
          )}
        </Stack>

        <StatusApplication
          type="corporate"
          statusApplication={mainInfo?.status}
          applicationId={applicationId}
          validateBeneficiaries={async () => {
            return await benRef.current?.validateBeneficiaries()
          }}
          refetchApplication={refetch}
        />
      </StyledToolbar>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}

      {!isLoading && (
        <Container maxWidth={false}>
          <Paper>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <StyledTableRow hover tabIndex={-1}>
                    <TableCell>
                      {t(
                        'corporate-application.object keys.finalBeneficiaries'
                      )}
                    </TableCell>

                    <TableCell>
                      <BeneficiariesList
                        disabled={mainInfo?.status !== 'received'}
                        savedBeneficiariesList={beneficiariesList}
                        applicationId={applicationId}
                        refetchCorporateApplication={refetch}
                        ref={benRef}
                      />
                    </TableCell>
                  </StyledTableRow>

                  {corporateApplication?.map((item) => (
                    <RowCorporateApplicationInfo
                      objectKey={item.key}
                      value={item.value}
                      key={item.key}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Container>
      )}
    </>
  )
}
