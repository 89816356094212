import { SxProps, Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IConfirmCancelExchangeProps } from '../model'
import { ConfirmExchangeModal } from './components'

interface IProps extends IConfirmCancelExchangeProps {
  sx?: SxProps
}

export function ConfirmExchange(props: IProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { t } = useTranslation('features')

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsOpen(true)}
        sx={props.sx}
      >
        {t('exchange.confirm-exchange')}
      </Button>

      <ConfirmExchangeModal
        show={isOpen}
        onClose={() => setIsOpen(false)}
        {...props}
      />
    </>
  )
}
