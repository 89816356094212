import { instance } from '@shared/requester'
import { TTopUpStatus } from '@shared/types'

interface IRequest {
  code: string
  topUpId: string
}

interface IResponse {
  id: string
  status: TTopUpStatus
}

export async function verifySmsTopUp({ topUpId, code }: IRequest) {
  return instance
    .patch<IResponse>(`accounts/topup/${topUpId}/confirm/verify-sms`, { code })
    .then((response) => response.data)
}
