import { LoadingButton } from '@mui/lab'
import { SxProps, Box, Stack, Typography } from '@mui/material'
import { startOfMonth, format, isValid } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  DateRange,
  ExcludeScam,
  IDateRange,
  StatisticsMultipleSelect,
} from '@features/statistics'

import {
  TStatisticsOperation,
  getUserStatistics,
  statisticsOperations,
} from '@entities/statistics'

import { accountTypes, TParamsKeys } from '@shared/constants'
import { TAccountType } from '@shared/types'
import { SimpleSelect } from '@shared/ui'
import { saveDataToCSV } from '@shared/utils'

interface IProps {
  sx?: SxProps
}

type TType = TAccountType | 'all'

const typeOptions: TType[] = ['all', ...accountTypes]

export function UserStatistics({ sx }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [excludeScam, setExcludeScam] = useState(true)

  const [selectedType, setSelectedType] = useState<TType>('all')

  const [selectedOperations, setSelectedOperations] = useState<
    TStatisticsOperation[]
  >([])

  const [dateRange, setDateRange] = useState<IDateRange>({
    from: startOfMonth(new Date()),
    to: new Date(),
  })

  const { userId } = useParams<TParamsKeys>()
  const { t } = useTranslation(['pages', 'shared'])

  const handleDownloadUserStatistics = async () => {
    try {
      setIsLoading(true)

      if (!(isValid(dateRange.from) && isValid(dateRange.to))) {
        toast.error('Must be a valid date')

        return
      }

      const response = await getUserStatistics({
        dateFrom: format(dateRange.from, 'yyyy-MM-dd'),
        dateTo: format(dateRange.to, 'yyyy-MM-dd'),
        operations: selectedOperations,
        userId: userId || '',
        type: selectedType !== 'all' ? selectedType : undefined,
        excludeScam,
      })

      const from = format(dateRange.from, 'dd-MM-yyyy')
      const to = format(dateRange.to, 'dd-MM-yyyy')
      const operations = selectedOperations.join('-')

      const fileName = `statistics_by_user-id_${userId}__${from}_${to}${
        operations && '_' + operations
      }${selectedType !== 'all' ? '_' + selectedType : ''}`

      await saveDataToCSV(response, fileName)
    } catch (error) {
      toast.error(t('shared:errors.download'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box p={2} sx={sx}>
      <Typography variant="h5" mb={2}>
        {t('user.statistics.title')}
      </Typography>

      <Stack spacing={2} mb={3}>
        <Stack direction="row" spacing={3}>
          <DateRange
            dateRange={dateRange}
            loading={isLoading}
            setDateRange={setDateRange}
          />

          <ExcludeScam
            excludeScam={excludeScam}
            setExcludeScam={setExcludeScam}
          />
        </Stack>

        <Stack direction="row" spacing={2}>
          <SimpleSelect
            onChange={setSelectedType}
            selectedOption={selectedType}
            options={typeOptions}
            label={t('user.statistics.account-type')}
          />

          <StatisticsMultipleSelect
            selected={selectedOperations}
            setSelected={setSelectedOperations}
            options={statisticsOperations as unknown as TStatisticsOperation[]}
            label={t('user.statistics.operations')}
          />
        </Stack>
      </Stack>

      <LoadingButton
        loading={isLoading}
        variant="contained"
        onClick={handleDownloadUserStatistics}
      >
        {t('shared:buttons.download')}
      </LoadingButton>
    </Box>
  )
}
