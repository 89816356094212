import { useState } from 'react'

import { TWithdrawsStatusFilterType } from '@entities/withdraw'

import { IFilterOptions } from '@shared/ui'

const filterOptions: TWithdrawsStatusFilterType[] = [
  'all',
  'CANCELED_BY_AML',
  'CANCELED_BY_USER',
  'CONFIRM_BY_USER',
  'CREATED',
  'SUCCESS',
]

export function useWithdrawsFilters() {
  const [currentStatus, setCurrentStatus] =
    useState<TWithdrawsStatusFilterType>('all')

  const filtersOptions: IFilterOptions[] = [
    {
      options: filterOptions,
      currentOption: currentStatus,
      setCurrentOption: setCurrentStatus,
      filterName: 'status',
    },
  ]

  return { filtersOptions, currentStatus }
}
