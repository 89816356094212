export const userLists = {
  accounts: 'accounts',
  applications: 'applications',
  topUps: 'top-ups',
  wallets: 'wallets',
  withdraws: 'withdraws',
  exchanges: 'exchanges',
  fees: 'fees',
  transactions: 'transactions',
  statistic: 'statistic',
}
