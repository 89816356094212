import { toast } from 'react-toastify'

import { instance } from '@shared/requester'
import { IBaseConfirmationResponse } from '@shared/types'

interface IRequest {
  accountId?: string
}

interface IResponse {
  id: string
  confirmation: IBaseConfirmationResponse
}

export async function sendSmsTopUp({ accountId }: IRequest) {
  if (!accountId) {
    toast.error('Something went wrong!')

    return
  }

  return instance
    .patch<IResponse>(`accounts/topup/${accountId}/confirm/send-sms`)
    .then((response) => response.data)
}
