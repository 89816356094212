import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useGetWallets } from '@entities/wallet'

import { TWalletType } from '@shared/types'
import { IFilterOptions } from '@shared/ui'

interface IProps {
  userId?: string
  walletType?: TWalletType
}

export const walletSearchParamName = 'wallet'

export function useGetWalletsForFilter({ userId, walletType }: IProps) {
  const [currentWallet, setCurrentWallet] = useState<string>('')
  const [searchParams, setSearchParams] = useSearchParams()
  const walletFromParams = searchParams.get(walletSearchParamName)

  const { data: wallets, isLoading } = useGetWallets({
    page: 1,
    rowsPerPage: '1000',
    userId,
  })

  const allWallets = useMemo(
    () =>
      wallets?.list.reduce((acc, wallet) => {
        if (!walletType) {
          acc.push(`${wallet.address} (${wallet.blockchain})`)

          return acc
        }

        if (walletType === wallet.type) {
          acc.push(`${wallet.address} (${wallet.blockchain})`)

          return acc
        }

        return acc
      }, [] as string[]),
    [wallets?.list]
  )

  useEffect(() => {
    allWallets && !walletFromParams && setCurrentWallet(allWallets[0])
  }, [allWallets])

  const walletAddressFilterOptions: IFilterOptions = useMemo(
    () => ({
      options: allWallets || [],
      currentOption: currentWallet,
      setCurrentOption: setCurrentWallet,
      filterName: walletSearchParamName,
    }),
    [allWallets, currentWallet]
  )

  useLayoutEffect(() => {
    walletFromParams && setCurrentWallet(walletFromParams)
  }, [])

  useEffect(() => {
    searchParams.set(walletSearchParamName, currentWallet)

    setSearchParams(searchParams)
  }, [currentWallet])

  return {
    currentWallet,
    isLoading,
    walletAddressFilterOptions,
    wallets,
  }
}
