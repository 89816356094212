import { SxProps, Grid, Typography } from '@mui/material'
import { IWalletBalance } from '@shared/types'

interface IProps {
  type: IWalletBalance['type']
  name: IWalletBalance['name']
  balance: IWalletBalance['balance']
  sx?: SxProps
}

export function RenderWalletBalance({ balance, name, type, sx }: IProps) {
  return (
    <Grid container sx={sx}>
      <Grid item xs={3}>
        <Typography>{type}</Typography>
      </Grid>

      <Grid item xs={8}>
        <Typography>{`${balance} ${name}`}</Typography>
      </Grid>
    </Grid>
  )
}
