import { useState } from 'react'

import {
  TExchangesStatusFilterType,
  TExchangesMethodFilterType,
  TExchangesTypeFilterType,
  exchangeStatuses,
} from '@entities/exchange'

import { IFilterOptions } from '@shared/ui'

const statusFilterOptions: TExchangesStatusFilterType[] = [
  'all',
  ...exchangeStatuses,
]

const methodFilterOptions: TExchangesMethodFilterType[] = [
  'all',
  'AUTO',
  'KRAKEN',
]

const typeFilterOptions: TExchangesTypeFilterType[] = ['all', 'SELL', 'BUY']

export function useExchangesFilters() {
  const [currentType, setCurrentType] =
    useState<TExchangesTypeFilterType>('all')

  const [currentStatus, setCurrentStatus] =
    useState<TExchangesStatusFilterType>('all')

  const [currentMethod, setCurrentMethod] =
    useState<TExchangesMethodFilterType>('all')

  const filtersOptions: IFilterOptions[] = [
    {
      options: statusFilterOptions,
      currentOption: currentStatus,
      setCurrentOption: setCurrentStatus,
      filterName: 'status',
    },
    {
      options: typeFilterOptions,
      currentOption: currentType,
      setCurrentOption: setCurrentType,
      filterName: 'type',
    },
    {
      options: methodFilterOptions,
      currentOption: currentMethod,
      setCurrentOption: setCurrentMethod,
      filterName: 'method',
    },
  ]

  return { filtersOptions, currentType, currentStatus, currentMethod }
}
