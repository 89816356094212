import { IExchange } from '@entities/exchange'

import { ExchangesListItem } from './exchanges-list-item'

interface IProps {
  exchanges?: IExchange[]
}

export function ExchangesList({ exchanges }: IProps) {
  return (
    <>
      {exchanges?.map((exchange) => (
        <ExchangesListItem
          {...exchange}
          key={exchange.outputId}
          walletName={`${exchange.wallet.corporateName || exchange.wallet.label}`}
        />
      ))}
    </>
  )
}
