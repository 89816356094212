export type TOndatoStatuses =
  | 'APPROVED'
  | 'AWAITING'
  | 'CANCELED'
  | 'NEW'
  | 'REJECTED'
  | 'SENT_CALLBACK'

export interface IUsersListItem {
  id: string //"34",
  createdAt: string //"2023-09-27T13:46:47.000Z",
  updatedAt: string //"2024-01-12T14:20:11.000Z",
  keycloackId: string //uuid,
  statusOndato: TOndatoStatuses
  displayName: string | null
  phoneNumber: string | null
  emailAddress: string | null
  active: 'true' | 'false'
  blocked: 'true' | 'false'
}

export enum EUserStatuses {
  UNVERIFIED = 0,
  APPROVED = 1,
  REJECTED = 2,
}

interface IMetaData {
  finish_time: string
  ip: string
  start_time: string
  user_agent: string
  privacyPolicyArbiex: string
  termsAndConditionsArbiex: string
}

export interface IUser {
  id: string
  createdAt: string
  updatedAt: string
  keycloackId: string
  status: EUserStatuses
  statusAml: EUserStatuses
  statusIndividual: EUserStatuses
  statusCorporate: EUserStatuses
  statusOndato: TOndatoStatuses | null
  phoneNumber: string
  emailAddress: string
  firstName?: string | null
  lastName?: string | null
  metadata: IMetaData | IMetaData[]
}
