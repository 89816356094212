import { instance } from '@shared/requester'
import { IWallet } from '@shared/types'

export async function getWallet(id?: string) {
  if (!id) {
    throw new Error()
  }

  return instance
    .get<IWallet>(`/wallets/${id}`)
    .then((response) => response.data)
}
