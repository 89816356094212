import { styled } from '@mui/material'

export const StyledBox = styled('div')(() => ({
  borderRadius: '4px',
  padding: '12px 8px',
  border: '1px solid',
  borderColor: '#BDBDBD',
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  minWidth: 160,
  height: 64,
}))
