import { Box, Chip, TableCell } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { routePaths } from '@shared/constants'
import { IUsersListItem } from '@shared/types'
import {
  CopyButton,
  DateWithTodayYesterdayBadge,
  StyledTableRow,
} from '@shared/ui'
import { useOndatoStatus } from '../../model'

export function TableBodyListItem({ user }: { user: IUsersListItem }) {
  const navigate = useNavigate()

  const { getOndatoStatus, getOndatoStatusColor } = useOndatoStatus()

  const isCopyBtnRequired = (copiedValue: string | null) => {
    if (copiedValue) {
      return <CopyButton value={copiedValue || ''} />
    }

    return
  }

  return (
    <StyledTableRow
      hover
      tabIndex={-1}
      key={user.id}
      onClick={() => navigate(`${routePaths.users.list}/${user.id}`)}
      sx={{
        backgroundColor:
          user.active === 'false' ? 'rgba(255, 0, 0, 0.1)' : 'inherit',
      }}
    >
      <TableCell>{user.id}</TableCell>

      <TableCell>
        <Box>
          {user.displayName}
          {isCopyBtnRequired(user?.displayName)}
        </Box>
      </TableCell>

      <TableCell>
        {user.statusOndato && (
          <Chip
            size="small"
            label={getOndatoStatus(user.statusOndato)}
            color={getOndatoStatusColor(user.statusOndato)}
            sx={{ minWidth: 100 }}
          />
        )}
      </TableCell>

      <TableCell>
        <Box>
          {user.emailAddress}
          {isCopyBtnRequired(user.emailAddress)}
        </Box>
      </TableCell>

      <TableCell>
        <Box>
          {user.phoneNumber}
          {isCopyBtnRequired(user.phoneNumber)}
        </Box>
      </TableCell>

      <TableCell>
        <DateWithTodayYesterdayBadge date={user.createdAt} />
      </TableCell>

      <TableCell>
        {user.active === 'false' && (
          <Box
            sx={{
              color: 'white',
              p: '2px 4px',
              background: 'red',
              borderRadius: '4px',
              textAlign: 'center',
            }}
          >
            Inactive
          </Box>
        )}
      </TableCell>
    </StyledTableRow>
  )
}
