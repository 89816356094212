import { LoadingButton } from '@mui/lab'
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { changeTariff } from '@entities/exchange'

import { TParamsKeys } from '@shared/constants'
import { ITariffPlanInfo, IUserPlanInfo, TAccountType } from '@shared/types'
import { StyledToolbar } from '@shared/ui'

interface IProps {
  currentPlan: IUserPlanInfo
  allPlans: ITariffPlanInfo[]
  type: TAccountType
  refetchUserTariffs: () => void
}

export function UserPlan({
  allPlans,
  currentPlan,
  type,
  refetchUserTariffs,
}: IProps) {
  const { t } = useTranslation(['pages', 'common'])
  const { userId } = useParams<TParamsKeys>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [selectedTariff, setSelectedTariff] = useState<ITariffPlanInfo>(
    allPlans[0]
  )

  const handleSelectTariff = (event: SelectChangeEvent) => {
    setSelectedTariff(
      allPlans.find((plan) => plan.id === +event.target.value) || allPlans[0]
    )
  }

  useEffect(() => {
    setSelectedTariff(
      allPlans.find((plan) => plan.id === currentPlan.id) || allPlans[0]
    )
  }, [allPlans])

  const handleChangePlan = async () => {
    try {
      if (!userId) {
        return
      }

      setIsLoading(true)

      await changeTariff({
        tariffId: selectedTariff.id.toString(),
        userId,
        userType: type,
      })

      refetchUserTariffs()
    } catch (error) {
      toast.error(t('common:oops'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box flex={1}>
      <StyledToolbar variant="dense" sx={{ mb: 2 }}>
        <Typography variant="h5" mx="auto">
          {`${t(`user.fees.${type}-plan`)} (${currentPlan?.name})`}
        </Typography>
      </StyledToolbar>

      <Box width={530} mx="auto">
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item md={12} sx={{ display: 'flex', gap: 2 }}>
            <FormControl fullWidth sx={{ maxWidth: 356 }}>
              <InputLabel id="plan-select">
                {t('user.fees.input-label')}
              </InputLabel>

              <Select
                labelId="plan-select"
                id="select-plan-select"
                value={selectedTariff.id.toString()}
                renderValue={(id) =>
                  `${selectedTariff.name}${currentPlan.id === +id ? ` (${t('user.fees.current')})` : ''}`
                }
                label={t('user.fees.input-label')}
                onChange={handleSelectTariff}
              >
                {allPlans.map((plan) => (
                  <MenuItem value={plan.id} key={plan.id}>
                    {`${plan.name}${currentPlan.id === plan.id ? ` (${t('user.fees.current')})` : ''}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <LoadingButton
              onClick={handleChangePlan}
              loading={isLoading}
              disabled={selectedTariff.id === currentPlan.id}
              variant="contained"
              sx={{ width: 220, height: '100%' }}
            >
              {t('user.fees.button-change-plan')}
            </LoadingButton>
          </Grid>

          {selectedTariff && (
            <Grid item xs={12}>
              {selectedTariff.steps.map((tariff, index) => (
                <Stack direction="row" key={index}>
                  <Typography flexBasis={200}>
                    {`${tariff.min}${tariff.max ? ` - ${tariff.max}` : ''}:`}
                  </Typography>

                  <Typography>
                    {`${tariff.per}%${tariff.abs ? ` (min. ${tariff.abs} EUR)` : ''}`}
                  </Typography>
                </Stack>
              ))}
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}
