import { Link, Typography, useTheme } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useParams } from 'react-router'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { BackButton } from '@features/buttons'

import { TParamsKeys, routePaths } from '@shared/constants'
import { IWallet, IWalletBalance } from '@shared/types'
import { StyledToolbar } from '@shared/ui'

import { IWalletContext, getWallet, walletLists } from '../model'
import { WalletTabs, WalletMainInfo } from './components'

interface IErrorResponse {
  statusCode: number
  message: string
}

export interface IConvertValueProps {
  value?: string | IWalletBalance[]
  objectKey: string
}

export function WalletPage() {
  const theme = useTheme()
  const { walletId } = useParams<TParamsKeys>()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    data: wallet,
    isLoading,
    isError,
    failureReason,
  } = useQuery<IWallet, AxiosError<IErrorResponse>>({
    queryKey: ['get-top-up', walletId],
    queryFn: () => getWallet(walletId),
  })

  useEffect(() => {
    isError && toast.error(`${failureReason?.response?.data.message}`)
  }, [failureReason])

  const context: IWalletContext = {
    walletAddress: wallet?.address,
  }

  useEffect(() => {
    if (location.pathname.split('/').filter((item) => item).length < 3) {
      navigate(
        `${routePaths.wallets.list}/${walletId}/${walletLists.exchanges}`,
        {
          replace: true,
        }
      )
    }
  }, [])

  return (
    <>
      <StyledToolbar
        sx={{
          bgcolor: theme.palette.primary.light,
          color: theme.palette.common.white,
          gap: 3,
        }}
        variant="dense"
      >
        <BackButton />

        <Typography
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <Trans
            ns="pages"
            i18nKey="wallet.title"
            values={{
              userName: wallet?.label,
            }}
            components={{
              a: (
                <Link
                  href={`${routePaths.users.list}/${wallet?.userId}`}
                  sx={{ color: '#fff', textDecorationColor: '#fff' }}
                />
              ),
            }}
          />
        </Typography>
      </StyledToolbar>

      <WalletMainInfo isLoading={isLoading} wallet={wallet} />

      {wallet && (
        <>
          <WalletTabs />
          <Outlet context={context} />
        </>
      )}
    </>
  )
}
