import { useQuery } from '@tanstack/react-query'

import { getExchangesList } from '../../api'
import {
  IExchange,
  IGetExchangesListRequestParams,
  TExchangeStatus,
  TExchangesMethodFilterType,
  TExchangesStatusFilterType,
  TExchangesTypeFilterType,
} from '../types'

interface IProps
  extends Omit<IGetExchangesListRequestParams, 'method' | 'type' | 'status'> {
  status?: TExchangesStatusFilterType
  type?: TExchangesTypeFilterType
  method?: TExchangesMethodFilterType
  enabled?: boolean
}

export function useGetExchangesList({
  page,
  perPage,
  method,
  status,
  type,
  userId,
  wallet,
  enabled = true,
}: IProps) {
  // const deleteAllFromParam = <T,>(param: T): Omit<T, 'all'> | undefined => {
  //   return param !== 'all' ? param : undefined
  // }
  // TODO: need to fix typing
  const deleteAllFromParam = (param: unknown): unknown | undefined => {
    return param !== 'all' ? param : undefined
  }

  const query = useQuery({
    queryKey: [
      'get-exchanges-list',
      page,
      perPage,
      method,
      type,
      status,
      userId,
      wallet,
    ],
    queryFn: () =>
      getExchangesList({
        page,
        perPage,
        method: deleteAllFromParam(method) as IExchange['method'] | undefined,
        type: deleteAllFromParam(type) as IExchange['type'] | undefined,
        status: deleteAllFromParam(status) as TExchangeStatus | undefined,
        userId,
        wallet,
      }),
    refetchInterval: 30 * 1000,
    enabled,
  })

  return query
}
