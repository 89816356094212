import {
  Badge,
  Collapse,
  Divider,
  List,
  ListItem,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material'
import { Fragment } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useSidebarItems } from '../content'
import { SidebarMenuListItemButton, StyledListItemIcon } from '../styled'
import { FadeIcon } from './fade-icon'

interface ISidebarMenuListProps {
  sx?: SxProps
  open: boolean
  hover: boolean
  onClickItem?: () => void
}

export function SidebarMenuList({
  sx,
  open,
  hover,
  onClickItem,
}: ISidebarMenuListProps) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const sidebarItems = useSidebarItems()

  const getMainPath = () => pathname.split('/').filter((item) => item)[0]

  const isActivePath = (path?: string) => {
    if (!path) {
      return false
    }

    return getMainPath() === path.replaceAll('/', '')
  }

  return (
    <List sx={sx}>
      {sidebarItems.map((item) => (
        <Fragment key={item.id}>
          <ListItem disablePadding sx={{ position: 'relative', ...item.sx }}>
            <Tooltip
              title={item.title}
              disableHoverListener={open || hover}
              placement={'right'}
              enterDelay={500}
              leaveDelay={200}
            >
              <Badge
                badgeContent={item.actionsQuantity}
                color="secondary"
                sx={{ width: '100%' }}
              >
                <SidebarMenuListItemButton
                  selected={isActivePath(item.navigationLink)}
                  disabled={item.disabled}
                  onClick={() => {
                    onClickItem && onClickItem()

                    item.navigationLink && navigate(item.navigationLink)

                    item.action && item.action()
                  }}
                >
                  <StyledListItemIcon open={open || hover}>
                    <FadeIcon show={isActivePath(item.navigationLink)}>
                      <item.iconFill sx={{ color: 'background.paper' }} />
                    </FadeIcon>

                    <FadeIcon show={!isActivePath(item.navigationLink)}>
                      <item.icon sx={{ color: 'primary.main' }} />
                    </FadeIcon>
                  </StyledListItemIcon>

                  <Collapse in={open || hover} orientation={'horizontal'}>
                    <Typography
                      mb={-0.25}
                      variant={'body1'}
                      sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                    >
                      {item.title}
                    </Typography>
                  </Collapse>
                </SidebarMenuListItemButton>
              </Badge>
            </Tooltip>
          </ListItem>

          {item.divider && <Divider />}
        </Fragment>
      ))}
    </List>
  )
}
