import to from 'await-to-js'
import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { ultimateConfirmExchange } from '@entities/exchange'

import { cancelExchange, confirmExchange } from '../api'
import { IConfirmCancelExchangeModal } from '../types'

interface IProps
  extends Pick<
    IConfirmCancelExchangeModal,
    'id' | 'refetchExchanges' | 'status' | 'type' | 'onClose'
  > {
  resetForm?: () => void
  getAmount?: () => number
}

export function useConfirmCancelExchange({
  id,
  refetchExchanges,
  status,
  type,
  onClose,
  resetForm,
  getAmount,
}: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isCryptoTxDoneStatus = useMemo(
    () => status === 'CRYPTO_TX_DONE',
    [status]
  )

  const { t } = useTranslation(['common', 'features', 'shared'])

  const handleCancel = async () => {
    try {
      setIsLoading(true)

      const [error] = await to(
        cancelExchange(
          type === 'BUY'
            ? { id, type }
            : { id, type, crypto: status === 'CRYPTO_TX_DONE' ? true : false }
        )
      )

      if (error) {
        toast.error(t('try again'))

        return
      }

      toast.success(t('features:exchange.confirm-modal.canceled-message'))

      await refetchExchanges()
    } finally {
      onClose()

      setIsLoading(false)
    }
  }

  const handleConfirmExchange = async () => {
    if (!getAmount || !resetForm || !onClose) {
      toast.error(t('oops'))

      return
    }

    try {
      setIsLoading(true)

      await confirmExchange(
        isCryptoTxDoneStatus && type === 'SELL'
          ? { crypto: true, id, type }
          : { amount: getAmount(), id, type }
      )

      refetchExchanges()

      setTimeout(resetForm, 300)
    } catch {
      resetForm()

      toast.error(t('oops'))
    } finally {
      onClose()

      setIsLoading(false)
    }
  }

  const handleUltimateConfirmExchange = async () => {
    try {
      setIsLoading(true)

      await ultimateConfirmExchange(id)

      await refetchExchanges()

      onClose()

      toast.success(t('shared:success'))
    } catch (error) {
      toast.error(t('try again'))
    } finally {
      setIsLoading(false)
    }
  }

  return {
    handleConfirmExchange,
    handleCancel,
    isCryptoTxDoneStatus,
    isLoading,
    handleUltimateConfirmExchange,
  }
}
