import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { CryptoTransactionsListPage, FiatTransactionsListPage } from '../ui'

export const TransactionsListRouter: RouteObject[] = [
  {
    path: routePaths.fiatTransactions.list,
    element: <FiatTransactionsListPage />,
  },
  {
    path: routePaths.cryptoTransactions.list,
    element: <CryptoTransactionsListPage />,
  },
]
