import { parseISO, format } from 'date-fns'

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  pattern?: string
}

export const convertValueToDate = ({
  value,
  pattern = 'dd.MM.yy HH.mm',
}: IProps) => {
  try {
    if (!value) {
      throw new Error()
    }

    return format(parseISO(value), pattern)
  } catch {
    return value
  }
}
