import { Box, Stack } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { getExchangesTariffsList } from '@entities/exchange'
import { getUserTariffs } from '@entities/user'

import { TParamsKeys } from '@shared/constants'

import { UserPlan } from './user-plan'

export function UserFees() {
  const { userId } = useParams<TParamsKeys>()

  const { data: allTariffs } = useQuery({
    queryKey: ['all-tariffs'],
    queryFn: getExchangesTariffsList,
  })

  const { data: currentTariffs, refetch: refetchUserTariffs } = useQuery({
    queryKey: ['user-tariffs', userId],
    queryFn: () => getUserTariffs(userId),
  })

  return (
    <>
      {allTariffs && (
        <>
          <Stack
            direction="row"
            divider={<Box width="1px" bgcolor="divider" />}
          >
            {currentTariffs?.individual && (
              <UserPlan
                type="individual"
                allPlans={allTariffs}
                currentPlan={currentTariffs.individual}
                refetchUserTariffs={refetchUserTariffs}
              />
            )}

            {currentTariffs?.corporate && (
              <UserPlan
                type="corporate"
                allPlans={allTariffs}
                currentPlan={currentTariffs.corporate}
                refetchUserTariffs={refetchUserTariffs}
              />
            )}
          </Stack>
        </>
      )}
    </>
  )
}
