import { IStep } from '../types'

export const sellAuto: IStep[] = [
  {
    defaultTitle: 'Created',
    conditionalStatuses: [
      {
        key: 'NEW',
        title: 'New exchange request',
        additionalText: 'User created a request',
      },
      {
        key: 'CANCELED',
        title: 'Canceled',
        additionalText: 'Exchange request is exists',
      },
    ],
  },
  {
    defaultTitle: 'User approved',
    conditionalStatuses: [
      {
        key: 'USER_APPROVED',
        title: 'User approved',
        additionalText: 'Approved by user',
      },
      {
        key: 'USER_REJECTED',
        title: 'User rejected',
        additionalText: 'Rejected by user',
      },
    ],
  },
  {
    defaultTitle: 'Transaction sent',
    conditionalStatuses: [
      {
        key: 'TRANSACTION_SENT',
        title: 'Transaction sent',
      },
    ],
  },
]
