import {
  Box,
  CircularProgress,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useKeycloak } from '@react-keycloak/web'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { Sidebar, SidebarMobile } from '@widgets/sidebar'

export function MainLayout() {
  const [loading, setLoading] = useState(true)
  const { keycloak } = useKeycloak()
  const theme = useTheme()
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    if (keycloak.authenticated) {
      setLoading(false)
    }
  }, [keycloak.authenticated])

  if (loading) {
    return (
      <Box
        minHeight="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Stack direction={mdAndUp ? 'row' : 'column'} gap={2} minHeight="100vh">
      {mdAndUp ? <Sidebar /> : <SidebarMobile />}

      <Box
        component="main"
        sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        <Outlet />
      </Box>
    </Stack>
  )
}
