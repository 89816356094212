import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'

export const ResendButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: 'unset',
  color: '#0155B7',
  fontSize: 16,
  padding: '16px 34px',
  borderRadius: 12,
  [theme.breakpoints.up('md')]: {
    fontSize: 20,
    lineHeight: '30px',
  },
  '&:disabled': {
    backgroundColor: '#f1f1f1',
  },
}))
