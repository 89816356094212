import { IAccount } from '@entities/accounts'

import { instance } from '@shared/requester'

export async function getAccount(id?: string) {
  if (!id) {
    throw new Error()
  }

  return instance
    .get<IAccount>(`/accounts/${id}`)
    .then((response) => response.data)
}
