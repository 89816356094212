import { instance } from '@shared/requester'
import { IUser } from '@shared/types'

export async function getUser(id?: string) {
  if (!id) {
    throw new Error()
  }

  return instance.get<IUser>(`/users/${id}`).then((response) => response.data)
}
