import {
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { TApplicationsStatusFilterType } from '@entities/applications-list'

import { routePaths } from '@shared/constants'
import { IMainApplicationInfo, ITableHeadCell } from '@shared/types'
import {
  DateWithTodayYesterdayBadge,
  FiltersMenu,
  ListLoader,
  StyledTableHeadCell,
  // StyledTableLink,
  StyledTableRow,
  StyledToolbar,
  IFilterOptions,
} from '@shared/ui'
import { getStatusApplicationColor } from '@shared/utils'

type TTableColumnName =
  | 'id'
  | 'full name'
  | 'status'
  | 'account type'
  | 'created at'

interface IProps {
  isLoading: boolean
  currentStatus: TApplicationsStatusFilterType
  applicationsList?: IMainApplicationInfo[]
  filterMenuOptions: IFilterOptions[]
  rowsPerPage: number
  paginationNode: JSX.Element
  isOwnPage?: boolean
}

export function ApplicationsTable({
  currentStatus,
  filterMenuOptions,
  isLoading,
  paginationNode,
  rowsPerPage,
  applicationsList,
  isOwnPage = true,
}: IProps) {
  const { t } = useTranslation('widgets')
  const navigate = useNavigate()

  const tableColumnsNames: ITableHeadCell<TTableColumnName>[] = [
    {
      cellName: 'id',
      text: t('applications-table.id'),
    },
    {
      cellName: 'full name',
      text: t('applications-table.full name'),
    },
    {
      cellName: 'status',
      text: t('applications-table.status'),
    },
    {
      cellName: 'account type',
      text: t('applications-table.account type'),
    },
    {
      cellName: 'created at',
      text: t('applications-table.created at'),
    },
  ]

  return (
    <>
      <StyledToolbar variant="dense">
        <Typography>{t('applications-table.title')}</Typography>
        <FiltersMenu filtersOptions={filterMenuOptions} />
      </StyledToolbar>

      {isLoading && <ListLoader skeletonsNumber={+rowsPerPage} />}

      {!isLoading && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {tableColumnsNames.map((columnName) =>
                  !isOwnPage &&
                  columnName.cellName === 'full name' ? undefined : (
                    <StyledTableHeadCell key={columnName.cellName}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography fontSize="inherit" fontWeight="inherit">
                          {columnName.text}

                          {columnName.cellName === 'status' &&
                            currentStatus !== 'all' &&
                            ` (${currentStatus})`}
                        </Typography>
                      </Stack>
                    </StyledTableHeadCell>
                  )
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {applicationsList?.map((application) => (
                <StyledTableRow
                  hover
                  tabIndex={-1}
                  key={application.id}
                  onClick={() =>
                    navigate(
                      `${routePaths.applications.list}/${application.id}/${application.type}`
                    )
                  }
                >
                  <TableCell>{application.id}</TableCell>

                  {isOwnPage && (
                    <TableCell>{`${application.firstName} ${application.lastName}`}</TableCell>
                  )}

                  <TableCell>
                    <Chip
                      size="small"
                      label={t(`applications-table.${application.status}`)}
                      color={getStatusApplicationColor(application.status)}
                      sx={{ minWidth: 80 }}
                    />
                  </TableCell>

                  <TableCell>{application.type}</TableCell>

                  <TableCell>
                    <DateWithTodayYesterdayBadge date={application.createdAt} />
                    {/* <StyledTableLink
                      href={`${routePaths.applications.list}/${application.id}/${application.type}`}
                    /> */}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {paginationNode}
    </>
  )
}
