import { Stack, Typography } from '@mui/material'
import { useMemo } from 'react'
import { IExchange } from '@entities/exchange'
import { useExchangeDetails } from '../model'

interface IProps {
  exchange?: IExchange
}

export function ExchangeIdInfo({ exchange }: IProps) {
  const { transformKeyToDataRow } = useExchangeDetails({ exchange })

  const data = useMemo(() => {
    if (!exchange) {
      return []
    }

    const keys: Array<keyof Omit<IExchange, 'wallet'>> = [
      'uuid',
      'id',
      'outputId',
    ]

    return keys.map(transformKeyToDataRow)
  }, [exchange])

  return (
    <Stack direction={'row'} gap={2}>
      <Stack gap={1}>
        {data.map((row) => (
          <Typography fontWeight={600} key={row.title}>
            {row.title}
          </Typography>
        ))}
      </Stack>

      <Stack gap={1}>
        {data.map((row) => (
          <Typography key={row.title}>{row.value}</Typography>
        ))}
      </Stack>
    </Stack>
  )
}
