import { styled } from '@mui/material'

export const ListIconWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
}))
