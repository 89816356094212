import { convertCentsToEuro } from '../../../utils'

interface IProps {
  cents?: number | string
  currency?: string
}

export function ShowEuro({ cents, currency = '€' }: IProps) {
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      {cents !== undefined && `${convertCentsToEuro(cents)} ${currency}`}
    </span>
  )
}
