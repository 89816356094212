import { instance } from '@shared/requester'
import { TAccountType } from '@shared/types'

import { TStatisticsOperation } from '../model'

interface IGetUserStatisticsRequestParams {
  dateFrom: string //2024-06-01
  dateTo: string //2024-09-01
  excludeScam: boolean //optional, ‘true’|'false', default: 'true'
  operations?: TStatisticsOperation[] //optional, undefined by default, left undefined if not need, available: ["exchange-sell", "exchange-buy", "top-up", "withdraw", "transfer"],
  type?: TAccountType //optional, undefined by default, left undefined if not need, available: ['individual', 'corporate'],
  userId: string //required, user id
}

export async function getUserStatistics(
  params: IGetUserStatisticsRequestParams
) {
  return instance
    .get<Blob>('/wallets/statistic/user', { params })
    .then((response) => response.data)
}
