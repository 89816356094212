import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  SxProps,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Stack,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { CancelExchange, ConfirmExchange } from '@features/exchange'

import { IExchange } from '@entities/exchange'

interface IProps {
  exchange?: IExchange
  refetchExchanges: () => void
  sx?: SxProps
}

export function ExchangeAdvancedOptions({
  refetchExchanges,
  exchange,
  sx,
}: IProps) {
  const { t } = useTranslation('widgets')

  return (
    <Accordion sx={sx}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" fontWeight={600}>
          {t('exchange-details.advanced-options-title')}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        {exchange && (
          <Stack direction="row" gap={2}>
            <CancelExchange refetchExchanges={refetchExchanges} {...exchange} />

            <ConfirmExchange
              refetchExchanges={refetchExchanges}
              {...exchange}
            />
          </Stack>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
