import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { TParamsKeys, expandedParamName, routePaths } from '@shared/constants'
import { CustomTabs, ITab } from '@shared/ui'

import { accountLists } from '../../model'

export const AccountTabs = memo(function AccountTabs() {
  const { t } = useTranslation('pages')
  const { accountId } = useParams<TParamsKeys>()

  const mainPath = `${routePaths.accounts.list}/${accountId}`

  const tabs: ITab[] = [
    {
      label: t('user.tabs.top-ups'),
      value: `${mainPath}/${accountLists.topUps}`,
    },
    {
      label: t('user.tabs.withdraws'),
      value: `${mainPath}/${accountLists.withdraws}`,
    },
    {
      label: t('user.tabs.exchanges'),
      value: `${mainPath}/${accountLists.exchanges}`,
    },
  ]

  return <CustomTabs tabsRoutes={tabs} searchParamName={expandedParamName} />
})
