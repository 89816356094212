import {
  SxProps,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'

interface IProps<T extends string> {
  onChange: (payload: T) => void
  selectedOption: T
  options: T[]
  label?: string
  sx?: SxProps
}

export function SimpleSelect<T extends string>({
  onChange,
  options,
  selectedOption,
  label,
  sx,
}: IProps<T>) {
  const handleChange = (event: SelectChangeEvent<T>) => {
    onChange(event.target.value as T)
  }

  return (
    <FormControl variant="outlined" sx={sx}>
      <InputLabel>{label}</InputLabel>

      <Select
        value={selectedOption}
        label={label}
        onChange={handleChange}
        sx={{ minWidth: 200 }}
      >
        {options.map((option, index) => (
          <MenuItem value={option} key={index}>
            {option.toLowerCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
