import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { StyledTableRow } from '@shared/ui'
import { convertValueToDate } from '@shared/utils'

interface IProps {
  value?: string | object | null
  mainTranslateKey: string
}

export const ConvertTermsAndConditions = ({
  value,
  mainTranslateKey,
}: IProps) => {
  const { t } = useTranslation(['entities', 'pages'])

  try {
    if (!value || typeof value === 'number') {
      throw new Error()
    }

    const parsedValue = typeof value === 'object' ? value : JSON.parse(value)

    if (typeof parsedValue !== 'object') {
      throw new Error()
    }

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1" fontWeight={700}>
            {t('application.show details')}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <TableContainer>
            <Table size="small">
              <TableBody>
                {_.map(parsedValue, (value, objectKey) => (
                  <StyledTableRow hover tabIndex={-1} key={objectKey}>
                    <TableCell sx={{ maxWidth: 140 }}>
                      {t(`${mainTranslateKey}.${objectKey}` as never)}
                    </TableCell>

                    <TableCell>{convertValueToDate({ value })}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    )
  } catch {
    return value ? <>{value}</> : <></>
  }
}
