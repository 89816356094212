import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { ICorporateDetails, TCorporateDetailsKeys } from '@shared/types'

import { OwnersList } from './owners-list'

interface IProps {
  details?: string | null
}

export function CorporateApplicationDetails({ details }: IProps) {
  const { t } = useTranslation('pages')

  const parsedDetailsFromJSON =
    details && (JSON.parse(details) as ICorporateDetails)

  return (
    <>
      {parsedDetailsFromJSON &&
        _.map(parsedDetailsFromJSON, (value, objectKey) => (
          <Accordion key={objectKey}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1" fontWeight={700}>
                {t(`corporate-application.object keys.${objectKey}` as never)}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <OwnersList
                owners={value}
                type={objectKey as TCorporateDetailsKeys}
              />
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  )
}
