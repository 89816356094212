import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { IndividualApplicationPage } from '../ui'

export const IndividualApplicationRouter: RouteObject = {
  path: routePaths.applications.individualApplication,
  element: <IndividualApplicationPage />,
}
