import { styled } from '@mui/material'

export const DesktopSidebarBox = styled('div')(() => ({
  background: '#FFFFFF',
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 24,
  paddingBottom: 24,
  width: '100%',
  height: 'max-content',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}))
