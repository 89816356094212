import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { TransactionsTable } from '@widgets/transaction'

import { getFiatTransactionsList } from '@entities/transaction'

import { useCustomPagination } from '@shared/ui'

export function FiatTransactionsListPage() {
  const { page, rowsPerPage, paginationNode, setTotalPages, setListLength } =
    useCustomPagination()

  const { data: transactions, isLoading } = useQuery({
    queryKey: ['get-fiat-transactions-list', page, rowsPerPage],
    queryFn: () => getFiatTransactionsList({ page, perPage: rowsPerPage }),
    refetchInterval: 30 * 1000,
  })

  useEffect(() => {
    if (transactions) {
      setTotalPages(transactions.totalPages)

      setListLength(transactions.list.length)
    }
  }, [transactions?.totalPages])

  return (
    <TransactionsTable
      isLoading={isLoading}
      rowsPerPage={+rowsPerPage}
      paginationNode={paginationNode}
      transactions={transactions?.list}
      type="fiat"
    />
  )
}
