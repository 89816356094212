import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { RenderWalletBalance, TWalletTypeFilterType } from '@entities/wallet'

import { routePaths } from '@shared/constants'
import { ITableHeadCell, IWallet } from '@shared/types'
import {
  DateWithTodayYesterdayBadge,
  FiltersMenu,
  ListLoader,
  StyledTableHeadCell,
  // StyledTableLink,
  StyledTableRow,
  StyledToolbar,
  IFilterOptions,
} from '@shared/ui'

type TTableColumnName =
  | 'id'
  | 'label'
  | 'blockchain'
  | 'address'
  | 'balance'
  | 'type'
  | 'created at'

interface IProps {
  isLoading: boolean
  currentType: TWalletTypeFilterType
  walletsList?: IWallet[]
  filterMenuOptions: IFilterOptions[]
  rowsPerPage: number
  paginationNode: JSX.Element
}

export function WalletsTable({
  currentType,
  filterMenuOptions,
  isLoading,
  paginationNode,
  rowsPerPage,
  walletsList,
}: IProps) {
  const { t } = useTranslation('widgets')
  const navigate = useNavigate()

  const tableColumnsNames: ITableHeadCell<TTableColumnName>[] = [
    {
      cellName: 'id',
      text: t('wallets-table.id'),
    },
    {
      cellName: 'label',
      text: t('wallets-table.label'),
    },
    {
      cellName: 'blockchain',
      text: t('wallets-table.blockchain'),
    },
    {
      cellName: 'address',
      text: t('wallets-table.address'),
    },
    {
      cellName: 'balance',
      text: t('wallets-table.balance'),
    },
    {
      cellName: 'type',
      text: t('wallets-table.type'),
    },
    {
      cellName: 'created at',
      text: t('wallets-table.created at'),
    },
  ]

  return (
    <>
      <StyledToolbar variant="dense">
        <Typography>{t('wallets-table.title')}</Typography>
        <FiltersMenu filtersOptions={filterMenuOptions} />
      </StyledToolbar>

      {isLoading && <ListLoader skeletonsNumber={+rowsPerPage} />}

      {!isLoading && (
        <>
          <TableContainer sx={{ mb: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {tableColumnsNames.map((columnName) => (
                    <StyledTableHeadCell key={columnName.cellName}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography>
                          {columnName.text}

                          {columnName.cellName === 'type' &&
                            currentType !== 'all' &&
                            ` (${currentType})`}
                        </Typography>
                      </Stack>
                    </StyledTableHeadCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {walletsList?.map((wallet) => (
                  <StyledTableRow
                    hover
                    tabIndex={-1}
                    key={wallet.id}
                    onClick={() =>
                      navigate(`${routePaths.wallets.list}/${wallet.id}`)
                    }
                  >
                    <TableCell>{wallet.id}</TableCell>
                    <TableCell>{wallet.label}</TableCell>
                    <TableCell>{wallet.blockchain}</TableCell>
                    <TableCell>{wallet.address}</TableCell>

                    <TableCell>
                      {wallet.balances.map((balance, index) => (
                        <RenderWalletBalance
                          balance={balance.balance}
                          name={balance.name}
                          type={balance.type}
                          key={index}
                        />
                      ))}
                    </TableCell>

                    <TableCell>{wallet.type}</TableCell>

                    <TableCell>
                      <DateWithTodayYesterdayBadge date={wallet.createdAt} />
                      {/* <StyledTableLink
                        href={`${routePaths.wallets.list}/${wallet.id}`}
                      /> */}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {paginationNode}
    </>
  )
}
