import {
  Box,
  Container,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { StatusApplication } from '@features/application'
import { BackButton, GoToUserPageLink } from '@features/buttons'

import { TParamsKeys } from '@shared/constants'
import { IIndividualApplication, IMainApplicationInfo } from '@shared/types'

import { StyledToolbar } from '@shared/ui'
import { getIndividualApplication } from '../model'
import { RowPersonalApplicationInfo } from './components'

export function IndividualApplicationPage() {
  const { t } = useTranslation('pages')
  const { applicationId } = useParams<TParamsKeys>()
  const [mainInfo, setMainInfo] = useState<IMainApplicationInfo>()

  const getMainInfoAndTransformApplicationForRender = (
    application: IIndividualApplication
  ) => {
    setMainInfo(application?.application)

    const applicationForRender = JSON.parse(
      JSON.stringify(application)
    ) as Omit<IIndividualApplication, 'application' | 'id'>

    if ('application' in applicationForRender) {
      delete applicationForRender['application']
    }

    if ('id' in applicationForRender) {
      delete applicationForRender['id']
    }

    return applicationForRender
  }

  const {
    data: individualApplication,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['get-individual-application', applicationId],
    queryFn: async () => {
      const individualApplication = await getIndividualApplication({
        applicationId,
      })

      return getMainInfoAndTransformApplicationForRender(individualApplication)
    },
  })

  return (
    <>
      <StyledToolbar variant="dense">
        <BackButton />

        <Stack direction="row" spacing={1}>
          <Typography>{`${t('individual-application.title')} of`}</Typography>

          {mainInfo?.userId && (
            <GoToUserPageLink
              userId={mainInfo.userId}
              userName={`${mainInfo?.firstName} ${mainInfo?.lastName}`}
            />
          )}
        </Stack>

        <StatusApplication
          type="individual"
          statusApplication={mainInfo?.status}
          applicationId={applicationId}
          refetchApplication={refetch}
        />
      </StyledToolbar>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}

      {!isLoading && (
        <Container maxWidth={false}>
          <Paper>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {_.map(individualApplication, (value, key) => (
                    <RowPersonalApplicationInfo
                      objectKey={key}
                      value={value}
                      key={key}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Container>
      )}
    </>
  )
}
