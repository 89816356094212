import { Stack, Typography } from '@mui/material'
import { Dispatch, memo, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { DateField } from '@shared/ui'

import { IDateRange } from '../model'

interface IProps {
  setDateRange: Dispatch<SetStateAction<IDateRange>>
  dateRange: IDateRange
  loading: boolean
}

export const DateRange = memo(function DateRange({
  dateRange,
  loading,
  setDateRange,
}: IProps) {
  const { t } = useTranslation('features')

  return (
    <Stack direction="row" spacing={4}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{t('statistics.from')}</Typography>

        <DateField
          value={dateRange.from}
          onChange={(e) =>
            setDateRange((prev) => ({ ...prev, from: e as Date }))
          }
          format="dd.MM.yyyy"
          disabled={loading}
          disableFuture
        />
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{t('statistics.to')}</Typography>

        <DateField
          value={dateRange.to}
          onChange={(e) => setDateRange((prev) => ({ ...prev, to: e as Date }))}
          format="dd.MM.yyyy"
          disabled={loading}
          disableFuture
        />
      </Stack>
    </Stack>
  )
})
