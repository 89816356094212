import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { WalletExchangesTable, WalletPage } from '../ui'
import { walletLists } from './constants'

const mainPath = routePaths.wallets.wallet

export const WalletPageRouter: RouteObject = {
  path: mainPath,
  element: <WalletPage />,
  children: [
    {
      path: `${mainPath}/${walletLists.exchanges}`,
      element: <WalletExchangesTable />,
    },
  ],
}
