import { styled } from '@mui/material'

export const BorderBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  background: theme.palette.background.paper,
  transition: theme.transitions.create(['width', 'height', 'background'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
}))
