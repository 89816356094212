import {
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TWithdrawsStatusFilterType } from '@entities/withdraw'

import { ITableHeadCell, IWithdraw } from '@shared/types'
import {
  ListLoader,
  StyledTableHeadCell,
  IFilterOptions,
  FiltersMenu,
  StyledToolbar,
} from '@shared/ui'

import { WithdrawsList } from './components'

type TTableColumnName = 'id' | 'full name' | 'status' | 'amount' | 'created at'

interface IProps {
  isLoading: boolean
  currentStatus: TWithdrawsStatusFilterType
  withdrawsList?: IWithdraw[]
  filterMenuOptions: IFilterOptions[]
  rowsPerPage: number
  paginationNode: JSX.Element
  isOwnPage?: boolean
}

export function WithdrawsTable({
  currentStatus,
  filterMenuOptions,
  isLoading,
  paginationNode,
  rowsPerPage,
  isOwnPage = true,
  withdrawsList,
}: IProps) {
  const { t } = useTranslation('widgets')

  const tableColumnsNames: ITableHeadCell<TTableColumnName>[] = [
    {
      cellName: 'id',
      text: t('withdraws-table.id'),
    },
    {
      cellName: 'full name',
      text: t('withdraws-table.full name'),
    },
    {
      cellName: 'status',
      text: t('withdraws-table.status'),
    },
    {
      cellName: 'amount',
      text: t('withdraws-table.amount'),
    },
    {
      cellName: 'created at',
      text: t('withdraws-table.created at'),
    },
  ]

  return (
    <>
      <StyledToolbar variant="dense">
        <Typography>{t('withdraws-table.title')}</Typography>
        <FiltersMenu filtersOptions={filterMenuOptions} />
      </StyledToolbar>

      {isLoading && <ListLoader skeletonsNumber={+rowsPerPage} />}

      {!isLoading && (
        <>
          <TableContainer sx={{ mb: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {tableColumnsNames.map((columnName) =>
                    !isOwnPage &&
                    columnName.cellName === 'full name' ? undefined : (
                      <StyledTableHeadCell key={columnName.cellName}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography>
                            {columnName.text}

                            {columnName.cellName === 'status' &&
                              currentStatus !== 'all' &&
                              ` (${currentStatus})`}
                          </Typography>
                        </Stack>
                      </StyledTableHeadCell>
                    )
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                <WithdrawsList
                  withdraws={withdrawsList}
                  isOwnPage={isOwnPage}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {paginationNode}
    </>
  )
}
