import { PaletteOptions } from '@mui/material'
import { blue, deepBlue, green, grey, orange, red } from '@shared/constants'

export const palette = {
  common: {
    white: '#FFFFFF',
    black: '#021218',
    backdrop: '#848689',
  },
  primary: {
    dark: blue[700],
    main: blue[500],
    light: blue[50],
    contrastText: '#FFFFFF',
  },
  secondary: {
    dark: '#e200ff',
    main: '#e200ff',
    light: '#e200ff',
    contrastText: '#e200ff',
  },
  text: {
    primary: '#011217',
    secondary: grey[500],
    disabled: '#C0C9D1',
  },
  success: {
    dark: green[800],
    main: green[500],
    light: green[50],
    contrastText: '#FFFFFF',
  },
  error: {
    dark: red[800],
    main: red[500],
    light: red[50],
    contrastText: '#FFFFFF',
  },
  warning: {
    dark: orange[700],
    main: orange[500],
    light: orange[50],
    contrastText: '#FFFFFF',
  },
  info: {
    dark: deepBlue[800],
    main: deepBlue[500],
    light: deepBlue[50],
    contrastText: '#FFFFFF',
  },
} satisfies PaletteOptions
