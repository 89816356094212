import { Chip, ChipOwnProps } from '@mui/material'

import { EUserStatuses, TOndatoStatuses } from '@shared/types'

import { getStatusOndatoColor } from '../../../model'

interface IProps {
  value?: string | number | null
  objectKey:
    | 'statusAml'
    | 'statusIndividual'
    | 'statusCorporate'
    | 'statusOndato'
    | 'status'
}

export const convertStatusValueToColoredCircle = ({
  value,
  objectKey,
}: IProps) => {
  const getStatusColor = (value: EUserStatuses): ChipOwnProps['color'] => {
    switch (value) {
      case EUserStatuses.APPROVED:
        return 'success'
      case EUserStatuses.UNVERIFIED:
        return 'warning'
      case EUserStatuses.REJECTED:
      default:
        return 'error'
    }
  }

  const getStatusLabel = (value: EUserStatuses) => {
    switch (value) {
      case EUserStatuses.APPROVED:
        return 'APPROVED'
      case EUserStatuses.UNVERIFIED:
        return 'UNVERIFIED'
      case EUserStatuses.REJECTED:
        return 'REJECTED'
      default:
        return
    }
  }

  switch (objectKey) {
    case 'status':
    case 'statusAml':
    case 'statusCorporate':
    case 'statusIndividual':
      return (
        <Chip
          size="small"
          label={getStatusLabel(value as EUserStatuses)}
          color={getStatusColor(value as EUserStatuses)}
          sx={{ minWidth: 120 }}
        />
      )
    case 'statusOndato':
      return (
        <Chip
          size="small"
          label={value || 'NO TRIES YET'}
          color={getStatusOndatoColor(value as TOndatoStatuses)}
          sx={{ minWidth: 120 }}
        />
      )
    default:
      return value
  }
}
