import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { ITopUpForm } from '../types'

export function useTopUpForm() {
  const { t } = useTranslation(['features', 'common'])

  const amountSchema = (minAmount = 5) =>
    yup
      .string()
      .required(t('common:validation.required'))
      .test({
        name: 'min-amount',
        message: t('top up account.errors.min-sum_interval', {
          count: minAmount,
          postProcess: 'interval',
        }),
        test: (value) => _.toNumber(value) >= minAmount,
      })

  const formSchema: yup.ObjectSchema<ITopUpForm> = yup.object().shape({
    amount: amountSchema(5),
    accountUuid: yup.string().required(t('common:validation.required')),
    // .uuid(t('top up account.errors.error account uuid')),
  })

  const topUpForm = useForm<ITopUpForm>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      amount: '',
      accountUuid: '',
    },
  })

  return { topUpForm }
}
