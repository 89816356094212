import { Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'

interface IProps {
  title: string
  value: string | ReactNode
  color?: 'error' | 'warning'
}

export function ExchangeRowInfo({ title, value, color }: IProps) {
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      width={'100%'}
      gap={5}
    >
      <Typography variant={'body1'} color={'text.secondary'}>
        {title}
      </Typography>

      {typeof value === 'string' ? (
        <Typography
          variant={'body1'}
          color={color === 'warning' ? 'warning.light' : color}
        >
          {value}
        </Typography>
      ) : (
        value
      )}
    </Stack>
  )
}
