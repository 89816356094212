import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Autocomplete, Box, Paper, TextField } from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'

import { countries } from '@shared/constants'

import { IInputProps, TCountry, TCountryAbbreviation } from '@shared/types'

interface IProps extends IInputProps<unknown[]> {
  onChange?: (...event: unknown[]) => void
  variant?: 'filled' | 'outlined' | 'standard'
  value?: TCountryAbbreviation[]
}

export const MultipleCountrySelectInput = forwardRef(function Named(
  props: IProps,
  ref
) {
  const {
    placeholder,
    error,
    helperText,
    label,
    onChange,
    sx,
    variant,
    name,
    disabled,
    fullWidth,
    value,
  } = props

  const [autocompleteValues, setAutocompleteValues] = useState<
    TCountry[] | undefined
  >(
    countries.filter((country) => value?.includes(country.abbreviation)) ||
      undefined
  )

  useEffect(() => {
    setAutocompleteValues(
      countries.filter((item) => value?.includes(item.abbreviation)) ||
        undefined
    )
  }, [value])

  return (
    <Box sx={sx}>
      <Autocomplete
        multiple
        disabled={disabled}
        fullWidth={fullWidth}
        defaultValue={undefined}
        value={autocompleteValues}
        clearOnBlur
        disablePortal
        options={countries}
        getOptionLabel={(option) => option.name}
        popupIcon={!disabled && <ExpandMoreIcon sx={{ color: '#009EE0' }} />}
        PaperComponent={({ children }) => <Paper>{children}</Paper>}
        onChange={(_, option) => {
          if (onChange) {
            onChange(option.map((item) => item.abbreviation))
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant}
            fullWidth={fullWidth}
            label={label}
            placeholder={placeholder}
            error={error}
            name={name}
            inputRef={ref}
            helperText={helperText || ''}
          />
        )}
      />
    </Box>
  )
})
