import {
  getStatusExchangeColor,
  IExchange,
  TExchangeStatus,
} from '@entities/exchange'
import { buyAuto, buyKraken, sellAuto, sellKraken } from '../constants'

const exchangeStepFlows = {
  buyAuto,
  buyKraken,
  sellAuto,
  sellKraken,
}

export const getFlowName = (
  type: IExchange['type'],
  method: IExchange['method']
) => {
  if (type === 'BUY') {
    return method === 'AUTO' ? 'buyAuto' : 'buyKraken'
  }

  if (type === 'SELL') {
    return method === 'AUTO' ? 'sellAuto' : 'sellKraken'
  }
}

export const getFlowSteps = (
  type: IExchange['type'],
  method: IExchange['method']
) => {
  const flowName = getFlowName(type, method)

  return flowName ? exchangeStepFlows[flowName] : []
}

export const getStatusLabelColor = (
  status: TExchangeStatus,
  type: IExchange['type']
): 'warning' | 'error' | undefined => {
  if (!status) {
    return
  }

  const warningStatuses = [
    'EXCHANGE_TECH_DONE',
    'EXCHANGE_ORDER_DONE',
    'CRYPTO_TX_DONE',
  ]

  if (
    warningStatuses.includes(status) &&
    !(type === 'BUY' && status === 'EXCHANGE_ORDER_DONE')
  ) {
    return 'warning'
  }

  if (getStatusExchangeColor(status) === 'error') {
    return 'error'
  }
}
