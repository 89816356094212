import { instance } from '@shared/requester'

import { IGetListResponse } from '@shared/types'

import { IExchange, IGetExchangesListRequestParams } from '../model'

export async function getExchangesList({
  page,
  perPage,
  status,
  userId,
  method,
  type,
  wallet,
}: IGetExchangesListRequestParams) {
  return instance
    .get<IGetListResponse<IExchange>>('/exchanges', {
      params: {
        'user-id': userId,
        'cur-page': page,
        'per-page': perPage,
        wallet,
        type,
        method,
        status,
      },
    })
    .then((response) => response.data)
}
