import { LoadingButton } from '@mui/lab'
import { SxProps, Box, Typography, Stack } from '@mui/material'
import { startOfMonth, format, isValid } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
  IDateRange,
  ExcludeScam,
  StatisticsMultipleSelect,
  UsersAutocomplete,
  DateRange,
} from '@features/statistics'

import {
  getAllStatistics,
  statisticsOperations,
  TStatisticsOperation,
} from '@entities/statistics'

import { saveDataToCSV } from '@shared/utils'

interface IProps {
  sx?: SxProps
}

export function DownloadAllStatistics({ sx }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [excludeScam, setExcludeScam] = useState(true)
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])

  const [selectedOperations, setSelectedOperations] = useState<
    TStatisticsOperation[]
  >([])

  const [dateRange, setDateRange] = useState<IDateRange>({
    from: startOfMonth(new Date()),
    to: new Date(),
  })

  const { t } = useTranslation(['pages', 'shared'])

  const handleDownloadAllStatistics = async () => {
    try {
      setIsLoading(true)

      if (!(isValid(dateRange.from) && isValid(dateRange.to))) {
        toast.error('Must be a valid date')

        return
      }

      const response = await getAllStatistics({
        dateFrom: format(dateRange.from, 'yyyy-MM-dd'),
        dateTo: format(dateRange.to, 'yyyy-MM-dd'),
        operations: selectedOperations,
        userIds: selectedUserIds,
        excludeScam,
      })

      const from = format(dateRange.from, 'dd-MM-yyyy')
      const to = format(dateRange.to, 'dd-MM-yyyy')
      const operations = selectedOperations.join('-')
      const userIds = selectedUserIds.join('-')

      const fileName = `statistics_${from}_${to}${
        operations && '_' + operations
      }${userIds && '_' + userIds}`

      await saveDataToCSV(response, fileName)
    } catch (error) {
      toast.error(t('shared:errors.download'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box sx={sx}>
      <Typography variant="h5" mb={2}>
        {t('dashboard.statistics.title')}
      </Typography>

      <Stack spacing={2} mb={3}>
        <Stack direction="row" spacing={3}>
          <DateRange
            dateRange={dateRange}
            loading={isLoading}
            setDateRange={setDateRange}
          />

          <ExcludeScam
            excludeScam={excludeScam}
            setExcludeScam={setExcludeScam}
          />
        </Stack>

        <Stack direction="row" spacing={3}>
          <StatisticsMultipleSelect
            selected={selectedOperations}
            setSelected={setSelectedOperations}
            options={statisticsOperations as unknown as TStatisticsOperation[]}
            label={t('dashboard.statistics.operations-select-label')}
          />

          <UsersAutocomplete setSelectedUsersIds={setSelectedUserIds} />
        </Stack>
      </Stack>

      <LoadingButton
        loading={isLoading}
        variant="contained"
        onClick={handleDownloadAllStatistics}
      >
        {t('shared:buttons.download')}
      </LoadingButton>
    </Box>
  )
}
