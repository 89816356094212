import { SxProps, Box, Stack, Skeleton } from '@mui/material'

interface IProps {
  skeletonsNumber: number
  sx?: SxProps
}

export function ListLoader({ skeletonsNumber, sx }: IProps) {
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Stack spacing={0.5} mb={2}>
        {new Array(+skeletonsNumber).fill(null).map((_, index) => (
          <Skeleton variant="rectangular" height={32} key={index} />
        ))}
      </Stack>
    </Box>
  )
}
