import { Skeleton, Step, StepLabel, Stepper } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IExchange } from '@entities/exchange'

import { useStepsByMethodType } from '../../model'

interface IProps {
  exchange?: IExchange
}

export function ExchangeStepper({ exchange }: IProps) {
  const { t } = useTranslation('widgets')

  const {
    generateLabelProps,
    flowSteps,
    activeStep,
    activeStepMessage,
    activeStepIsLoading,
    activeStepAdditionalMessage,
  } = useStepsByMethodType({ exchange })

  const getStepLabel = (index: number) => {
    return activeStep.withOptional === index
      ? exchange?.status
        ? activeStepMessage
        : ' '
      : t(
          `exchange-stepper.steps-label-messages.${flowSteps[index].defaultTitle}`
        )
  }

  const getLabelProps = (index: number) => {
    return activeStep.withOptional === index && exchange
      ? generateLabelProps(
          exchange.status,
          exchange.type,
          activeStepIsLoading,
          activeStepAdditionalMessage
        )
      : {
          sx: {
            '& .MuiStepLabel-label': {
              lineHeight: '1.2',
            },
          },
        }
  }

  const stepsForRender = useMemo(
    () =>
      flowSteps.map((step, index) => {
        const labelProps = getLabelProps(index)

        if (step?.optional && activeStep.withOptional !== index) {
          return
        }

        return (
          <Step key={index}>
            <StepLabel {...labelProps}>{getStepLabel(index)}</StepLabel>
          </Step>
        )
      }),
    [flowSteps, activeStep.withOptional]
  )

  const activeStepForRender = useMemo(
    () =>
      activeStep.noOptional === stepsForRender.filter((step) => step).length - 1
        ? activeStep.noOptional + 1
        : activeStep.noOptional,
    [activeStep.noOptional, stepsForRender.length]
  )

  return (
    <>
      {!exchange && (
        <Skeleton variant="rectangular" height={60} width={'100%'} />
      )}

      {exchange && (
        <Stepper activeStep={activeStepForRender}>
          {stepsForRender.map((step) => step)}
        </Stepper>
      )}
    </>
  )
}
