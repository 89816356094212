import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { IConfirmExchangeForm } from '../types'

export function useConfirmExchangeForm() {
  const { t } = useTranslation(['features', 'common'])

  const formSchema: yup.ObjectSchema<IConfirmExchangeForm> = yup
    .object()
    .shape({
      amount: yup.string().required(t('common:validation.required')),
    })

  const confirmExchangeForm = useForm<IConfirmExchangeForm>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      amount: '',
    },
  })

  return { confirmExchangeForm }
}
