import { SxProps, Stack } from '@mui/material'
import { convertValueToDate } from '@shared/utils'
import { TodayYesterdayBadge } from '../today-badge'

interface IProps {
  date: string | number | Date
  sx?: SxProps
}

export function DateWithTodayYesterdayBadge({ date, sx }: IProps) {
  return (
    <Stack direction="row" gap={1} alignItems="center" sx={sx}>
      {convertValueToDate({ value: date })}
      <TodayYesterdayBadge date={date} />
    </Stack>
  )
}
