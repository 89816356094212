import { useEffect } from 'react'

import { ApplicationsTable } from '@widgets/applications'

import { useApplicationsFilters } from '@features/applications'

import { useGetAllApplications } from '@entities/applications-list'

import { useCustomPagination } from '@shared/ui'

export function ApplicationsListPage() {
  const {
    page,
    rowsPerPage,
    paginationNode,
    setTotalPages,
    setListLength,
    setPage,
  } = useCustomPagination()

  const { currentStatus, filtersOptions } = useApplicationsFilters()

  const { data: applications, isLoading } = useGetAllApplications({
    currentStatus,
    page,
    rowsPerPage,
  })

  useEffect(() => {
    if (applications) {
      setTotalPages(applications.totalPages)

      setListLength(applications.list.length)
    }
  }, [applications?.totalPages])

  useEffect(() => {
    setPage(1)
  }, [currentStatus])

  return (
    <ApplicationsTable
      isLoading={isLoading}
      currentStatus={currentStatus}
      filterMenuOptions={filtersOptions}
      rowsPerPage={+rowsPerPage}
      paginationNode={paginationNode}
      applicationsList={applications?.list}
    />
  )
}
