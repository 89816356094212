export interface IUploadedDocsLink {
  s3Link: string
  contentType: 'image/jpeg' | 'image/jpg' | 'image/png' | 'application/pdf'
}

export const isObjectOfIUploadedDocsLink = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any[]
): object is IUploadedDocsLink[] => {
  if ('s3Link' in object[0] && 'contentType' in object[0]) {
    return true
  }

  return false
}
