import { TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ImgListWithModal } from '@entities/application'

import { IUploadedDocsLink, TAddressKeys, TDocumentKeys } from '@shared/types'
import { StyledTableRow } from '@shared/ui'
import { convertCountryCodeToFullCountryName } from '@shared/utils'

interface IProps {
  value: string | IUploadedDocsLink[]
  objectKey: TDocumentKeys | TAddressKeys
}

export function DocumentListItem({ value, objectKey }: IProps) {
  const { t } = useTranslation('pages')

  const convertValue = () => {
    if (typeof value === 'object' && value !== null) {
      return <ImgListWithModal docs={value} />
    }

    switch (objectKey) {
      case 'type':
      case 'number':
      case 'street':
      case 'streetAddress':
      case 'zipCode':
        return value
      default:
        return convertCountryCodeToFullCountryName({
          value,
          defaultValue: value,
        })
    }
  }

  return (
    <StyledTableRow hover tabIndex={-1} key={objectKey}>
      <TableCell>
        {t(`corporate-application.object keys.${objectKey}` as never)}
      </TableCell>

      <TableCell>{convertValue()}</TableCell>
    </StyledTableRow>
  )
}
