import { LoadingButton } from '@mui/lab'
import { Button, Stack } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { IMainApplicationInfo, TAccountType } from '@shared/types'

import { TStatusApplication, handleApprovalOfApplicationStatus } from '../modal'

interface IProps {
  applicationId?: string
  statusApplication?: IMainApplicationInfo['status']
  type: TAccountType
  validateBeneficiaries?: () => Promise<boolean | undefined>
  refetchApplication: () => void
}

export function StatusApplication({
  applicationId,
  statusApplication,
  type,
  validateBeneficiaries,
  refetchApplication,
}: IProps) {
  const { t } = useTranslation(['features', 'common'])
  const confirm = useConfirm()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleDecision = async (status: TStatusApplication) => {
    try {
      setIsLoading(true)

      await handleApprovalOfApplicationStatus({ applicationId, type, status })

      await refetchApplication()

      toast.success('Application status changed successful')
    } catch (error) {
      toast.error(t('common:oops'))
    } finally {
      setIsLoading(false)
    }
  }

  const confirmModal = (status: TStatusApplication) => {
    confirm({
      description: t('application.question', {
        action: status === 'approved' ? 'approve' : 'reject',
      }),
    }).then(() => {
      handleDecision(status)
    })
  }

  const handleClick = async (status: TStatusApplication) => {
    const isValid =
      validateBeneficiaries && status === 'approved'
        ? await validateBeneficiaries()
        : true

    if (isValid) {
      confirmModal(status)
    }
  }

  return (
    <>
      {statusApplication === 'received' && (
        <Stack direction="row" spacing={2}>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="success"
            sx={{ minWidth: 120 }}
            onClick={() => handleClick('approved')}
          >
            {t('application.approve')}
          </LoadingButton>

          <LoadingButton
            loading={isLoading}
            variant="contained"
            sx={{ minWidth: 120 }}
            color="error"
            onClick={() => handleClick('rejected')}
          >
            {t('application.reject')}
          </LoadingButton>
        </Stack>
      )}

      {statusApplication === 'rejected' && (
        <Button
          variant="contained"
          disabled
          sx={{
            '&.Mui-disabled': {
              bgcolor: '#d32f2f',
              color: 'white',
            },
          }}
        >
          {t('application.rejected')}
        </Button>
      )}

      {statusApplication === 'approved' && (
        <Button
          variant="contained"
          disabled
          sx={{
            '&.Mui-disabled': {
              bgcolor: 'green',
              color: 'white',
            },
          }}
        >
          {t('application.approved')}
        </Button>
      )}
    </>
  )
}
