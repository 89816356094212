import { FormControl, styled } from '@mui/material'

export const StyledFormControl = styled(FormControl)(() => ({
  flexDirection: 'row',
  gap: 16,
  alignItems: 'center',
  '& .MuiInputBase-root': {
    marginTop: 0,
  },
}))
