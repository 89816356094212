import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { ImgListWithModal } from '@entities/application'

import {
  TAccountType,
  TAddressKeys,
  TDocumentKeys,
  TOwnersDetailsKeys,
  isObjectOfIUploadedDocsLink,
} from '@shared/types'
import { StyledTableRow } from '@shared/ui'
import { convertCountryCodeToFullCountryName } from '@shared/utils'

interface IProps {
  object: object
  type: TAccountType | 'user'
}

export function MapObjectValue({ object, type }: IProps) {
  const { t } = useTranslation(['pages', 'entities'])

  const convertValue = (
    objectKey: TAddressKeys | TOwnersDetailsKeys | TDocumentKeys,
    value: object | null | string
  ) => {
    if (typeof value === 'object' && value !== null) {
      return <MapObjectValue object={value} type={type} />
    }

    switch (objectKey) {
      case 'city':
      case 'street':
      case 'streetAddress':
      case 'zipCode':
      case 'dateOfBirthday':
      case 'personalNumber':
      case 'number':
      case 'firstName':
      case 'lastName':
        return value
      default:
        return convertCountryCodeToFullCountryName({
          value,
          defaultValue: value,
        })
    }
  }

  if (Array.isArray(object)) {
    if (typeof object[0] !== 'object') {
      return <></>
    }

    if (isObjectOfIUploadedDocsLink(object)) {
      return <ImgListWithModal docs={object} />
    }

    return (
      <>
        {object.map((item, index) => (
          <Fragment key={index}>{item}</Fragment>
        ))}
      </>
    )
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1" fontWeight={700}>
          {t('entities:application.show details')}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {_.map(object, (item, objectKey) => (
                <StyledTableRow hover tabIndex={-1} key={objectKey}>
                  <TableCell>
                    {type === 'user' &&
                      t(`user.object keys.${objectKey}` as never)}

                    {type !== 'user' &&
                      t(
                        `${type}-application.object keys.${objectKey}` as never
                      )}
                  </TableCell>

                  <TableCell>
                    {convertValue(objectKey as TAddressKeys, item)}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  )
}
