export const isAdult = (value: string | Date | number) => {
  try {
    const currentDate = new Date()
    const inputDate = new Date(value)

    const ageDifferenceInYears =
      currentDate.getFullYear() - inputDate.getFullYear()

    return ageDifferenceInYears >= 18
  } catch {
    return false
  }
}
