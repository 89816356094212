import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  SxProps,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  LinearProgress,
  Typography,
} from '@mui/material'
import {
  PropsWithChildren,
  SyntheticEvent,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import { expandedParamName } from '@shared/constants'

interface IProps {
  title: string
  loading?: boolean
  defaultExpanded?: boolean
  sx?: SxProps
}

export function CustomAccordion({
  title,
  children,
  loading,
  defaultExpanded,
  sx,
}: IProps & PropsWithChildren) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [isExpanded, setIsExpanded] = useState<boolean>(true)

  const handleChange = () => (_: SyntheticEvent, isExpanded: boolean) => {
    setIsExpanded(isExpanded)
  }

  useLayoutEffect(() => {
    const expanded = searchParams.get(expandedParamName)

    expanded !== null && setIsExpanded(!(expanded === 'false'))
  }, [])

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false)
    } else {
      searchParams.set(expandedParamName, isExpanded.toString())

      setSearchParams(searchParams)
    }
  }, [isExpanded])

  return (
    <Accordion
      sx={sx}
      defaultExpanded={defaultExpanded}
      expanded={isExpanded}
      onChange={handleChange()}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1" fontWeight={700}>
          {title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        {loading && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}

        {!loading && <>{children}</>}
      </AccordionDetails>
    </Accordion>
  )
}
