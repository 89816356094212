import {
  Container,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ICryptoTransaction, IFiatTransaction } from '@shared/types'
import {
  ListLoader,
  StyledTableRow,
  StyledToolbar,
  FiltersMenu,
  IFilterOptions,
  FilterSelect,
} from '@shared/ui'

import { TTransactionTable } from '../model'
import { TransactionsTableRow, TransactionsTableHead } from './components'

interface IMainProps {
  isLoading: boolean
  rowsPerPage: number
  paginationNode: JSX.Element
  isOwnPage?: boolean
}

interface ICryptoTransactionsProps {
  type: Extract<TTransactionTable, 'crypto'>
  transactions?: ICryptoTransaction[]
  filtersOptions: IFilterOptions[]
  dateFilter: JSX.Element
  resetDateFilter: () => void
  walletAddressFilterOptions?: IFilterOptions
}

interface IFiatTransactionsProps {
  type: Extract<TTransactionTable, 'fiat'>
  transactions?: IFiatTransaction[]
  filtersOptions?: undefined
  dateFilter?: undefined
  resetDateFilter?: undefined
  walletAddressFilterOptions?: undefined
}

type TProps = IMainProps & (ICryptoTransactionsProps | IFiatTransactionsProps)

export function TransactionsTable({
  isLoading,
  paginationNode,
  rowsPerPage,
  transactions,
  isOwnPage = true,
  filtersOptions,
  dateFilter,
  type,
  resetDateFilter,
  walletAddressFilterOptions,
}: TProps) {
  const { t } = useTranslation('widgets')

  // TODO: need to add typing for filtersValues
  const filtersValues: Record<string, string> | undefined = useMemo(() => {
    const result =
      filtersOptions &&
      filtersOptions.reduce(
        (acc, curr) => {
          acc[curr.filterName] = curr.currentOption

          return acc
        },
        {} as Record<string, string>
      )

    return result
  }, [filtersOptions])

  const title = useMemo(() => {
    let result = t('transactions-table.title')

    if (
      !isOwnPage &&
      filtersValues &&
      filtersValues['incoming type'] !== 'all'
    ) {
      result += ` (${filtersValues['incoming type']})`
    }

    return result
  }, [t, filtersValues])

  return (
    <>
      <StyledToolbar variant="dense">
        <Typography variant="h6" color="GrayText" fontWeight={100}>
          {title}
        </Typography>

        {walletAddressFilterOptions && (
          <FilterSelect
            {...walletAddressFilterOptions}
            sx={{ width: 'initial' }}
          />
        )}

        {type === 'crypto' && filtersOptions && (
          <FiltersMenu
            filtersOptions={filtersOptions}
            dateFilter={dateFilter}
            resetDateFilter={resetDateFilter}
          />
        )}
      </StyledToolbar>

      {isLoading && <ListLoader skeletonsNumber={+rowsPerPage} />}

      <Container maxWidth={false} disableGutters={!isOwnPage}>
        <Paper elevation={!isOwnPage ? 0 : undefined}>
          {!isLoading && (
            <TableContainer sx={{ mb: 2 }}>
              <Table size="small">
                <TableHead>
                  <TransactionsTableHead
                    type={type}
                    filtersValues={filtersValues}
                    isOwnPage={isOwnPage}
                  />
                </TableHead>

                <TableBody>
                  {transactions?.map((transaction) => {
                    const isCrypto = 'externalId' in transaction

                    return (
                      <StyledTableRow hover tabIndex={-1} key={transaction.id}>
                        <TransactionsTableRow
                          {...transaction}
                          isCrypto={isCrypto}
                          isOwnPage={isOwnPage}
                        />
                      </StyledTableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {paginationNode}
        </Paper>
      </Container>
    </>
  )
}
