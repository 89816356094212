import { Tabs, Tab, Link } from '@mui/material'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useRouteMatch } from '@shared/hooks'

import { ITab } from '../model'

interface IProps {
  tabsRoutes: ITab[]
  searchParamName: string | null
}

export function CustomTabs({ tabsRoutes, searchParamName }: IProps) {
  const [searchParams] = useSearchParams()
  const routeMatch = useRouteMatch([...tabsRoutes.map((route) => route.value)])
  const currentTab = routeMatch?.pattern?.path

  const expandedParam = useMemo(() => {
    const expanded = searchParamName && searchParams.get(searchParamName)

    return expanded === null ? '' : `?${searchParamName}=${expanded}`
  }, [searchParams])

  return (
    <Tabs value={currentTab} sx={{ borderBottom: '1px solid #f1f1f1' }}>
      {tabsRoutes.map((tab, index) => (
        <Tab
          label={tab.label}
          value={tab.value}
          href={`${tab.value}${expandedParam}`}
          component={Link}
          key={index}
        />
      ))}
    </Tabs>
  )
}
