import { instance } from '@shared/requester'
import { IGetListResponse, IWallet, TWalletType } from '@shared/types'

interface IRequestParams {
  page: number
  perPage: string
  userId?: string
  type?: TWalletType
  address?: IWallet['address']
}

export async function getWalletsList({
  page,
  perPage,
  type,
  userId,
  address,
}: IRequestParams) {
  return instance
    .get<IGetListResponse<IWallet>>('/wallets', {
      params: {
        'cur-page': page,
        'per-page': perPage,
        'user-id': userId,
        type,
        address,
      },
    })
    .then((response) => response.data)
}
