import { TDocumentType, DocumentTypes } from '@shared/types'

import { TDocumentPurpose } from '../type'

export function getDocumentTypesList(
  purpose?: TDocumentPurpose
): { type: TDocumentType; name: string }[] {
  switch (purpose) {
    case 'personal':
      return [
        { type: DocumentTypes.PASSPORT, name: 'Passport' },
        { type: DocumentTypes.ID, name: 'ID Card' },
      ]
    case 'company':
      return [{ type: DocumentTypes.LEGAL_DOCS, name: 'LEGAL DOCUMENT' }]
    case 'verification':
    default:
      return []
  }
}
