import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingButton } from '@mui/lab'
import {
  SxProps,
  Box,
  Button,
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import { format, parse } from 'date-fns'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { IBeneficiary } from '@shared/types'

import { updateBeneficiaries, useBeneficiariesForm } from '../model'
import { BeneficiaryListItem } from './components'

interface IProps {
  disabled: boolean
  savedBeneficiariesList?: IBeneficiary[]
  applicationId?: string
  refetchCorporateApplication: () => void
  sx?: SxProps
}

export interface IRef {
  validateBeneficiaries: () => Promise<boolean>
}

export const BeneficiariesList = forwardRef<IRef, IProps>(
  function BeneficiariesList(
    {
      sx,
      savedBeneficiariesList,
      disabled,
      applicationId,
      refetchCorporateApplication,
    },
    ref
  ) {
    const { t } = useTranslation(['widgets', 'common'])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const { addBeneficiary, beneficiaries, form, removeBeneficiary } =
      useBeneficiariesForm()

    const { handleSubmit, setValue, getValues, trigger } = form

    const convertSavedBeneficiariesDateOfBirthdayFieldToDate = (
      savedBeneficiariesList: IBeneficiary[]
    ) => {
      return savedBeneficiariesList.map((beneficiary) => {
        const parsedDate = parse(
          beneficiary.dateOfBirthday,
          'dd.MM.yyyy',
          new Date()
        )

        return { ...beneficiary, dateOfBirthday: parsedDate }
      })
    }

    const convertBeneficiariesFormDateOfBirthdayFieldToString = () => {
      const formBeneficiaries = getValues().beneficiaries

      return formBeneficiaries.map((beneficiary) => {
        const formattedDateOfBirthday = format(
          beneficiary.dateOfBirthday,
          'dd.MM.yyyy'
        )

        return {
          ...beneficiary,
          dateOfBirthday: formattedDateOfBirthday,
        }
      })
    }

    const handleValidateBeneficiaries = async () => {
      const beneficiariesList = getValues().beneficiaries

      if (!beneficiariesList) {
        return false
      }

      const currentBeneficiariesListJSON = JSON.stringify(
        convertBeneficiariesFormDateOfBirthdayFieldToString()
      )

      const isValid = await trigger('beneficiaries', { shouldFocus: true })

      if (!isValid) {
        toast.error(
          t(
            'corporate-application.beneficiaries list.form.errors.invalid fields'
          )
        )

        return false
      }

      const isThereUnsavedChanges =
        currentBeneficiariesListJSON !== JSON.stringify(savedBeneficiariesList)

      if (isThereUnsavedChanges) {
        toast.error(
          t(
            'corporate-application.beneficiaries list.form.errors.unsaved changes'
          )
        )

        return false
      }

      if (beneficiaries.length < 1) {
        toast.error(
          t(
            'corporate-application.beneficiaries list.form.errors.at least one beneficiary'
          )
        )

        return false
      }

      return true
    }

    useEffect(() => {
      if (!savedBeneficiariesList) {
        return
      }

      setValue(
        'beneficiaries',
        convertSavedBeneficiariesDateOfBirthdayFieldToDate(
          savedBeneficiariesList
        )
      )
    }, [savedBeneficiariesList])

    useImperativeHandle(ref, () => ({
      validateBeneficiaries: handleValidateBeneficiaries,
    }))

    const handleUpdateBeneficiaries = async () => {
      try {
        setIsLoading(true)

        if (!applicationId) {
          throw new Error()
        }

        await updateBeneficiaries({
          applicationId,
          data: convertBeneficiariesFormDateOfBirthdayFieldToString() as IBeneficiary[],
        })

        toast.success(
          t('corporate-application.beneficiaries list.beneficiaries saved')
        )

        refetchCorporateApplication()
      } catch {
        // TODO: need to process updateBeneficiaries errors
        toast.error('common:oops')
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <Box
        sx={sx}
        component="form"
        onSubmit={handleSubmit(handleUpdateBeneficiaries)}
      >
        <Box mb={disabled ? 0 : 2}>
          <FormProvider {...form}>
            {beneficiaries.map((item, index) => (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1" fontWeight={700}>
                    {`${t('corporate-application.beneficiaries list.beneficiary')} ${index + 1}`}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <BeneficiaryListItem
                    key={item.id}
                    index={index}
                    disabled={disabled}
                    remove={() => removeBeneficiary(index)}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </FormProvider>
        </Box>

        {!disabled && (
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={addBeneficiary}>
              {t('corporate-application.beneficiaries list.add beneficiary')}
            </Button>

            {beneficiaries.length > 0 && (
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading}
              >
                {t('corporate-application.beneficiaries list.save')}
              </LoadingButton>
            )}
          </Stack>
        )}
      </Box>
    )
  }
)
