import { instance } from '@shared/requester'
import { IGetListResponse, ITopUp, TTopUpStatus } from '@shared/types'

interface IRequestParams {
  page: number
  perPage: string
  userId?: string
  accountId?: string
  status?: TTopUpStatus
}

export async function getTopUpsList({
  page,
  perPage,
  status,
  userId,
  accountId,
}: IRequestParams) {
  return instance
    .get<
      IGetListResponse<ITopUp>
    >(`/accounts/topup/?cur-page=${page}&per-page=${perPage}${userId ? `&user-id=${userId}` : ''}${status ? `&status=${status}` : ''}${accountId ? `&account-id=${accountId}` : ''}`)
    .then((response) => response.data)
}
