import { Stack, Typography } from '@mui/material'
import { useUsersFilters } from '@features/user'
import { StyledTableHeadCell } from '@shared/ui'

interface IProps {
  cellName: string
  text: string
}

const getCurrentStatus = (cellName: string, currentStatus: string) =>
  cellName === 'verification status' &&
  currentStatus !== 'all' &&
  ` (${currentStatus})`

export function TableHeadListItem({ cellName, text }: IProps) {
  const { currentStatus } = useUsersFilters()

  return (
    <StyledTableHeadCell key={cellName}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography fontSize="small" fontWeight="600">
          {text}
          {getCurrentStatus(cellName, currentStatus)}
        </Typography>
      </Stack>
    </StyledTableHeadCell>
  )
}
