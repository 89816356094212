import { convertCountryCodeToFullCountryName } from '@shared/utils'

export const convertFullCountriesNamesFromCountriesCodesString = (
  value?: string | null
) => {
  if (!value) {
    return value
  }

  const countriesCodesArray = value.split(',')

  return countriesCodesArray
    .map((countryCode) =>
      convertCountryCodeToFullCountryName({ value: countryCode })
    )
    .filter((item) => item)
    .join(', ')
}
