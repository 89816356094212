import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { ChangeEvent, useRef, KeyboardEvent, useEffect, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { useFormAmountHelpers } from '@shared/hooks'

import {
  useConfirmExchangeForm,
  IConfirmCancelExchangeModal,
  useConfirmCancelExchange,
} from '../../model'

export function ConfirmCancelExchangeModal({
  onClose: setShow,
  show,
  refetchExchanges,
  buyingAmountFinal,
  buyingCurrency,
  id,
  sellingAmount,
  sellingCurrency,
  status,
  type,
  rateFinal,
  operationType,
}: IConfirmCancelExchangeModal) {
  const { t } = useTranslation(['features', 'common'])
  const formRef = useRef<HTMLFormElement>(null)
  const { confirmExchangeForm } = useConfirmExchangeForm()

  const { control, handleSubmit, setValue, getValues, reset } =
    confirmExchangeForm

  useEffect(() => {
    setTimeout(() => setValue('amount', buyingAmountFinal || '0'), 300)
  }, [show])

  const {
    handleChangeAmount: changeAmountUtil,
    handleKeyDownAmount: keyDownAmountUtil,
    handleKeyUpAmount,
  } = useFormAmountHelpers()

  const onChangeAmount = (value: string) => {
    setValue('amount', value)
  }

  const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.valueAsNumber

    changeAmountUtil(e, onChangeAmount, 200)
  }

  const {
    handleCancel,
    handleConfirmExchange,
    isCryptoTxDoneStatus,
    isLoading,
  } = useConfirmCancelExchange({
    getAmount: () => +getValues('amount'),
    id,
    refetchExchanges,
    resetForm: reset,
    onClose: setShow,
    status,
    type,
  })

  const handleKeyDownAmount = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !isLoading) {
      formRef.current?.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      )

      return
    }

    if (e.key === 'Escape' && !isLoading) {
      handleClose()
    }

    keyDownAmountUtil(e)
  }

  const handleClose = () => {
    setShow()

    setTimeout(reset, 300)
  }

  const isCanceling = useMemo(() => operationType === 'cancel', [operationType])

  return (
    <Dialog
      open={show}
      onClose={!isLoading ? handleClose : undefined}
      aria-labelledby="alert-dialog-title"
      sx={{ '& .MuiPaper-root': { pb: 2 } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography component="span" variant="h5" whiteSpace="pre-wrap">
          <Trans
            ns="features"
            i18nKey={
              operationType === 'confirm'
                ? 'exchange.confirm-modal.title'
                : 'exchange.confirm-modal.title-cancel'
            }
            values={{ uuid: id }}
          />
        </Typography>
      </DialogTitle>

      <form onSubmit={handleSubmit(handleConfirmExchange)} ref={formRef}>
        {!isCryptoTxDoneStatus && (
          <DialogContent sx={{ py: '8px !important' }}>
            <Typography>
              {t('exchange.confirm-modal.sell')}

              <Typography fontWeight={600} component="span">
                {` ${sellingAmount} ${sellingCurrency}`}
              </Typography>
            </Typography>

            <Typography>
              {t('exchange.confirm-modal.buy')}

              <Typography fontWeight={600} component="span">
                {` ${buyingAmountFinal} ${buyingCurrency}`}
              </Typography>
            </Typography>

            <Typography mb={2}>
              {t('exchange.confirm-modal.rate')}

              <Typography fontWeight={600} component="span">
                {` ${rateFinal}`}
              </Typography>
            </Typography>

            <Controller
              control={control}
              name="amount"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  ref={null}
                  inputRef={field.ref}
                  type="tel"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  label={t('exchange.confirm-modal.input-label')}
                  placeholder="Input amount"
                  fullWidth
                  autoFocus
                  disabled={isCanceling}
                  onKeyUp={handleKeyUpAmount}
                  onKeyDown={handleKeyDownAmount}
                  onChange={handleChangeAmount}
                  InputProps={{
                    endAdornment: buyingCurrency,
                  }}
                />
              )}
            />
          </DialogContent>
        )}

        <DialogActions sx={{ px: 3, justifyContent: 'center' }}>
          <LoadingButton
            onClick={handleClose}
            variant="outlined"
            fullWidth
            loading={isLoading}
          >
            {t('common:close')}
          </LoadingButton>

          <LoadingButton
            type={isCanceling ? 'button' : 'submit'}
            loading={isLoading}
            variant="contained"
            onClick={isCanceling ? handleCancel : undefined}
            autoFocus
            fullWidth
            color={isCanceling ? 'error' : undefined}
          >
            {operationType === 'confirm'
              ? t('common:confirm')
              : t('common:cancel')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
