import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { TopUpPage } from '../ui'

export const TopUpPageRouter: RouteObject = {
  path: routePaths.topUps.topUp,
  element: <TopUpPage />,
}
