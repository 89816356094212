import { ChipOwnProps } from '@mui/material'
import { IMainApplicationInfo } from '@shared/types'

export const getStatusApplicationColor = (
  status: IMainApplicationInfo['status']
): ChipOwnProps['color'] => {
  switch (status) {
    case 'approved':
      return 'success'
    case 'received':
      return 'primary'
    case 'rejected':
    default:
      return 'error'
  }
}
