import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded'
import {
  Chip,
  IconButton,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import { BackButton } from '@features/buttons'
import { ConfirmCancelWithdraw } from '@features/withdraws'

import { getWithdrawStatusColor } from '@entities/withdraw'

import { TParamsKeys, routePaths } from '@shared/constants'
import { IWithdraw, TWithdrawStatus } from '@shared/types'
import { ShowEuro, StyledTableRow, StyledToolbar } from '@shared/ui'
import { convertValueToDate } from '@shared/utils'

import { getWithdraw } from '../model'

interface IErrorResponse {
  statusCode: number
  message: string
}

interface IConvertValueProps {
  value?: string | number | null
  objectKey: string
}

export function WithdrawPage() {
  const { t } = useTranslation('pages')
  const { withdrawId } = useParams<TParamsKeys>()

  const {
    data: withdraw,
    isLoading,
    isError,
    failureReason,
    refetch,
  } = useQuery<IWithdraw, AxiosError<IErrorResponse>>({
    queryKey: ['get-top-up', withdrawId],
    queryFn: () => getWithdraw(withdrawId),
  })

  useEffect(() => {
    isError && toast.error(`${failureReason?.response?.data.message}`)
  }, [failureReason])

  const convertValue = ({ value, objectKey }: IConvertValueProps) => {
    const typedObjectKey = objectKey as keyof IWithdraw

    switch (typedObjectKey) {
      case 'createdAt':
      case 'updatedAt':
        return convertValueToDate({ value })
      case 'amount':
        return <ShowEuro cents={value as number} />
      case 'status':
        return (
          <Chip
            size="small"
            label={value}
            color={getWithdrawStatusColor(value as TWithdrawStatus)}
            sx={{ minWidth: 80 }}
          />
        )
      case 'accountId':
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box>{value}</Box>

            <IconButton
              href={`${routePaths.accounts.list}/${withdraw?.accountId}`}
            >
              <ReplyRoundedIcon />
            </IconButton>
          </Stack>
        )
      default:
        return value
    }
  }

  const isShowConfirmCancelWithdraw = useMemo(
    () =>
      withdraw?.status === 'CONFIRM_BY_USER' || withdraw?.status === 'CREATED',
    [withdraw]
  )

  return (
    <>
      <StyledToolbar variant="dense">
        <BackButton />

        <Typography
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          {`${t('withdraw.title')} `}
        </Typography>

        {isShowConfirmCancelWithdraw && withdraw && (
          <ConfirmCancelWithdraw
            refetch={refetch}
            withdrawId={withdraw.id}
            status={withdraw.status}
          />
        )}
      </StyledToolbar>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}

      <TableContainer sx={{ mb: 2 }}>
        <Table size="small">
          <TableBody>
            {_.map(withdraw, (value, objectKey) => (
              <StyledTableRow hover tabIndex={-1} key={objectKey}>
                <TableCell>
                  {t(`withdraw.object-keys.${objectKey}` as never)}
                </TableCell>

                <TableCell>{convertValue({ value, objectKey })}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
