import { instance } from '@shared/requester'
import { IGetListResponse, IWithdraw, TWithdrawStatus } from '@shared/types'

interface IRequestParams {
  page: number
  perPage: string
  userId?: string
  accountId?: string
  status?: TWithdrawStatus
}

export async function getWithdrawsList({
  page,
  perPage,
  accountId,
  status,
  userId,
}: IRequestParams) {
  return instance
    .get<
      IGetListResponse<IWithdraw>
    >(`/accounts/withdraw?cur-page=${page}&per-page=${perPage}${accountId ? `&account-id=${accountId}` : ''}${status ? `&status=${status}` : ''}${userId ? `&user-id=${userId}` : ''}`)
    .then((response) => response.data)
}
