import { Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ContentPaper, StyledToolbar } from '@shared/ui'

import { DownloadAllStatistics } from './components'

export function DashboardPage() {
  const { t } = useTranslation('pages')

  return (
    <>
      <StyledToolbar>
        <Typography variant="h6" color="GrayText" fontWeight={100}>
          {t('main.title')}
        </Typography>
      </StyledToolbar>

      <Container maxWidth={false}>
        <ContentPaper>
          <DownloadAllStatistics />
        </ContentPaper>
      </Container>
    </>
  )
}
