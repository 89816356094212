import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material'
import _ from 'lodash'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { IDirector, IShareholder, TCorporateDetailsKeys } from '@shared/types'

import { OwnersDetails } from './owners-details'

interface IProps {
  owners: IDirector[] | IShareholder[]
  type: TCorporateDetailsKeys
}

export function OwnersList({ owners, type }: IProps) {
  const { t } = useTranslation('pages')

  const getTitle = (objectKey: string, index: number) =>
    `${
      type === 'directors'
        ? `${t('corporate-application.object keys.director')} ${index + 1}`
        : `${t('corporate-application.object keys.shareholder')} ${index + 1}`
    } - ${t(`corporate-application.object keys.${objectKey}` as never)}`

  return (
    <>
      {owners.map((item, index) => (
        <Fragment key={index}>
          {_.map(item, (value, objectKey) => (
            <Accordion key={objectKey}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1" fontWeight={700}>
                  {getTitle(objectKey, index)}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <OwnersDetails value={value} />
              </AccordionDetails>
            </Accordion>
          ))}
        </Fragment>
      ))}
    </>
  )
}
