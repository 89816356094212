import { Box, Container, LinearProgress, Paper } from '@mui/material'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { BackButton } from '@features/buttons'

import { useUserData } from '@entities/user'
import { TParamsKeys, routePaths } from '@shared/constants'
import { StyledToolbar } from '@shared/ui'

import { userLists } from '../model'
import { MainUserInfoList, UserTabs } from './components'

export function UserPage() {
  const { userId } = useParams<TParamsKeys>()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    data: user,
    isLoading,
    isError,
    failureReason,
  } = useUserData({ userId })

  useEffect(() => {
    if (location.pathname.split('/').filter((item) => item).length < 3) {
      navigate(`${routePaths.users.list}/${userId}/${userLists.applications}`, {
        replace: true,
      })
    }
  }, [])

  useEffect(() => {
    isError && toast.error(`${failureReason?.response?.data.message}`)
  }, [failureReason])

  return (
    <>
      <StyledToolbar>
        <BackButton />
      </StyledToolbar>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}

      {!isLoading && (
        <Container maxWidth={false} sx={{ pb: 8 }}>
          <Paper sx={{ pb: 2 }}>
            <MainUserInfoList user={user} />
            <UserTabs />
            <Outlet />
          </Paper>
        </Container>
      )}
    </>
  )
}
