import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded'
import { Chip, IconButton, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getStatusExchangeColor,
  IExchange,
  TExchangeStatus,
} from '@entities/exchange'
import { routePaths } from '@shared/constants'
import { convertValueToDate } from '@shared/utils'

interface IProps {
  exchange?: IExchange
}

interface IConvertValueProps {
  value?: IExchange[keyof Omit<IExchange, 'wallet'>]
  objectKey: string
}

interface IRowData {
  title: string
  value: string | ReactNode
  show?: boolean
}

export function useExchangeDetails({ exchange }: IProps) {
  const { t } = useTranslation('widgets')

  const convertValue = ({ value, objectKey }: IConvertValueProps) => {
    const typedObjectKey = objectKey as keyof IExchange

    switch (typedObjectKey) {
      case 'createdAt':
      case 'updatedAt':
        return convertValueToDate({
          value: value as IExchange['createdAt'],
        })
      case 'buyingAmount':
      case 'buyingAmountFinal':
      case 'buyingAmountSend':
      case 'getFromKraken':
        return value && `${value} ${exchange?.buyingCurrency}`
      case 'sellingAmount':
      case 'sendToKraken':
        return value && `${value} ${exchange?.sellingCurrency}`
      case 'feeAmount':
        return value && `${value} ${exchange?.feeCurrency}`
      case 'status':
        return (
          <Chip
            size="small"
            label={value as TExchangeStatus}
            color={getStatusExchangeColor(value as TExchangeStatus)}
            sx={{ minWidth: 200 }}
          />
        )
      case 'userId':
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box>{value as string}</Box>

            <IconButton
              href={`${routePaths.users.list}/${exchange?.wallet.userId}`}
            >
              <ReplyRoundedIcon />
            </IconButton>
          </Stack>
        )
      default:
        return value
    }
  }

  const transformKeyToDataRow = (
    key: keyof Omit<IExchange, 'wallet'>
  ): IRowData => {
    const value = convertValue({ value: exchange?.[key], objectKey: key })

    let formattedValue: string | ReactNode

    if (value === undefined || value === null) {
      formattedValue = ''
    } else if (typeof value === 'number') {
      formattedValue = String(value)
    } else {
      formattedValue = value
    }

    return {
      title: t(`exchange-details.object-keys.${key}` as never) as string,
      value: formattedValue,
      show: !!formattedValue,
    }
  }

  return { transformKeyToDataRow, convertValue }
}
