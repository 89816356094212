import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { TopUpsTable } from '@widgets/top-up'

import { useTopUpsFilters } from '@features/top-up'

import { getTopUpsList } from '@entities/top-up'

import { TParamsKeys } from '@shared/constants'
import { useCustomPagination } from '@shared/ui'

export function UserTopUpsTable() {
  const { userId } = useParams<TParamsKeys>()

  const { currentStatus, filtersOptions } = useTopUpsFilters()

  const {
    page,
    rowsPerPage,
    paginationNode,
    setTotalPages,
    setListLength,
    setPage,
  } = useCustomPagination()

  const { data: topUps, isLoading } = useQuery({
    queryKey: ['get-user-top-ups', userId, page, rowsPerPage, currentStatus],
    queryFn: () =>
      getTopUpsList({
        page,
        perPage: rowsPerPage,
        userId,
        status: currentStatus !== 'all' ? currentStatus : undefined,
      }),
    refetchInterval: 90 * 1000,
  })

  useEffect(() => {
    if (topUps) {
      setTotalPages(topUps.totalPages)

      setListLength(topUps.list.length)
    }
  }, [topUps?.totalPages])

  useEffect(() => {
    setPage(1)
  }, [currentStatus])

  return (
    <TopUpsTable
      isLoading={isLoading}
      currentStatus={currentStatus}
      filterMenuOptions={filtersOptions}
      rowsPerPage={+rowsPerPage}
      paginationNode={paginationNode}
      topUpsList={topUps?.list}
      isOwnPage={false}
    />
  )
}
