import to from 'await-to-js'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
  ITopUpOptions,
  sendSmsTopUp,
  verifySmsTopUp,
} from '@entities/top-up-account'
import { ITopUpAccountWithOtpProps } from '@entities/top-up-account-with-otp'

import {
  OtpDialog,
  TOtpConfirmationMethod,
  TOtpConfirmationStatuses,
  TOtpResendCodeMethod,
} from '@shared/ui'
import { getTimeToLeftFromTimestamp } from '@shared/utils'

import { CreateTopUpAccount } from './components'

export function TopUpAccountWithOtp({
  accountId,
  accountUuid,
  refetch,
  sxTopUpButton,
}: ITopUpAccountWithOtpProps) {
  const { t } = useTranslation('common')
  const [isShowOtp, setIsShowOtp] = useState<boolean>(false)
  const [topUpOptions, setTopUpOptions] = useState<ITopUpOptions | null>(null)

  const otpErrorResolver = (error: unknown): TOtpConfirmationStatuses => {
    switch (error) {
      case '422':
        return 'NOT_VALID'
      case '400':
        return 'EXPIRE'
      default:
        throw new Error('Something went wrong. Please try again later.')
    }
  }

  const confirmationMethod: TOtpConfirmationMethod = async ({ otp }) => {
    if (!topUpOptions?.id) {
      throw new Error('Top up id is not defined')
    }

    const [error] = await to(
      verifySmsTopUp({ topUpId: topUpOptions?.id, code: otp })
    )

    if (!error) {
      return 'SUCCESS'
    }

    return otpErrorResolver(error?.message)
  }

  const onConfirm = () => {
    toast.success(t('success'))

    setIsShowOtp(false)

    refetch()
  }

  const resendOtp: TOtpResendCodeMethod = async () => {
    const response = await sendSmsTopUp({ accountId })

    if (!response || response.confirmation.type === 'fail') {
      toast.error('Something went wrong. Please try again later.')

      throw { result: false, ttl: 0 }
    }

    setTopUpOptions({
      id: response.id,
      TTL: getTimeToLeftFromTimestamp(response.confirmation.confirmTo),
    })

    return {
      result: true,
      ttl: getTimeToLeftFromTimestamp(response.confirmation.confirmTo),
    }
  }

  return (
    <>
      <CreateTopUpAccount
        accountUuid={accountUuid}
        setIsShowOtp={setIsShowOtp}
        setTopUpOptions={setTopUpOptions}
        sx={sxTopUpButton}
      />

      <OtpDialog
        show={isShowOtp}
        onClose={() => setIsShowOtp(false)}
        confirmationMethod={confirmationMethod}
        onConfirm={onConfirm}
        resendCodeMethod={accountId ? resendOtp : undefined}
        timer={topUpOptions?.TTL}
      />
    </>
  )
}
