import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Avatar, Link, Skeleton, Stack, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useUserData } from '@entities/user'
import { routePaths } from '@shared/constants'
import { StyledBox } from './styled'

interface IProps {
  userId?: string
}

export function UserButton({ userId }: IProps) {
  const { data: user, isLoading } = useUserData({ userId })

  const userAvatarName = useMemo(() => {
    if (user && user.firstName && user.lastName) {
      return user.firstName?.[0] + user.lastName?.[0]
    }

    return ''
  }, [user])

  return (
    <>
      {!isLoading && (
        <Link
          sx={{
            height: 'min-content',
            color: '#666666',
            textDecoration: 'none',
          }}
          href={`${routePaths.users.list}/${userId}`}
        >
          <StyledBox>
            <Avatar variant={'rounded'} color={'#BDBDBD'}>
              {userAvatarName}
            </Avatar>

            <Stack>
              <Typography fontSize={14}>
                {user?.firstName} {user?.lastName}
              </Typography>

              <Typography fontSize={12} color={'#BDBDBD'}>
                id: {userId}
              </Typography>
            </Stack>

            <OpenInNewIcon sx={{ fontSize: 18 }} />
          </StyledBox>
        </Link>
      )}

      {isLoading && (
        <Skeleton width={160} height={64} sx={{ borderRadius: '4px' }} />
      )}
    </>
  )
}
