import { Toolbar, styled } from '@mui/material'

export const StyledToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== 'second',
})(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    minHeight: 102,
  },
  justifyContent: 'space-between',
  zIndex: 2,
  paddingTop: 20,
  paddingBottom: 20,
}))
