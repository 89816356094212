import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { ExchangesTable } from '@widgets/exchanges-table'

import { useExchangesFilters } from '@features/exchange'

import { useGetAccount } from '@entities/accounts'
import { useGetExchangesList } from '@entities/exchange'
import { useGetWalletsForFilter } from '@entities/wallet'

import { TParamsKeys } from '@shared/constants'
import { useCustomPagination } from '@shared/ui'

export function AccountExchangesTable() {
  const { accountId } = useParams<TParamsKeys>()

  const { currentMethod, currentStatus, currentType, filtersOptions } =
    useExchangesFilters()

  const {
    page,
    rowsPerPage,
    paginationNode,
    setTotalPages,
    setListLength,
    setPage,
  } = useCustomPagination()

  const { data: account, isLoading: isLoadingAccount } = useGetAccount({
    accountId,
  })

  const {
    currentWallet,
    isLoading: isWalletsLoading,
    walletAddressFilterOptions,
    wallets,
  } = useGetWalletsForFilter({
    userId: account?.userId,
    walletType: account?.type,
  })

  const { data: exchanges, isLoading: isLoadingExchanges } =
    useGetExchangesList({
      page,
      perPage: rowsPerPage,
      method: currentMethod,
      type: currentType,
      status: currentStatus,
      wallet: currentWallet.split(' ')[0], // currentWallet = `${wallet.address} (${wallet.blockchain})`,
      userId: account?.userId,
      enabled: !!wallets && !!currentWallet,
    })

  useEffect(() => {
    if (exchanges) {
      setTotalPages(exchanges.totalPages)

      setListLength(exchanges.list.length)
    }
  }, [exchanges?.totalPages])

  useEffect(() => {
    setPage(1)
  }, [currentType, currentStatus, currentMethod])

  return (
    <ExchangesTable
      filtersOptions={filtersOptions}
      isLoading={isWalletsLoading || isLoadingExchanges || isLoadingAccount}
      rowsPerPage={+rowsPerPage}
      currentType={currentType}
      currentStatus={currentStatus}
      currentMethod={currentMethod}
      exchangesList={exchanges?.list}
      paginationNode={paginationNode}
      walletAddressFilterOptions={walletAddressFilterOptions}
    />
  )
}
