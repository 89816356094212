import { styled } from '@mui/material'

export const StickyWrapper = styled('div')(({ theme }) => ({
  position: 'sticky',
  zIndex: 100,
  transition: theme.transitions.create('width'),
  top: 0,
  minHeight: '100vh',
  height: 'min-content',
  boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.08)',
}))
