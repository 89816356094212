import { SxProps, Stack, Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IConfirmCancelExchangeProps, TOperationType } from '../model'
import { ConfirmCancelExchangeModal } from './components'

interface IProps extends IConfirmCancelExchangeProps {
  sx?: SxProps
}

export function ConfirmCancelExchange(props: IProps) {
  const { type, status, sx } = props
  const { t } = useTranslation('common')
  const [operationType, setOperationType] = useState<TOperationType>()

  return (
    <>
      <Stack direction="row" spacing={1} sx={sx}>
        {!(type === 'BUY' && status === 'EXCHANGE_ORDER_DONE') && (
          <>
            <Button
              variant="contained"
              onClick={() => setOperationType('confirm')}
            >
              {t('confirm')}
            </Button>

            <Button
              variant="contained"
              color="error"
              onClick={() => setOperationType('cancel')}
            >
              {t('cancel')}
            </Button>
          </>
        )}
      </Stack>

      <ConfirmCancelExchangeModal
        show={!!operationType}
        operationType={operationType}
        onClose={() => setOperationType(undefined)}
        {...props}
      />
    </>
  )
}
