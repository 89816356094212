import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { WithdrawPage } from '../ui'

export const WithdrawPageRouter: RouteObject = {
  path: routePaths.withdraws.withdraw,
  element: <WithdrawPage />,
}
