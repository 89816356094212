import { useState } from 'react'

import { TUsersStatusFilterType } from '@entities/user'

import { IFilterOptions } from '@shared/ui'

const filterOptions: TUsersStatusFilterType[] = [
  'all',
  'APPROVED',
  'AWAITING',
  'CANCELED',
  'NEW',
  'REJECTED',
  'SENT_CALLBACK',
]

export function useUsersFilters() {
  const [currentStatus, setCurrentStatus] =
    useState<TUsersStatusFilterType>('all')

  const filtersOptions: IFilterOptions[] = [
    {
      options: filterOptions,
      currentOption: currentStatus,
      setCurrentOption: setCurrentStatus,
      filterName: 'status',
    },
  ]

  return { filtersOptions, currentStatus }
}
