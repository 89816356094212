import { Box } from '@mui/material'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { TParamsKeys, expandedParamName, routePaths } from '@shared/constants'
import { CustomTabs, ITab } from '@shared/ui'

import { userLists } from '../../model'

export const UserTabs = memo(function UserTabs() {
  const { t } = useTranslation('pages')
  const { userId } = useParams<TParamsKeys>()

  const mainPath = `${routePaths.users.list}/${userId}`

  const tabs: ITab[] = [
    {
      label: t('user.tabs.applications'),
      value: `${mainPath}/${userLists.applications}`,
    },
    {
      label: t('user.tabs.accounts'),
      value: `${mainPath}/${userLists.accounts}`,
    },
    {
      label: t('user.tabs.top-ups'),
      value: `${mainPath}/${userLists.topUps}`,
    },
    {
      label: t('user.tabs.wallets'),
      value: `${mainPath}/${userLists.wallets}`,
    },
    {
      label: t('user.tabs.withdraws'),
      value: `${mainPath}/${userLists.withdraws}`,
    },
    {
      label: t('user.tabs.exchanges'),
      value: `${mainPath}/${userLists.exchanges}`,
    },
    {
      label: t('user.tabs.transactions'),
      value: `${mainPath}/${userLists.transactions}`,
    },
    {
      label: t('user.tabs.fees'),
      value: `${mainPath}/${userLists.fees}`,
    },
    {
      label: t('user.tabs.statistics'),
      value: `${mainPath}/${userLists.statistic}`,
    },
  ]

  return (
    <Box
      sx={{ borderTop: '1px solid #f1f1f1', borderBottom: '1px solid #f1f1f1' }}
    >
      <CustomTabs tabsRoutes={tabs} searchParamName={expandedParamName} />
    </Box>
  )
})
