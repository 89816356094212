import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { WithdrawsListPage } from '../ui'

export const WithdrawsListPageRouter: RouteObject = {
  path: routePaths.withdraws.list,
  element: <WithdrawsListPage />,
}
