import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material'
import { PropsWithChildren } from 'react'

import { components } from './components'
import { palette } from './palette'
import { typography } from './typography'

const theme = createTheme({
  palette: palette,
  typography: typography,
  components: components,
})

export function ThemeProvider({ children }: PropsWithChildren) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}
