import { ChipOwnProps } from '@mui/material'

import { TExchangeStatus } from '@entities/exchange'

export const getStatusExchangeColor = (
  status: TExchangeStatus
): ChipOwnProps['color'] => {
  switch (status) {
    case 'NEW':
    case 'USER_APPROVED':
    case 'CRYPTO_TX_SEND':
    case 'CRYPTO_CONFIRMED':
    case 'EXCHANGE_TECH_SENT':
      return 'warning'
    case 'EXCHANGE_ORDER_DONE':
    case 'EXCHANGE_TECH_DONE':
    case 'CRYPTO_TX_DONE':
      return 'primary'
    case 'AML_APPROVED':
    case 'TRANSACTION_SENT':
      return 'success'
    default:
      return 'error'
  }
}
