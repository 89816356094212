import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import intervalPlural from 'i18next-intervalplural-postprocessor'

import { initReactI18next } from 'react-i18next'

// eslint-disable-next-line import/no-internal-modules
import en from './translations/en.json'

i18n
  // To define phrases expressing the number of items lies in a range.
  // learn more: https://www.i18next.com/translation-function/plurals
  .use(intervalPlural)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en,
    },
    defaultNS: '',
  })

export default i18n
