import { TextField, styled } from '@mui/material'

export const StyledTextField = styled(TextField)(() => ({
  marginLeft: 8,
  width: 30,
  '& input': {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
  },
}))
