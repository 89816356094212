import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { Box, Button, Link, Modal } from '@mui/material'
import { useState } from 'react'

import { IUploadedDocsLink } from '@shared/types'

interface IProps {
  link: string
  type: IUploadedDocsLink['contentType']
}

export function ShowPdfLinkOrImgWithModal({ link, type }: IProps) {
  const [showImageOnFullScreen, setShowImageOnFullScreen] =
    useState<boolean>(false)

  return (
    <>
      {type === 'application/pdf' ? (
        <Link href={link} target="_blank">
          <PictureAsPdfIcon fontSize="large" />
        </Link>
      ) : (
        <Button
          sx={{ p: 0, minHeight: 20 }}
          onClick={() => setShowImageOnFullScreen(true)}
        >
          <Box component="img" src={link} sx={{ width: 100 }} />
        </Button>
      )}

      <Modal
        open={showImageOnFullScreen}
        onClose={() => setShowImageOnFullScreen(false)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box component="img" src={link} sx={{ width: 800 }} />
      </Modal>
    </>
  )
}
