import { useEffect } from 'react'

import { TransactionsTable } from '@widgets/transaction'

import { useCryptoTransactionsFilters } from '@features/transaction'

import { useGetCryptoTransactions } from '@entities/transaction'

import { useCustomPagination, useFilterByDate } from '@shared/ui'

export function CryptoTransactionsListPage() {
  const { page, rowsPerPage, paginationNode, setTotalPages, setListLength } =
    useCustomPagination()

  const { currentIncomingType, currentStatus, currentType, filtersOptions } =
    useCryptoTransactionsFilters()

  const { dateFilterNode, dateFrom, dateTo, reset } = useFilterByDate()

  const { data: transactions, isLoading } = useGetCryptoTransactions({
    currentIncomingType,
    currentStatus,
    currentType,
    dateFrom,
    dateTo,
    page,
    rowsPerPage,
  })

  useEffect(() => {
    if (transactions) {
      setTotalPages(transactions.totalPages)

      setListLength(transactions.list.length)
    }
  }, [transactions?.totalPages])

  return (
    <TransactionsTable
      isLoading={isLoading}
      rowsPerPage={+rowsPerPage}
      paginationNode={paginationNode}
      transactions={transactions?.list}
      filtersOptions={filtersOptions}
      dateFilter={dateFilterNode}
      resetDateFilter={reset}
      type="crypto"
    />
  )
}
