import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Autocomplete, Box, Paper, TextField } from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'

import { countries } from '@shared/constants'

import { IInputProps, TCountry } from '@shared/types'

interface IProps extends IInputProps<unknown[]> {
  onChange?: (...event: unknown[]) => void
  variant?: 'filled' | 'outlined' | 'standard'
  listMode?: 'countries' | 'countryCodes'
}

export const CountrySelectInput = forwardRef<HTMLInputElement, IProps>(
  function CountrySelectInput(props: IProps, ref) {
    const {
      placeholder,
      error,
      helperText,
      label,
      onChange,
      onBlur,
      sx,
      value,
      variant,
      name,
      disabled,
      fullWidth,
      listMode,
    } = props

    const [autocompleteValue, setAutocompleteValue] = useState<TCountry | null>(
      countries.find((item) => item.abbreviation === value) || null
    )

    useEffect(() => {
      setAutocompleteValue(
        countries.find((item) => item.abbreviation === value) || null
      )
    }, [value])

    return (
      <Box sx={sx}>
        <Autocomplete
          disabled={disabled}
          fullWidth={fullWidth}
          value={autocompleteValue}
          defaultValue={null}
          clearOnBlur
          disablePortal
          options={countries}
          getOptionLabel={(option) =>
            !!listMode && listMode === 'countryCodes'
              ? option.abbreviation
              : option.name
          }
          popupIcon={!disabled && <ExpandMoreIcon />}
          PaperComponent={({ children }) => <Paper>{children}</Paper>}
          onChange={(_, option) => onChange && onChange(option?.abbreviation)}
          onBlur={onBlur}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={ref}
              variant={variant}
              fullWidth={fullWidth}
              label={label}
              placeholder={placeholder}
              error={error}
              name={name}
              helperText={helperText || ''}
              InputProps={params.InputProps}
            />
          )}
        />
      </Box>
    )
  }
)
