import { styled } from '@mui/material'

export const SidebarInfoIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  '& svg': {
    width: 20,
    height: 20,
  },
}))
