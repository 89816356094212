import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Stack,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { TopUpAccountWithOtp } from '@features/top-up-account'

import { IAccount, TAccountTypeFilterType } from '@entities/accounts'

import { routePaths } from '@shared/constants'
import { ITableHeadCell } from '@shared/types'
import {
  DateWithTodayYesterdayBadge,
  FiltersMenu,
  ListLoader,
  ShowEuro,
  StyledTableHeadCell,
  // StyledTableLink,
  StyledTableRow,
  StyledToolbar,
  IFilterOptions,
  CopyButton,
} from '@shared/ui'

interface IProps {
  refetchList?: () => void
  isLoading: boolean
  currentStatus: TAccountTypeFilterType
  accountsList?: IAccount[]
  filterMenuOptions: IFilterOptions[]
  rowsPerPage: number
  paginationNode: JSX.Element
  isOwnPage?: boolean
}

type TTableColumnName =
  | 'id'
  | 'full name'
  | 'type'
  | 'balance'
  | 'created at'
  | 'reference'

export function AccountsTable({
  currentStatus,
  filterMenuOptions,
  isLoading,
  paginationNode,
  rowsPerPage,
  isOwnPage = true,
  refetchList,
  accountsList,
}: IProps) {
  const { t } = useTranslation('widgets')
  const navigate = useNavigate()

  const tableColumnsNames: ITableHeadCell<TTableColumnName>[] = [
    {
      cellName: 'id',
      text: t('accounts-table.id'),
    },
    {
      cellName: 'full name',
      text: t('accounts-table.full name'),
    },
    {
      cellName: 'reference',
      text: t('accounts-table.reference'),
    },
    {
      cellName: 'type',
      text: t('accounts-table.type'),
    },
    {
      cellName: 'balance',
      text: t('accounts-table.balance'),
    },
    {
      cellName: 'created at',
      text: t('accounts-table.created at'),
    },
  ]

  return (
    <>
      <StyledToolbar variant="dense">
        <Typography>{t('accounts-table.title')}</Typography>

        {isOwnPage && refetchList && (
          <TopUpAccountWithOtp
            refetch={refetchList}
            sxTopUpButton={{ width: 400 }}
          />
        )}

        <FiltersMenu filtersOptions={filterMenuOptions} />
      </StyledToolbar>

      {isLoading && <ListLoader skeletonsNumber={+rowsPerPage} />}

      {!isLoading && (
        <>
          <TableContainer sx={{ mb: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {tableColumnsNames.map((columnName) =>
                    !isOwnPage &&
                    columnName.cellName === 'reference' ? undefined : (
                      <StyledTableHeadCell key={columnName.cellName}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography fontSize="inherit" fontWeight="inherit">
                            {columnName.text}

                            {columnName.cellName === 'type' &&
                              currentStatus !== 'all' &&
                              ` (${currentStatus})`}
                          </Typography>
                        </Stack>
                      </StyledTableHeadCell>
                    )
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {accountsList?.map((account) => (
                  <StyledTableRow
                    hover
                    tabIndex={-1}
                    key={account.id}
                    onClick={() =>
                      navigate(`${routePaths.accounts.list}/${account.id}`)
                    }
                  >
                    <TableCell>{account.id}</TableCell>

                    <TableCell>
                      {account.type === 'corporate'
                        ? account.corporateName
                        : `${account.firstName} ${account.lastName}`}
                    </TableCell>

                    {isOwnPage && (
                      <TableCell>
                        <Stack direction="row" alignItems="center" gap={1}>
                          <CopyButton
                            value={account.account}
                            sx={{ position: 'relative', zIndex: 1 }}
                          />

                          {account.account}
                        </Stack>
                      </TableCell>
                    )}

                    <TableCell>{account.type}</TableCell>

                    <TableCell>
                      <ShowEuro cents={account.balance} />
                    </TableCell>

                    <TableCell>
                      <DateWithTodayYesterdayBadge date={account.createdAt} />
                      {/* <StyledTableLink
                        href={`${routePaths.accounts.list}/${account.id}`}
                      /> */}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {paginationNode}
    </>
  )
}
