import { instance } from '@shared/requester'
import { TAccountType } from '@shared/types'

import { TStatusApplication } from '../types'

interface IRequestParams {
  applicationId?: string
  type: TAccountType
  status: TStatusApplication
}

export async function handleApprovalOfApplicationStatus({
  applicationId,
  type,
  status,
}: IRequestParams) {
  if (!applicationId) {
    throw new Error()
  }

  return instance
    .patch(`/applications/${applicationId}/${type}`, { status })
    .then((response) => response.data)
}
