import { useQuery } from '@tanstack/react-query'

import { TUsersStatusFilterType } from '@entities/user'
import { getAllUsers } from '../../api'

interface IProps {
  page: number
  rowsPerPage: string
  currentStatus: TUsersStatusFilterType
}

export function useUsersData({ page, rowsPerPage, currentStatus }: IProps) {
  return useQuery({
    queryKey: ['get-users-list', page, rowsPerPage, currentStatus],
    queryFn: () =>
      getAllUsers({
        page,
        perPage: rowsPerPage,
        statusOndato: currentStatus !== 'all' ? currentStatus : undefined,
      }),
    refetchInterval: 30 * 1000,
  })
}
