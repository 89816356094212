import EastRoundedIcon from '@mui/icons-material/EastRounded'
import { Typography, Stack, TableCell, Chip } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { IExchange, getStatusExchangeColor } from '@entities/exchange'

import { routePaths } from '@shared/constants'
import {
  DateWithTodayYesterdayBadge,
  // StyledTableLink,
  StyledTableRow,
} from '@shared/ui'

interface IProps
  extends Omit<
    IExchange,
    | 'cryptoId'
    | 'rateFinal'
    | 'updatedAt'
    | 'wallet'
    | 'uuid'
    | 'rate'
    | 'feeCurrency'
    | 'feeAmount'
    | 'userId'
  > {
  walletName: string
}

export function ExchangesListItem({
  sellingAmount,
  sellingCurrency,
  buyingAmount,
  buyingAmountFinal,
  buyingAmountSend,
  buyingCurrency,
  type,
  method,
  createdAt,
  outputId,
  status,
  id,
  walletName,
}: IProps) {
  const navigate = useNavigate()

  return (
    <StyledTableRow
      hover
      tabIndex={-1}
      onClick={() => navigate(`${routePaths.exchanges.list}/${id}`)}
    >
      <TableCell>{outputId}</TableCell>
      <TableCell>{walletName}</TableCell>

      <TableCell>
        <Stack direction="row" spacing={1}>
          <Typography>{`${sellingAmount} ${sellingCurrency}`}</Typography>
          <EastRoundedIcon />

          <Typography>
            {`${buyingAmountSend || buyingAmountFinal || buyingAmount} ${buyingCurrency}`}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>{type}</TableCell>
      <TableCell>{method}</TableCell>

      <TableCell>
        <Chip
          size="small"
          label={status}
          color={getStatusExchangeColor(status)}
          sx={{ minWidth: 200 }}
        />
      </TableCell>

      <TableCell>
        <Stack direction="row" spacing={1}>
          <DateWithTodayYesterdayBadge date={createdAt} />
          {/* <StyledTableLink href={`${routePaths.exchanges.list}/${id}`} /> */}
        </Stack>
      </TableCell>
    </StyledTableRow>
  )
}
