import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { UsersListPage } from '../ui'

export const UsersListRouter: RouteObject = {
  path: routePaths.users.list,
  element: <UsersListPage />,
}
