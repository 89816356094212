import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { AccountsTable } from '@widgets/account'

import { useAccountsFilters } from '@features/account'

import { getAccountsList } from '@entities/accounts'

import { TParamsKeys } from '@shared/constants'
import { useCustomPagination } from '@shared/ui'

export function UserAccountsTable() {
  const { userId } = useParams<TParamsKeys>()
  const { currentType, filtersOptions } = useAccountsFilters()

  const {
    page,
    rowsPerPage,
    paginationNode,
    setTotalPages,
    setListLength,
    setPage,
  } = useCustomPagination()

  const {
    data: accounts,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['get-user-accounts', userId, page, rowsPerPage, currentType],
    queryFn: () =>
      getAccountsList({
        page,
        perPage: rowsPerPage,
        userId,
        type: currentType !== 'all' ? currentType : undefined,
      }),
    refetchInterval: 90 * 1000,
  })

  useEffect(() => {
    if (accounts) {
      setTotalPages(accounts.totalPages)

      setListLength(accounts.list.length)
    }
  }, [accounts?.totalPages])

  useEffect(() => {
    setPage(1)
  }, [currentType])

  return (
    <AccountsTable
      isLoading={isLoading}
      currentStatus={currentType}
      filterMenuOptions={filtersOptions}
      rowsPerPage={+rowsPerPage}
      paginationNode={paginationNode}
      refetchList={refetch}
      accountsList={accounts?.list}
      isOwnPage={false}
    />
  )
}
