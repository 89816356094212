import { instance } from '@shared/requester'
import { IBeneficiary } from '@shared/types'

interface IRequestParams {
  applicationId?: string
  data: IBeneficiary[]
}

interface IResponse {
  result: boolean
}

export async function updateBeneficiaries({
  applicationId,
  data,
}: IRequestParams) {
  if (!applicationId) {
    throw new Error()
  }

  return instance
    .post<IResponse>('/applications/change-data', {
      requestId: applicationId,
      data,
    })
    .then((response) => response.data)
}
