import { IWallet } from '@shared/types'

export interface IExchange {
  id: string
  uuid: string
  cryptoId: number | null
  status: TExchangeStatus
  type: 'BUY' | 'SELL'
  sellingAmount: string
  sellingCurrency: string
  buyingAmountSend: string | null
  buyingAmountFinal: string | null
  buyingAmount: string
  buyingCurrency: string
  wallet: Omit<IWallet, 'balances'>
  rate: string
  createdAt: string // Date as string ISO
  updatedAt: string // Date as string ISO
  method: 'AUTO' | 'KRAKEN'
  rateFinal: string | null
  userId: string
  feeAmount: string | null
  feeCurrency: string | null
  outputId: string

  sendToKraken: string
  getFromKraken: string
  tariffInfo: {
    name: string
    steps: ITariffInfoStep[]
    selectedStep: number
  } | null
  ip: string
}

interface ITariffInfoStep {
  min: number
  max: number
  abs: number
  per: number
}

export const exchangeStatuses = [
  'AML_APPROVED',
  'AML_REJECTED',
  'CANCELED',
  'CRYPTO_CONFIRMED',
  'CRYPTO_REJECTED',
  'CRYPTO_TX_DONE',
  'CRYPTO_TX_SEND',
  'EXCHANGE_COMPLETE',
  'EXCHANGE_NOT_POSSIBLE',
  'EXCHANGE_ORDER_DONE',
  'EXCHANGE_ORDER_FAIL',
  'EXCHANGE_ORDER_SENT',
  'EXCHANGE_TECH_DONE',
  'EXCHANGE_TECH_FAIL',
  'EXCHANGE_TECH_SENT',
  'EXCHANGE_USER_FAIL',
  'EXCHANGE_USER_SENT',
  'NEW',
  'TRANSACTION_SENT',
  'USER_REJECTED',
  'USER_APPROVED',
] as const

export type TExchangeStatus = (typeof exchangeStatuses)[number]
