import {
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { TopUpAccountWithOtp } from '@features/top-up-account'

import { TTopUpsStatusFilterType, getTopUpStatusColor } from '@entities/top-up'

import { routePaths } from '@shared/constants'
import { ITableHeadCell, ITopUp } from '@shared/types'
import {
  DateWithTodayYesterdayBadge,
  FiltersMenu,
  ListLoader,
  ShowEuro,
  StyledTableHeadCell,
  // StyledTableLink,
  StyledTableRow,
  StyledToolbar,
  IFilterOptions,
} from '@shared/ui'

type TTableColumnName = 'id' | 'full name' | 'amount' | 'status' | 'created at'

interface IProps {
  refetchList?: () => void
  isLoading: boolean
  currentStatus: TTopUpsStatusFilterType
  topUpsList?: ITopUp[]
  filterMenuOptions: IFilterOptions[]
  rowsPerPage: number
  paginationNode: JSX.Element
  isOwnPage?: boolean
}

export function TopUpsTable({
  currentStatus,
  isLoading,
  refetchList,
  topUpsList,
  filterMenuOptions,
  rowsPerPage,
  paginationNode,
  isOwnPage = true,
}: IProps) {
  const { t } = useTranslation('widgets')
  const navigate = useNavigate()

  const tableColumnsNames: ITableHeadCell<TTableColumnName>[] = [
    {
      cellName: 'id',
      text: t('top-ups-table.id'),
    },
    {
      cellName: 'full name',
      text: t('top-ups-table.full name'),
    },
    {
      cellName: 'amount',
      text: t('top-ups-table.amount'),
    },
    {
      cellName: 'status',
      text: t('top-ups-table.status'),
    },
    {
      cellName: 'created at',
      text: t('top-ups-table.created at'),
    },
  ]

  return (
    <>
      <StyledToolbar variant="dense">
        <Typography>{t('top-ups-table.title')}</Typography>

        {isOwnPage && refetchList && (
          <TopUpAccountWithOtp
            refetch={refetchList}
            sxTopUpButton={{ width: 400, mx: 'auto' }}
          />
        )}

        <FiltersMenu filtersOptions={filterMenuOptions} />
      </StyledToolbar>

      {isLoading && <ListLoader skeletonsNumber={rowsPerPage} />}

      {!isLoading && (
        <>
          <TableContainer sx={{ mb: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {tableColumnsNames.map((columnName) =>
                    !isOwnPage &&
                    columnName.cellName === 'full name' ? undefined : (
                      <StyledTableHeadCell key={columnName.cellName}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography fontSize="inherit" fontWeight="inherit">
                            {columnName.text}

                            {columnName.cellName === 'status' &&
                              currentStatus !== 'all' &&
                              ` (${currentStatus})`}
                          </Typography>
                        </Stack>
                      </StyledTableHeadCell>
                    )
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {topUpsList?.map((topUp) => (
                  <StyledTableRow
                    hover
                    tabIndex={-1}
                    key={topUp.id}
                    onClick={() =>
                      navigate(`${routePaths.topUps.list}/${topUp.id}`)
                    }
                  >
                    <TableCell>{topUp.id}</TableCell>
                    {isOwnPage && <TableCell>{topUp.displayName}</TableCell>}

                    <TableCell>
                      <ShowEuro cents={topUp.amount} />
                    </TableCell>

                    <TableCell>
                      <Chip
                        size="small"
                        label={topUp.status}
                        color={getTopUpStatusColor(topUp.status)}
                        sx={{ minWidth: 80 }}
                      />
                    </TableCell>

                    <TableCell>
                      <DateWithTodayYesterdayBadge date={topUp.createdAt} />
                      {/* <StyledTableLink
                        href={`${routePaths.topUps.list}/${topUp.id}`}
                      /> */}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {paginationNode}
    </>
  )
}
