import { IExchange } from '@entities/exchange'

import { instance } from '@shared/requester'

interface IMainProps {
  id: string
}

interface IBuySellProps extends IMainProps {
  type: IExchange['type']
  crypto?: false
  amount: number
}

interface ISellCryptoProps extends IMainProps {
  type: Extract<IExchange['type'], 'SELL'>
  crypto: true
  amount?: false
}

type TProps = IBuySellProps | ISellCryptoProps

export async function confirmExchange({ amount, id, type, crypto }: TProps) {
  const cryptoParam = crypto ? '/crypto' : ''

  return instance
    .patch(
      `/exchanges/${id}/${type.toLowerCase()}/accept${cryptoParam}`,
      !crypto && { amount }
    )
    .then((response) => response.data)
}
