import { TableCell, Table, TableBody, TableContainer } from '@mui/material'

import { MapObjectValue } from '@entities/application'

import { ILegalDocumentsImages } from '@shared/types'
import { StyledTableRow } from '@shared/ui'

export function LegalDocumentsImages({ type, images }: ILegalDocumentsImages) {
  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          <StyledTableRow hover tabIndex={-1}>
            <TableCell>{type}</TableCell>

            <TableCell>
              <MapObjectValue object={images} type="corporate" />
            </TableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
