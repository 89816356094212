import { instance } from '@shared/requester'
import { IGetListResponse, IMainApplicationInfo } from '@shared/types'

interface IRequestParams {
  page: number
  perPage: string
  userId?: string
  status?: IMainApplicationInfo['status']
}

export async function getAllApplications({
  page,
  perPage,
  status,
  userId,
}: IRequestParams) {
  const userIdParam = userId ? `&user-id=${userId}` : ''
  const statusParam = status ? `&status=${status}` : ''

  return instance
    .get<
      IGetListResponse<IMainApplicationInfo>
    >(`/applications/?cur-page=${page}&per-page=${perPage}${userIdParam}${statusParam}`)
    .then((response) => response.data)
}
