import { toast } from 'react-toastify'

import { instance } from '@shared/requester'
import { TWithdrawStatus } from '@shared/types'

interface IRequest {
  withdrawId?: string
}

interface IResponse {
  id: string
  status: TWithdrawStatus
}

export async function cancelWithdraw({ withdrawId }: IRequest) {
  if (!withdrawId) {
    toast.error('Something went wrong!')

    return
  }

  return instance
    .patch<IResponse>(`accounts/withdraw/${withdrawId}/cancel`)
    .then((response) => response.data)
}
