import { FormControlLabel, Checkbox } from '@mui/material'
import { Dispatch, SetStateAction, ChangeEvent, memo } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  setExcludeScam: Dispatch<SetStateAction<boolean>>
  excludeScam: boolean
}

export const ExcludeScam = memo(function ExcludeScam({
  excludeScam,
  setExcludeScam,
}: IProps) {
  const { t } = useTranslation('features')

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setExcludeScam(event.target.checked)
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={excludeScam}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      }
      label={t('statistics.exclude-scam-checkbox-label')}
    />
  )
})
