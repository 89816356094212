import { MuiOtpInput } from 'mui-one-time-password-input'
import { useEffect, useRef } from 'react'

import { sxForInputs } from '../styled'

interface IProps {
  onChange?: (value: string) => void
  onBlur?: (value: string, isCompleted: boolean) => void
  validateChar?: (value: string, index: number) => boolean
  value: string
  error?: boolean
  length?: number
  disabled?: boolean
}

export function OtpInput({
  onChange,
  onBlur,
  validateChar,
  value,
  length,
  error = false,
  disabled,
}: IProps) {
  const ref = useRef<HTMLElement>()

  const updateBackgroundColor = () => {
    if (!ref.current) {
      return
    }

    const inputs = ref.current.querySelectorAll('input')

    inputs.forEach((input) => {
      if (input.value) {
        input.classList.add('not-empty')
      } else {
        input.classList.remove('not-empty')
      }
    })
  }

  useEffect(() => {
    updateBackgroundColor()
  }, [ref.current, value])

  return (
    <MuiOtpInput
      autoFocus
      ref={ref}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      validateChar={validateChar}
      TextFieldsProps={{
        disabled,
        InputProps: {
          error,
          sx: sxForInputs,
          type: 'tel',
        },
      }}
      sx={{ gap: '12px', mt: { xs: '32px', md: 5 } }}
      length={length}
    />
  )
}
