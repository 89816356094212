import { SxProps } from '@mui/material'
import { format } from 'date-fns'
import { useState } from 'react'

import { DateFilterPicker } from '../ui'

export function useFilterByDate(pattern = 'yyyy-MM-dd', sx?: SxProps) {
  const [dateTo, setDateTo] = useState<Date | undefined>(new Date())
  const [dateFrom, setDateFrom] = useState<Date | null>()

  const dateFilterNode = (
    <DateFilterPicker
      to={dateTo}
      from={dateFrom}
      setTo={setDateTo}
      setFrom={setDateFrom}
      sx={sx}
    />
  )

  const reset = () => {
    setDateTo(new Date())

    setDateFrom(null)
  }

  return {
    dateFilterNode,
    dateTo: dateTo && format(dateTo, pattern),
    dateFrom: dateFrom && format(dateFrom, pattern),
    reset,
  }
}
