import { useQuery } from '@tanstack/react-query'

import { getUserListForFilter } from '../../api'

export function useUsersForFilter() {
  return useQuery({
    queryKey: ['user-list-for-filter'],
    queryFn: getUserListForFilter,
  })
}
