import { Stack } from '@mui/material'

import { IUploadedDocsLink } from '@shared/types'

import { ShowPdfLinkOrImgWithModal } from './components'

interface IProps {
  docs: IUploadedDocsLink[]
}

export function ImgListWithModal({ docs }: IProps) {
  return (
    <Stack direction="row" spacing={1}>
      {docs.map((item, index) => (
        <ShowPdfLinkOrImgWithModal
          link={item.s3Link}
          key={index}
          type={item.contentType}
        />
      ))}
    </Stack>
  )
}
