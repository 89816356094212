import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  SxProps,
  Typography,
} from '@mui/material'
import { IExchange } from '@entities/exchange'

interface IProps {
  tariffInfo?: IExchange['tariffInfo']
}

export function ExchangeTariffInfo({ tariffInfo }: IProps) {
  if (!tariffInfo) {
    return
  }

  const getSelectedStepSx = (index: number): SxProps => {
    return {
      ...(index === tariffInfo.selectedStep && {
        fontWeight: 'bold',
      }),
    }
  }

  return (
    <Box pt={2} ml={'auto'}>
      <Accordion sx={{ m: 0, width: 'fit-content' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h5">Tariff: {tariffInfo.name}</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Stack>
            {tariffInfo.steps.map((rule, index) => (
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                key={rule.min}
                gap={6}
              >
                <Typography
                  variant={'body2'}
                  component={'span'}
                  sx={getSelectedStepSx(index)}
                >
                  {rule.min}
                  {!!rule.max && <> - {rule.max}</>}:
                </Typography>

                <Typography
                  variant={'body2'}
                  component={'span'}
                  sx={getSelectedStepSx(index)}
                >
                  {rule.per}% {rule.abs > 0 ? `(min. ${rule.abs} EUR)` : ''}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
