import CloseIcon from '@mui/icons-material/Close'
import { IconButton, SxProps } from '@mui/material'

interface IProps {
  onClose?: () => void
  sx?: SxProps
}

export function CloseButton({ onClose, sx }: IProps) {
  const clickHandler = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <IconButton data-testid="close-button" sx={sx} onClick={clickHandler}>
      <CloseIcon sx={{ fill: '#22262B' }} />
    </IconButton>
  )
}
