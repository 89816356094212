import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
} from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'

import {
  IConfirmCancelExchangeModal,
  useConfirmCancelExchange,
} from '../../model'

type TProps = Omit<
  IConfirmCancelExchangeModal,
  | 'buyingAmountFinal'
  | 'buyingCurrency'
  | 'rateFinal'
  | 'sellingAmount'
  | 'sellingCurrency'
  | 'operationType'
>

export function ConfirmExchangeModal({
  show,
  id,
  refetchExchanges,
  onClose,
  status,
  type,
}: TProps) {
  const { t } = useTranslation(['features', 'common'])

  const { isLoading, handleUltimateConfirmExchange } = useConfirmCancelExchange(
    {
      id,
      refetchExchanges,
      status,
      type,
      onClose,
    }
  )

  return (
    <Dialog
      open={show}
      onClose={!isLoading ? onClose : undefined}
      aria-labelledby="alert-dialog-title"
      sx={{ '& .MuiPaper-root': { pb: 2 } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          component="span"
          variant="h5"
          whiteSpace="pre-wrap"
          textAlign="center"
          display="block"
        >
          <Trans
            ns="features"
            i18nKey="exchange.confirm-modal.title-confirm"
            values={{ uuid: id }}
          />
        </Typography>

        <Typography variant="h4" color="error" textAlign="center">
          {t('exchange.confirm-modal.confirm-warning-message')}
        </Typography>
      </DialogTitle>

      <DialogActions sx={{ px: 3, justifyContent: 'center' }}>
        <Button
          onClick={onClose}
          variant="outlined"
          fullWidth
          disabled={isLoading}
        >
          {t('common:close')}
        </Button>

        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={handleUltimateConfirmExchange}
          fullWidth
          color={'primary'}
        >
          {t('common:confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
