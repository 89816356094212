import { ArrowBack } from '@mui/icons-material'
import { SxProps, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

interface IProps {
  sx?: SxProps
}

export function BackButton({ sx }: IProps) {
  const location = useLocation()
  const { t } = useTranslation('features')

  const handleGoToFirstLevel = () => {
    const pathParts = location.pathname.split('/')
    const firstLevelPath = `/${pathParts[1]}`

    return firstLevelPath
  }

  return (
    <Button
      href={handleGoToFirstLevel()}
      startIcon={<ArrowBack />}
      variant="contained"
      sx={{ color: '#fff', ...sx }}
    >
      {t('buttons.back')}
    </Button>
  )
}
