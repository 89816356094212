import { IStep } from '../types'

export const buyKraken: IStep[] = [
  {
    defaultTitle: 'Created',
    conditionalStatuses: [
      {
        key: 'NEW',
        title: 'New',
        additionalText: 'User created a request',
      },
      {
        key: 'CANCELED',
        title: 'Canceled',
        additionalText: 'Exchange request is exists',
      },
    ],
  },
  {
    defaultTitle: 'User approved',
    conditionalStatuses: [
      {
        key: 'USER_APPROVED',
        title: 'User approved',
        additionalText: 'Waiting for order creation KRAKEN',
        loading: true,
      },
      {
        key: 'USER_REJECTED',
        title: 'User rejected',
        additionalText: 'Rejected by user',
      },
    ],
  },
  {
    defaultTitle: 'KRAKEN order creation',
    conditionalStatuses: [
      {
        key: 'EXCHANGE_ORDER_DONE',
        title: 'KRAKEN order creation',
        additionalText: 'Waiting for KRAKEN order creation',
        loading: true,
      },
      {
        key: 'EXCHANGE_ORDER_FAIL',
        title: 'KRAKEN order creation error',
        additionalText: 'Error during KRAKEN order creation',
      },
    ],
  },
  {
    defaultTitle: 'Send to central wallet',
    conditionalStatuses: [
      {
        key: 'EXCHANGE_TECH_SENT',
        title: 'Send to central wallet',
        loading: true,
      },
    ],
  },
  {
    defaultTitle: 'Check central wallet',
    conditionalStatuses: [
      {
        key: 'EXCHANGE_TECH_DONE',
        title: 'Check central wallet - success',
        additionalText: 'Confirmation required',
      },
      {
        key: 'EXCHANGE_TECH_FAIL',
        title: 'Check central wallet - failed',
      },
    ],
  },
  {
    defaultTitle: 'AML-officer confirmation',
    optional: true,
    conditionalStatuses: [
      {
        key: 'AML_REJECTED',
        title: 'Rejected by AML-officer',
      },
      {
        key: 'AML_APPROVED',
        title: 'Approved by AML-officer',
      },
    ],
  },
  {
    defaultTitle: 'Transaction sent',
    conditionalStatuses: [
      {
        key: 'TRANSACTION_SENT',
        title: 'Transaction sent',
      },
    ],
  },
]
