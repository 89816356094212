import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IUserForFilter, useUsersForFilter } from '@entities/statistics'

interface IProps {
  setSelectedUsersIds: (payload: string[]) => void
}

export function UsersAutocomplete({ setSelectedUsersIds }: IProps) {
  const [selectedUsers, setSelectedUsers] = useState<IUserForFilter[]>([])

  const { t } = useTranslation('features')

  const handleChange = () => {
    const selectedUserIds = selectedUsers.map((user) => user.id)

    setSelectedUsersIds(selectedUserIds)
  }

  useEffect(() => {
    handleChange()
  }, [selectedUsers])

  const { data, isLoading } = useUsersForFilter()

  return (
    <Autocomplete
      multiple
      id="users-checkboxes-tags"
      options={data || []}
      value={selectedUsers}
      onChange={(_, value) => setSelectedUsers(value)}
      disableCloseOnSelect
      getOptionLabel={(option) => `${option.name} (id: ${option.id})`}
      loading={isLoading}
      renderOption={(props, option, { selected }) => {
        return (
          <li key={option.id} {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />

            {`${option.name} (id: ${option.id})`}
          </li>
        )
      }}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('statistics.users-autocomplete-label')}
          placeholder={t('statistics.users-autocomplete-label')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}

                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
