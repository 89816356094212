import { instance } from '@shared/requester'
import { IBaseConfirmationResponse } from '@shared/types'

interface IRequestParams {
  accountUuid?: string
  amount: number // required, number, max decimal places: 2, positive
}

interface IResponse {
  id: string
  confirmation: IBaseConfirmationResponse
}

export async function createTopUp({ accountUuid, amount }: IRequestParams) {
  if (!accountUuid || !amount) {
    throw new Error()
  }

  return instance
    .post<IResponse>('/accounts/topup', {
      amount,
      account: accountUuid,
    })
    .then((response) => response.data)
}
