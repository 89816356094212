import { SxProps, Link } from '@mui/material'

import { routePaths } from '@shared/constants'

interface IProps {
  userId: string
  userName?: string
  sx?: SxProps
}

export function GoToUserPageLink({ userId, userName, sx }: IProps) {
  return (
    <Link href={`${routePaths.users.list}/${userId}`} sx={{ ...sx }}>
      {userName}
    </Link>
  )
}
