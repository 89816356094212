import { styled } from '@mui/material'

export const LoadingIconWrapper = styled('div')(() => ({
  position: 'relative',
  display: 'inline-flex',
  height: 24,
  width: 24,
  justifyContent: 'center',
  alignItems: 'center',
}))
