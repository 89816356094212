import { instance } from '@shared/requester'
import { IWithdraw } from '@shared/types'

export async function getWithdraw(withdrawId?: string) {
  if (!withdrawId) {
    throw new Error()
  }

  return instance
    .get<IWithdraw>(`/accounts/withdraw/${withdrawId}`)
    .then((response) => response.data)
}
