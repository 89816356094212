import { StepLabelProps, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IExchange, TExchangeStatus } from '@entities/exchange'
import { getLoadingStepIcon, WaringStepIcon } from '../custom-step-icons'
import { getFlowSteps, getStatusLabelColor } from '../utils'

interface IProps {
  exchange?: IExchange
}

export function useStepsByMethodType({ exchange }: IProps) {
  const { t } = useTranslation('widgets')

  const generateLabelProps = (
    status: TExchangeStatus,
    type: IExchange['type'],
    isLoading?: boolean,
    additionalText?: string
  ) => {
    const labelProps: Pick<
      StepLabelProps,
      'sx' | 'optional' | 'StepIconComponent' | 'StepIconProps'
    > = {}

    const statusLabel = getStatusLabelColor(status, type)
    const isWarning = statusLabel === 'warning'
    const isError = statusLabel === 'error'

    labelProps.optional = additionalText && (
      <Typography
        variant="caption"
        sx={{
          color: isError
            ? 'error.main'
            : isWarning
              ? 'warning.light'
              : 'text.primary',
        }}
      >
        {additionalText}
      </Typography>
    )

    labelProps.sx = {
      '& .MuiStepLabel-label': {
        fontWeight: 500,
        color: 'primary.main',
        lineHeight: '1.2',
      },
    }

    if (isError || isWarning) {
      labelProps.sx = {
        ...labelProps.sx,
        '& .MuiStepLabel-label': {
          color: isError ? 'error.main' : 'warning.main',
        },
        '& .Mui-active svg, & svg': {
          color: isError ? 'error.main' : 'warning.light',
        },
      }

      if (isWarning) {
        labelProps.StepIconComponent = WaringStepIcon
      }

      return labelProps
    }

    if (isLoading) {
      labelProps.StepIconComponent = getLoadingStepIcon(
        activeStep.noOptional + 1
      )
    }

    return labelProps
  }

  const flowSteps = useMemo(() => {
    if (!exchange) {
      return []
    }

    return getFlowSteps(exchange.type, exchange.method)
  }, [exchange])

  const activeStep = useMemo(() => {
    let countBeforeOptionalSteps = 0

    const activeStepWithOptional = flowSteps.findIndex((step) => {
      const founded =
        step?.conditionalStatuses?.findIndex(
          (status) => status.key === exchange?.status
        ) >= 0

      if (step.optional && !founded) {
        countBeforeOptionalSteps++
      }

      return founded
    })

    return {
      withOptional: activeStepWithOptional,
      noOptional: activeStepWithOptional - countBeforeOptionalSteps,
    }
  }, [exchange, flowSteps])

  const activeStepIsLoading = useMemo(() => {
    if (!exchange?.status) {
      return false
    }

    const result = flowSteps[
      activeStep.withOptional
    ]?.conditionalStatuses?.find(
      (status) => status.key === exchange.status
    )?.loading

    return result
  }, [exchange?.status])

  const activeStepMessage = useMemo(() => {
    if (!exchange?.status) {
      return ''
    }

    const tKey = flowSteps[activeStep.withOptional]?.conditionalStatuses?.find(
      (status) => status.key === exchange.status
    )?.title

    return tKey ? t(`exchange-stepper.steps-label-messages.${tKey}`) : ''
  }, [exchange?.status, activeStep])

  const activeStepAdditionalMessage = useMemo(() => {
    if (!exchange?.status) {
      return ''
    }

    const tKey = flowSteps[activeStep.withOptional]?.conditionalStatuses?.find(
      (status) => status.key === exchange.status
    )?.additionalText

    return tKey ? t(`exchange-stepper.steps-label-messages.${tKey}`) : ''
  }, [exchange?.status])

  const activeStepStatusColor = useMemo(() => {
    if (!exchange?.status || !exchange?.type) {
      return
    }

    return getStatusLabelColor(exchange.status, exchange.type)
  }, [exchange?.status])

  const activeStepStatusValue = useMemo(() => {
    if (activeStep.withOptional === flowSteps.length - 1) {
      if (activeStepStatusColor === 'error') {
        return t('exchange-stepper.steps-label-messages.Rejected')
      }

      return t('exchange-stepper.steps-label-messages.Done')
    }

    if (activeStepStatusColor === 'error') {
      if (exchange?.status === 'USER_REJECTED') {
        return t('exchange-stepper.steps-label-messages.Rejected by user')
      }

      if (exchange?.status === 'AML_REJECTED') {
        return t(
          'exchange-stepper.steps-label-messages.Rejected by AML-officer'
        )
      }

      return t('exchange-stepper.steps-label-messages.Error')
    }

    return t('exchange-stepper.steps-label-messages.Processing in progress')
  }, [exchange?.status])

  return {
    flowSteps,
    activeStep,
    activeStepMessage,
    activeStepAdditionalMessage,
    activeStepStatusValue,
    activeStepStatusColor,
    activeStepIsLoading,
    generateLabelProps,
  }
}
