import { SxProps } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IExchange } from '@entities/exchange'
import { useExchangeDetails } from '../model'
import { ExchangeCardInfo, IExchangeCardInfoData } from './components'
import { ExchangeTariffInfo } from './exchange-tariff-info'

interface IProps {
  exchange?: IExchange
  sx?: SxProps
}

export function ExchangeFeeInfo({ exchange, sx }: IProps) {
  const { t } = useTranslation('widgets')
  const { transformKeyToDataRow } = useExchangeDetails({ exchange })

  const feeInfo: IExchangeCardInfoData[] = useMemo(() => {
    if (!exchange) {
      return []
    }

    const keys: Array<keyof Omit<IExchange, 'wallet'>> = ['feeAmount']

    return keys.map(transformKeyToDataRow).concat([
      {
        title: t(`exchange-details.additional-keys.feeAmountSubtract`),
        value:
          exchange.type === 'SELL'
            ? exchange.buyingAmount
            : Number(exchange.sellingAmount) -
              Number(exchange.feeAmount) +
              ` ${exchange.feeCurrency || 'EUR'}`,
        show: exchange.type === 'BUY',
      },
    ])
  }, [exchange])

  return (
    <ExchangeCardInfo
      title={t('exchange-details.fee-info-title')}
      data={feeInfo}
      sx={sx}
      extraData={<ExchangeTariffInfo tariffInfo={exchange?.tariffInfo} />}
    />
  )
}
