import { useState } from 'react'

import { TWalletTypeFilterType } from '@entities/wallet'

import { IFilterOptions } from '@shared/ui'

const filterOptions: TWalletTypeFilterType[] = [
  'all',
  'corporate',
  'individual',
]

export function useWalletsFilters() {
  const [currentType, setCurrentType] = useState<TWalletTypeFilterType>('all')

  const filtersOptions: IFilterOptions[] = [
    {
      options: filterOptions,
      currentOption: currentType,
      setCurrentOption: setCurrentType,
      filterName: 'type',
    },
  ]

  return { filtersOptions, currentType }
}
