import { Box, Fade, SxProps, Typography } from '@mui/material'
import { useMemo, useRef, useState } from 'react'

import Logo from '@shared/assets/logo.svg'
import { useSidebarStore } from '../model'
import { RollButton, SidebarMenuList } from './components'
import {
  BorderBackground,
  DesktopSidebarBox,
  Wrapper,
  StickyWrapper,
  LogoWrapper,
} from './styled'

interface IProps {
  sx?: SxProps
}

const minSidebarWidth = 88
const maxSidebarWidth = 320

export function Sidebar({ sx }: IProps) {
  const { open, setOpen } = useSidebarStore()
  const [hover, setHover] = useState(false)
  const timeout = useRef<null | NodeJS.Timeout>(null)

  const [rollButtonHover, setRollButtonHover] = useState(false)
  const [hideRollButton, setHideRollButton] = useState(false)

  const wrapperSx: SxProps | undefined = useMemo(() => {
    return { ...(rollButtonHover && { width: maxSidebarWidth }) }
  }, [hover, rollButtonHover])

  const stickyWrapperWidth = useMemo(() => {
    return open ? maxSidebarWidth : minSidebarWidth
  }, [open])

  const borderBackgroundSx: SxProps | undefined = useMemo(() => {
    return { ...(rollButtonHover && { filter: 'blur(2px)' }) }
  }, [rollButtonHover])

  const closeSidebar = () => {
    setOpen(false)

    mouseLeaveRollButton()

    setHover(false)

    setRollButtonHover(false)

    setHideRollButton(true)

    setTimeout(() => {
      setHideRollButton(false)
    }, 100)
  }

  const mouseOverRollButton = () => {
    timeout.current = setTimeout(() => {
      !open && setRollButtonHover(true)
    }, 300)
  }

  const mouseLeaveRollButton = () => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
  }

  return (
    <StickyWrapper sx={{ minWidth: stickyWrapperWidth, ...sx }}>
      <Wrapper
        onMouseOver={() => {
          !hideRollButton && setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)

          setRollButtonHover(false)
        }}
        sx={wrapperSx}
      >
        <BorderBackground sx={borderBackgroundSx} />

        <DesktopSidebarBox>
          <Fade in={hover}>
            <div>
              <RollButton
                open={open}
                setOpen={setOpen}
                onMouseOver={mouseOverRollButton}
                onMouseLeave={mouseLeaveRollButton}
                onClose={closeSidebar}
              />
            </div>
          </Fade>

          <LogoWrapper open={open || rollButtonHover} href={'/'}>
            <Box component={'img'} src={Logo} width={'100%'} />

            <Typography
              color={'info.main'}
              textAlign={'end'}
              variant={'body1'}
              sx={open || rollButtonHover ? {} : { fontSize: { xs: 10 } }}
            >
              Backoffice
            </Typography>
          </LogoWrapper>

          <SidebarMenuList
            open={open}
            hover={rollButtonHover}
            sx={{ p: 0, flex: 1, display: 'flex', flexDirection: 'column' }}
          />
        </DesktopSidebarBox>
      </Wrapper>
    </StickyWrapper>
  )
}
