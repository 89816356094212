import { instance } from '@shared/requester'
import { TAccountType, IUserPlanInfo } from '@shared/types'

export async function getUserTariffs(id?: string) {
  if (!id) {
    throw new Error()
  }

  return instance
    .get<Record<TAccountType, IUserPlanInfo>>(`/users/${id}/tariffs`)
    .then((response) => response.data)
}
