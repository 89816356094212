import { instance } from '@shared/requester'

import { TStatisticsOperation } from '../model'

interface IGetAllStatisticsRequestParams {
  dateFrom: string //2024-06-01
  dateTo: string //2024-09-01
  userIds?: string[] //optional, undefined by default, left undefined if not need, example: ["17", "18", "15"]
  excludeScam?: boolean //optional, true by default
  operations?: TStatisticsOperation[] //optional, undefined by default, left undefined if not need, available: ["exchange-sell", "exchange-buy", "top-up", "withdraw", "transfer"]
}

export async function getAllStatistics(params: IGetAllStatisticsRequestParams) {
  return instance
    .get<Blob>('/wallets/statistic/full', { params })
    .then((response) => response.data)
}
