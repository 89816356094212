import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { IUser } from '@shared/types'
import { getUser } from '../api'

interface IErrorResponse {
  statusCode: number
  message: string
}

interface IProps {
  userId?: string
}

export function useUserData({ userId }: IProps) {
  return useQuery<IUser, AxiosError<IErrorResponse>>({
    queryKey: ['get-user', userId],
    queryFn: () => getUser(userId),
    refetchInterval: 90 * 1000,
  })
}
