import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import { StyledIconButton } from '../styled'

interface IProps {
  open: boolean
  setOpen: (open: boolean) => void
  onMouseOver: () => void
  onMouseLeave: () => void
  onClose: () => void
}

export function RollButton({
  open,
  setOpen,
  onMouseOver,
  onMouseLeave,
  onClose,
}: IProps) {
  return (
    <StyledIconButton
      open={open}
      onClick={() => {
        open ? onClose() : setOpen(true)
      }}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <ArrowBackIosRoundedIcon sx={{ fontSize: 20 }} />
    </StyledIconButton>
  )
}
