export interface ICryptoTransaction {
  id: string
  requestId: string
  externalId: string
  addressFrom: string
  addressTo: string
  amount: string
  currency: TCryptoTransactionCurrency
  status: TCryptoTransactionStatus
  type: TCryptoTransactionType
  hash: string
  reason: string
  createdAt: string
  updatedAt: string
  userId?: string
  displayNameFrom?: string
  displayNameTo?: string
}

export type TCryptoTransactionCurrency = 'USDT' | 'TRX'

export const cryptoTransactionTypes = ['transfer', 'sell', 'buy'] as const

export type TCryptoTransactionType = (typeof cryptoTransactionTypes)[number]

export const cryptoTransactionStatuses = [
  'CREATED',
  'SENT',
  'REJECTED',
  'DONE',
  'REJECTION',
  'FAIL',
] as const

export type TCryptoTransactionStatus =
  (typeof cryptoTransactionStatuses)[number]

export const incomingTypes = ['send', 'receive'] as const

export type TTransactionIncomingType = (typeof incomingTypes)[number]
