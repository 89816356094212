import { useQuery } from '@tanstack/react-query'

import { IWallet, TWalletType } from '@shared/types'

import { getWalletsList } from '../api'

interface IProps {
  page: number
  rowsPerPage: string
  userId?: string
  type?: TWalletType
  address?: IWallet['address']
  enabled?: boolean
}

export function useGetWallets({
  page,
  rowsPerPage,
  type,
  userId,
  address,
  enabled = true,
}: IProps) {
  return useQuery({
    queryKey: ['get-wallets-list', page, rowsPerPage, userId, type, address],
    queryFn: () =>
      getWalletsList({
        page,
        perPage: rowsPerPage,
        userId,
        type,
        address,
      }),
    refetchInterval: 90 * 1000,
    enabled,
  })
}
