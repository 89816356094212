import { Components, LinkProps } from '@mui/material'

import { forwardRef } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'
import { green, grey, orange, red } from '@shared/constants'
import { defaultTheme } from './default-theme'

// eslint-disable-next-line react/display-name,react-refresh/only-export-components
const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & {
    href: RouterLinkProps['to']
  }
>((props, ref) => {
  const { href, ...other } = props

  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />
})

export const components: Components = {
  MuiButton: {
    variants: [
      // TEXT BUTTON
      {
        props: { variant: 'text', color: 'primary' },
        style: {
          color: defaultTheme.palette.primary.main,
          '&:hover': {
            backgroundColor: defaultTheme.palette.primary.light,
          },
        },
      },
      {
        props: { variant: 'text', color: 'info' },
        style: {
          color: defaultTheme.palette.info.main,
          '&:hover': {
            backgroundColor: defaultTheme.palette.info.light,
          },
        },
      },
      {
        props: { variant: 'text', color: 'error' },
        style: {
          color: defaultTheme.palette.error.main,
          '&:hover': {
            backgroundColor: red[200],
          },
          '&:focus-visible': {
            backgroundColor: defaultTheme.palette.error.light,
          },
        },
      },
      {
        props: { variant: 'text', color: 'success' },
        style: {
          color: defaultTheme.palette.success.dark,
          '&:hover': {
            backgroundColor: green[200],
          },
          '&:focus-visible': {
            backgroundColor: defaultTheme.palette.success.light,
          },
        },
      },
      {
        props: { variant: 'text', color: 'warning' },
        style: {
          color: defaultTheme.palette.warning.dark,
          '&:hover': {
            backgroundColor: orange[200],
          },
          '&:focus-visible': {
            backgroundColor: defaultTheme.palette.warning.light,
          },
        },
      },

      // CONTAINED BUTTON
      {
        props: { variant: 'contained', color: 'primary' },
        style: {
          color: defaultTheme.palette.primary.contrastText,
          backgroundColor: defaultTheme.palette.primary.main,
          '&:hover': {
            backgroundColor: defaultTheme.palette.primary.dark,
          },
        },
      },
      {
        props: { variant: 'contained', color: 'info' },
        style: {
          color: defaultTheme.palette.info.contrastText,
          backgroundColor: defaultTheme.palette.info.dark,
          '&:hover': {
            backgroundColor: defaultTheme.palette.primary.dark,
          },
        },
      },
      {
        props: { variant: 'contained', color: 'error' },
        style: {
          color: defaultTheme.palette.error.main,
          backgroundColor: defaultTheme.palette.error.light,
          '&:hover': {
            backgroundColor: red[200],
          },
        },
      },
      {
        props: { variant: 'contained', color: 'success' },
        style: {
          color: defaultTheme.palette.success.dark,
          backgroundColor: defaultTheme.palette.success.light,
          '&:hover': {
            backgroundColor: green[200],
          },
        },
      },
      {
        props: { variant: 'contained', color: 'warning' },
        style: {
          color: defaultTheme.palette.warning.dark,
          backgroundColor: defaultTheme.palette.warning.light,
          '&:hover': {
            backgroundColor: orange[200],
          },
        },
      },
      {
        props: { variant: 'contained', disabled: true },
        style: {
          backgroundColor: grey[50] + ' !important',
          color: defaultTheme.palette.text.disabled,
        },
      },

      // OUTLINED BUTTON
      {
        props: { variant: 'outlined', color: 'primary' },
        style: {
          color: defaultTheme.palette.primary.main,
          borderColor: defaultTheme.palette.primary.main,
          '&:hover': {
            backgroundColor: defaultTheme.palette.primary.light,
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'info' },
        style: {
          color: defaultTheme.palette.info.main,
          borderColor: defaultTheme.palette.info.main,
          '&:hover': {
            backgroundColor: defaultTheme.palette.info.light,
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'error' },
        style: {
          color: defaultTheme.palette.error.main,
          borderColor: defaultTheme.palette.error.main,
          '&:hover': {
            backgroundColor: red[200],
          },
          '&:focus-visible': {
            backgroundColor: defaultTheme.palette.error.light,
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'success' },
        style: {
          color: defaultTheme.palette.success.dark,
          borderColor: defaultTheme.palette.success.dark,
          '&:hover': {
            backgroundColor: green[200],
          },
          '&:focus-visible': {
            backgroundColor: defaultTheme.palette.success.light,
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'warning' },
        style: {
          color: defaultTheme.palette.warning.dark,
          borderColor: defaultTheme.palette.warning.dark,
          '&:hover': {
            backgroundColor: orange[200],
          },
          '&:focus-visible': {
            backgroundColor: defaultTheme.palette.warning.light,
          },
        },
      },
      {
        props: { variant: 'outlined', disabled: true },
        style: {
          backgroundColor: grey[50] + ' !important',
          color: defaultTheme.palette.text.disabled,
          borderColor: defaultTheme.palette.text.disabled,
        },
      },
    ],
    styleOverrides: {
      root: {
        ...defaultTheme.typography.button,

        borderRadius: '10px',
        '&:focus-visible': {
          outline: 'none',
          boxShadow: `0px 0px 0px 3px ${orange[500]}`,
        },
        '&.Mui-disabled': {
          color: defaultTheme.palette.text.disabled,
        },
      },
      sizeSmall: {
        padding: '8px 16px',
      },
      sizeMedium: {
        padding: '14px 16px',
      },
      sizeLarge: {
        padding: '16px 24px',
      },
    },
  },
  MuiButtonBase: {
    defaultProps: { LinkComponent: LinkBehavior },
  },
  MuiLink: {
    defaultProps: {
      component: LinkBehavior,
    } as LinkProps,
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        display: 'block',
      },
    },
  },
}
