import { useState } from 'react'

import { CustomPagination } from '../../ui'

export function useCustomPagination() {
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<string>('10')
  const [totalPages, setTotalPages] = useState<number>(1)
  const [listLength, setListLength] = useState<number>(0)

  const paginationNode = (
    <CustomPagination
      onChangePerPage={setRowsPerPage}
      onChangePage={setPage}
      totalPages={totalPages}
      rowsPerPage={rowsPerPage}
      currentPage={page}
      listLength={listLength}
    />
  )

  return {
    paginationNode,
    page,
    rowsPerPage,
    setTotalPages,
    setListLength,
    setPage,
  }
}
