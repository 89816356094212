import { Container, Paper, Stack, Typography } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { BackButton, GoToUserPageLink } from '@features/buttons'
import { TopUpAccountWithOtp } from '@features/top-up-account'

import { useGetAccount } from '@entities/accounts'

import { TParamsKeys, routePaths } from '@shared/constants'
import { StyledToolbar } from '@shared/ui'

import { accountLists } from '../model'
import { AccountMainInfo, AccountTabs } from './components'

export interface IConvertValueProps {
  value?: string | number
  objectKey: string
}

export function AccountPage() {
  const { t } = useTranslation('pages')
  const { accountId } = useParams<TParamsKeys>()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    data: account,
    isLoading,
    isError,
    failureReason,
    refetch,
  } = useGetAccount({ accountId })

  useEffect(() => {
    isError && toast.error(`${failureReason?.response?.data.message}`)
  }, [failureReason])

  const userName = useMemo(
    () =>
      account &&
      (account.type === 'corporate'
        ? account.corporateName
        : account.firstName &&
          account.lastName &&
          `${account.firstName} ${account.lastName}`),
    [account]
  )

  useEffect(() => {
    if (location.pathname.split('/').filter((item) => item).length < 3) {
      navigate(
        `${routePaths.accounts.list}/${accountId}/${accountLists.withdraws}`,
        {
          replace: true,
        }
      )
    }
  }, [])

  return (
    <>
      <StyledToolbar variant="dense">
        <BackButton />

        <Stack direction="row" spacing={1} justifyContent="center">
          <Typography>{`${t('account.title')}`}</Typography>

          {account?.userId && (
            <GoToUserPageLink userId={account.userId} userName={userName} />
          )}
        </Stack>

        {account && (
          <TopUpAccountWithOtp
            accountId={account.id}
            accountUuid={account.account}
            refetch={refetch}
            sxTopUpButton={{ width: 150 }}
          />
        )}
      </StyledToolbar>

      <Container maxWidth={false}>
        <Paper>
          <AccountMainInfo account={account} isLoading={isLoading} />

          {accountId && (
            <>
              <AccountTabs />
              <Outlet />
            </>
          )}
        </Paper>
      </Container>
    </>
  )
}
