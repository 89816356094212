import { SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { isToday, isYesterday } from '@shared/utils'

import { StyledBadgeBox } from './styled'

interface IProps {
  sx?: SxProps
  date: Date | number | string
}

export function TodayYesterdayBadge({ date, sx }: IProps) {
  const { t } = useTranslation('shared')

  return (
    <>
      {isToday(date) && (
        <StyledBadgeBox bgcolor="#71a6db" sx={sx}>
          {t('today')}
        </StyledBadgeBox>
      )}

      {isYesterday(date) && (
        <StyledBadgeBox bgcolor="#d3d7db" sx={sx}>
          {t('yesterday')}
        </StyledBadgeBox>
      )}
    </>
  )
}
