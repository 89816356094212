import { countries } from '@shared/constants'

interface IProps {
  value?: string | null
  defaultValue?: string | null
}

export const convertCountryCodeToFullCountryName = ({
  value,
  defaultValue,
}: IProps) => {
  const country = countries.find(
    (country) => value === country.abbreviation
  )?.name

  return country || defaultValue
}
