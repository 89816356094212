import { styled } from '@mui/material'

interface IProps {
  bgcolor?: string
}

export const StyledBadgeBox = styled('div')<IProps>(({ bgcolor }) => ({
  minWidth: 120,
  padding: '0 8px',
  borderRadius: 100,
  backgroundColor: bgcolor,
  textAlign: 'center',
  color: 'white',
}))
