import { TypographyOptions } from '@mui/material/styles/createTypography'
import { defaultTheme } from './default-theme'

export const typography: TypographyOptions = {
  fontFamily: [
    'Simplon Norm Trial',
    'sans-serif',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  ].join(','),
  h1: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 32,
      lineHeight: '40px',
    },
  },
  h2: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '28px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 28,
      lineHeight: '36px',
    },
  },
  h3: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '26px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 24,
      lineHeight: '32px',
    },
  },
  h4: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 20,
      lineHeight: '28px',
    },
  },
  h5: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  h6: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '18px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  body1: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  body2: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  display1: {
    fontWeight: 300,
    fontSize: 48,
    lineHeight: '56px',
    letterSpacing: '-0.5px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 36,
      lineHeight: '44px',
      letterSpacing: '-px',
    },
  },
  display2: {
    fontWeight: 300,
    fontSize: 28,
    lineHeight: '36px',
    letterSpacing: '-0.5px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 36,
      lineHeight: '44px',
    },
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    letterSpacing: '0.1px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: '16px',
    letterSpacing: '0.1px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  caption: {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  overline: {
    fontWeight: 400,
    fontSize: 8,
    lineHeight: '14px',
    letterSpacing: '1.5px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 10,
      lineHeight: '14px',
    },
  },
  button: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '1.25px',
  },
  link: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
    letterSpacing: '0.5px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  numeric1: {
    fontWeight: 400,
    fontSize: 28,
    lineHeight: '36px',
    letterSpacing: '-0.5px',
  },
  focus1: {
    fontWeight: 700,
    fontSize: 44,
    lineHeight: '52px',
    letterSpacing: '0.25px',
    [defaultTheme.breakpoints.up('md')]: {
      fontSize: 64,
      lineHeight: '76px',
    },
  },
  focus2: {
    fontWeight: 400,
    fontSize: 22,
    lineHeight: '34px',
    letterSpacing: '-0.5px',
  },
}
