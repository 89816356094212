import { IExchange } from '@entities/exchange'

import { instance } from '@shared/requester'

export async function getExchange(id?: string) {
  if (!id) {
    throw new Error()
  }

  return instance
    .get<IExchange>(`/exchanges/${id}`)
    .then((response) => response.data)
}
