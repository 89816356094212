import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { TParamsKeys, expandedParamName, routePaths } from '@shared/constants'
import { CustomTabs, ITab } from '@shared/ui'

import { walletLists } from '../../model'

export const WalletTabs = memo(function AccountTabs() {
  const { t } = useTranslation('pages')
  const { walletId } = useParams<TParamsKeys>()

  const mainPath = `${routePaths.wallets.list}/${walletId}`

  const tabs: ITab[] = [
    {
      label: t('wallet.tabs.exchanges'),
      value: `${mainPath}/${walletLists.exchanges}`,
    },
  ]

  return <CustomTabs tabsRoutes={tabs} searchParamName={expandedParamName} />
})
