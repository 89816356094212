import { instance } from '@shared/requester'
import { ICorporateApplication } from '@shared/types'

interface IRequestParams {
  applicationId?: string
}

export async function getCorporateApplication({
  applicationId,
}: IRequestParams) {
  if (!applicationId) {
    throw new Error()
  }

  return instance
    .get<ICorporateApplication>(`/applications/${applicationId}/corporate`)
    .then((response) => response.data)
}
