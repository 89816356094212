import { RouteObject } from 'react-router-dom'

import { routePaths } from '@shared/constants'

import { ApplicationsListPage } from '../ui'

export const ApplicationsListRouter: RouteObject = {
  path: routePaths.applications.list,
  element: <ApplicationsListPage />,
}
