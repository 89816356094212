import { TableCell, Table, TableBody, TableContainer } from '@mui/material'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { MapObjectValue } from '@entities/application'

import {
  IAddress,
  ICompanyDetails,
  ICompanyShareholderDetails,
  IDocument,
  ILegalDocumentsImages,
  IPersonalDetails,
  IPersonalShareholderDetails,
  TCompanyDetailsKeys,
  TPersonalDetailsKeys,
} from '@shared/types'
import { StyledTableRow } from '@shared/ui'
import { convertCountryCodeToFullCountryName } from '@shared/utils'

import { DocumentsList } from './documents-list'
import { LegalDocumentsImages } from './legal-documents-images'

interface IProps {
  value:
    | IPersonalShareholderDetails
    | IPersonalDetails
    | ICompanyShareholderDetails
    | ICompanyDetails
}

// TODO: refactor this component
export function OwnersDetails({ value }: IProps) {
  const { t } = useTranslation('pages')

  const convertValue = (
    item: string | IDocument[] | IAddress | ILegalDocumentsImages,
    objectKey: TPersonalDetailsKeys | TCompanyDetailsKeys
  ) => {
    if (typeof item === 'object' && item !== null) {
      if (objectKey === 'documents') {
        return <DocumentsList documents={item as IDocument[]} />
      }

      if (objectKey === 'address') {
        return <MapObjectValue object={item} type="corporate" />
      }

      if (objectKey === 'legalDocumentsImages' && 'type' in item) {
        return <LegalDocumentsImages images={item.images} type={item.type} />
      }

      return <></>
    }

    switch (objectKey) {
      case 'dateOfBirthday':
      case 'personalNumber':
      case 'number':
      case 'firstName':
      case 'lastName':
        return item
      default:
        return convertCountryCodeToFullCountryName({
          value: item,
          defaultValue: item,
        })
    }
  }

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {_.map(value, (item, objectKey) => (
              <StyledTableRow hover tabIndex={-1} key={objectKey}>
                <TableCell>
                  {t(`corporate-application.object keys.${objectKey}` as never)}
                </TableCell>

                <TableCell>
                  {convertValue(
                    item,
                    objectKey as TPersonalDetailsKeys | TCompanyDetailsKeys
                  )}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
