import { useTranslation } from 'react-i18next'
import { ITableHeadCell } from '@shared/types'

type TTableColumnName =
  | 'id'
  | 'full name'
  | 'verification status'
  | 'email address'
  | 'phone number'
  | 'created at'
  | 'status'

type TReturnedFunc = ITableHeadCell<TTableColumnName>[]

export function useTableColumnsNames(): TReturnedFunc {
  const { t } = useTranslation('pages')

  const tableColumnsNames: TReturnedFunc = [
    {
      cellName: 'id',
      text: t('users list.table columns names.id'),
    },
    {
      cellName: 'full name',
      text: t('users list.table columns names.full name'),
    },
    {
      cellName: 'verification status',
      text: t('users list.table columns names.verification status'),
    },
    {
      cellName: 'email address',
      text: t('users list.table columns names.email address'),
    },
    {
      cellName: 'phone number',
      text: t('users list.table columns names.phone number'),
    },
    {
      cellName: 'created at',
      text: t('users list.table columns names.created at'),
    },
    {
      cellName: 'status',
      text: t('users list.table columns names.status'),
    },
  ]

  return tableColumnsNames
}
