import { Table, TableBody, TableCell, TableContainer } from '@mui/material'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { IAccount } from '@entities/accounts'

import { CustomAccordion, ShowEuro, StyledTableRow } from '@shared/ui'
import { convertValueToDate } from '@shared/utils'

import { IConvertValueProps } from '../account-page'

interface IProps {
  account?: IAccount
  isLoading: boolean
}

export function AccountMainInfo({ account, isLoading }: IProps) {
  const { t } = useTranslation('pages')

  const convertValue = ({ value, objectKey }: IConvertValueProps) => {
    const typedObjectKey = objectKey as keyof IAccount

    switch (typedObjectKey) {
      case 'createdAt':
      case 'updatedAt':
        return convertValueToDate({ value })
      case 'balance':
        return <ShowEuro cents={value as number} />
      default:
        return value
    }
  }

  return (
    <CustomAccordion title={t('account.show details')} loading={isLoading}>
      <TableContainer sx={{ mb: 2 }}>
        <Table size="small">
          <TableBody>
            {_.map(account, (value, objectKey) => (
              <StyledTableRow hover tabIndex={-1} key={objectKey}>
                <TableCell>
                  {t(`account.object keys.${objectKey}` as never)}
                </TableCell>

                <TableCell>{convertValue({ value, objectKey })}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomAccordion>
  )
}
