import { useState } from 'react'

import { TAccountTypeFilterType } from '@entities/accounts'

import { IFilterOptions } from '@shared/ui'

const filterOptions: TAccountTypeFilterType[] = [
  'all',
  'corporate',
  'individual',
]

export function useAccountsFilters() {
  const [currentType, setCurrentType] = useState<TAccountTypeFilterType>('all')

  const filtersOptions: IFilterOptions[] = [
    {
      options: filterOptions,
      currentOption: currentType,
      setCurrentOption: setCurrentType,
      filterName: 'status',
    },
  ]

  return { filtersOptions, currentType }
}
