import axios from 'axios'

import { urls } from '@shared/constants'
import keycloak from '@shared/keycloak'

const instance = axios.create({ baseURL: urls.api })

instance.interceptors.request.use(
  async (config) => {
    // Do something before request is sent

    config.headers &&
      (config.headers.Authorization = 'Bearer ' + keycloak.token)

    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      !error ||
      !error.response ||
      (error.response &&
        error.response.status > 401 &&
        error.response.status !== 403)
    ) {
      return Promise.reject(error || new Error('Bad request'))
    }

    const { response, config } = error
    const { status } = response

    try {
      if (status === 403) {
        await keycloak.logout()

        return Promise.reject(error)
      }

      if (status === 401) {
        const isTokenUpdated = await keycloak.updateToken()

        if (!isTokenUpdated) {
          return Promise.reject(error)
        }

        const secondRequestResponse = await axios.request({
          ...config,
          headers: {
            ...config.headers,
            Authorization: 'Bearer ' + keycloak.token,
          },
        })

        if (!secondRequestResponse) {
          return Promise.reject(error)
        }

        return secondRequestResponse
      } else {
        return Promise.reject(error)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return Promise.reject(error)
    }
  }
)

export { instance }
