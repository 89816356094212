import { useEffect } from 'react'

import { WalletsTable } from '@widgets/wallet'

import { useWalletsFilters } from '@features/wallet'

import { useGetWallets } from '@entities/wallet'

import { useCustomPagination } from '@shared/ui'

export function WalletsListPage() {
  const { currentType, filtersOptions } = useWalletsFilters()

  const {
    page,
    rowsPerPage,
    paginationNode,
    setTotalPages,
    setListLength,
    setPage,
  } = useCustomPagination()

  const { data: wallets, isLoading } = useGetWallets({
    page,
    rowsPerPage,
    type: currentType !== 'all' ? currentType : undefined,
  })

  useEffect(() => {
    if (wallets) {
      setTotalPages(wallets.totalPages)

      setListLength(wallets.list.length)
    }
  }, [wallets?.totalPages])

  useEffect(() => {
    setPage(1)
  }, [currentType])

  return (
    <WalletsTable
      isLoading={isLoading}
      currentType={currentType}
      filterMenuOptions={filtersOptions}
      rowsPerPage={+rowsPerPage}
      paginationNode={paginationNode}
      walletsList={wallets?.list}
    />
  )
}
