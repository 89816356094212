import { useState } from 'react'

import { TApplicationsStatusFilterType } from '@entities/applications-list'

import { IFilterOptions } from '@shared/ui'

const filterOptions: TApplicationsStatusFilterType[] = [
  'all',
  'approved',
  'rejected',
  'received',
]

export function useApplicationsFilters() {
  const [currentStatus, setCurrentStatus] =
    useState<TApplicationsStatusFilterType>('all')

  const filtersOptions: IFilterOptions[] = [
    {
      options: filterOptions,
      currentOption: currentStatus,
      setCurrentOption: setCurrentStatus,
      filterName: 'status',
    },
  ]

  return { filtersOptions, currentStatus }
}
