import { TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { MapObjectValue } from '@entities/application'

import { TIndividualApplicationKeys } from '@shared/types'
import { ConvertTermsAndConditions, StyledTableRow } from '@shared/ui'
import {
  convertValueToDate,
  convertCountryCodeToFullCountryName,
  convertFullCountriesNamesFromCountriesCodesString,
} from '@shared/utils'

interface IProps {
  objectKey: string
  value?: string | boolean | object | null
}

export function RowPersonalApplicationInfo({ objectKey, value }: IProps) {
  const { t } = useTranslation('pages')
  const typedObjectKey = objectKey as TIndividualApplicationKeys

  const convertValue = () => {
    if (typeof value === 'object' && value !== null) {
      return <MapObjectValue object={value} type="individual" />
    }

    if (typeof value === 'boolean') {
      return JSON.stringify(value)
    }

    switch (typedObjectKey) {
      case 'createdAt':
      case 'updatedAt':
        return convertValueToDate({ value })
      case 'termsAndConditions':
        return (
          <ConvertTermsAndConditions
            value={value}
            mainTranslateKey="pages:individual-application.object keys"
          />
        )
      case 'dualCitizenshipCountry':
      case 'nationality':
      case 'citizen':
      case 'issuing':
      case 'taxCountry':
        return convertCountryCodeToFullCountryName({
          value,
          defaultValue: value,
        })
      case 'countriesToWorkIncome':
      case 'countriesToWorkOutcome':
        return convertFullCountriesNamesFromCountriesCodesString(value)
      default:
        return value
    }
  }

  return (
    <StyledTableRow hover tabIndex={-1} key={objectKey}>
      <TableCell>
        {t(`individual-application.object keys.${objectKey}` as never)}
      </TableCell>

      <TableCell>{convertValue()}</TableCell>
    </StyledTableRow>
  )
}
