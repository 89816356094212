import CircleIcon from '@mui/icons-material/Circle'
import { styled } from '@mui/material'

export const StyledCircleIcon = styled(CircleIcon)(({ theme }) => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 24,
  color: theme.palette.primary.main,
}))
