import { InputBase, SxProps, styled } from '@mui/material'

const xFactor = 0.8

interface IProps {
  xFact?: number
}

export const Input = styled(InputBase)<IProps>(
  ({ theme, xFact = xFactor }) => ({
    width: 48 * xFact,
    height: 58 * xFact,
    input: {
      fontWeight: 400,
      padding: '12px 16px',
      height: '100%',
      borderRadius: '4px',
      fontSize: 26,
      lineHeight: 'normal',
      '&.not-empty': {
        backgroundColor: '#EAEAEA',
      },
      '&.not-empty~fieldset': {
        borderColor: '#EAEAEA',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: 48,
      height: 58,
    },
    [theme.breakpoints.up('md')]: {
      width: 61,
      height: 72,
      input: {
        fontSize: 40,
        lineHeight: '120%',
      },
    },
  })
)

export const sxForInputs: SxProps = {
  width: { xs: 48 * xFactor, sm: 48, md: 61 },
  height: { xs: 58 * xFactor, sm: 58, md: 72 },
  input: {
    fontWeight: 400,
    p: 0,
    height: '100%',
    borderRadius: '4px',
    fontSize: { xs: 26, md: 40 },
    lineHeight: { xs: 'normal', md: '120%' },
    '&.not-empty': {
      backgroundColor: '#EAEAEA',
    },
    '&.not-empty~fieldset': {
      borderColor: '#EAEAEA',
    },
  },
  fieldset: {
    borderColor: '#009EE0',
  },
}
