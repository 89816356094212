import { useQuery } from '@tanstack/react-query'

import {
  TWithdrawsStatusFilterType,
  getWithdrawsList,
} from '@entities/withdraw'

interface IProps {
  page: number
  rowsPerPage: string
  currentStatus: TWithdrawsStatusFilterType
}

export function useGetWithdrawsList({
  page,
  rowsPerPage,
  currentStatus,
}: IProps) {
  return useQuery({
    queryKey: ['get-withdraws-list', page, rowsPerPage, currentStatus],
    queryFn: () =>
      getWithdrawsList({
        page,
        perPage: rowsPerPage,
        status: currentStatus === 'all' ? undefined : currentStatus,
      }),
    refetchInterval: 30 * 1000,
  })
}
