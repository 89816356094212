import { Stack, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ITableHeadCell } from '@shared/types'
import { StyledTableHeadCell } from '@shared/ui'
import { TTransactionTable } from '../../model'

interface IProps {
  isOwnPage: boolean
  filtersValues?: Record<string, string>
  type: TTransactionTable
}

type TTableColumnName =
  | 'id'
  | 'full name'
  | 'name from'
  | 'name to'
  | 'status'
  | 'created at'
  | 'from'
  | 'to'
  | 'amount'
  | 'type'

export function TransactionsTableHead({
  isOwnPage,
  filtersValues,
  type,
}: IProps) {
  const { t } = useTranslation('widgets')

  const tableColumnsNames: ITableHeadCell<TTableColumnName>[] = [
    {
      cellName: 'id',
      text: t('transactions-table.table-columns-names.id'),
    },
    ...(type === 'crypto'
      ? ([
          {
            cellName: 'name from',
            text: t('transactions-table.table-columns-names.name-from'),
          },
          {
            cellName: 'name to',
            text: t('transactions-table.table-columns-names.name-to'),
          },
        ] as ITableHeadCell<TTableColumnName>[])
      : ([
          {
            cellName: 'full name',
            text: t('transactions-table.table-columns-names.full name'),
          },
        ] as ITableHeadCell<TTableColumnName>[])),

    {
      cellName: 'from',
      text: t('transactions-table.table-columns-names.from'),
    },
    {
      cellName: 'to',
      text: t('transactions-table.table-columns-names.to'),
    },
    {
      cellName: 'amount',
      text: t('transactions-table.table-columns-names.amount'),
    },
    {
      cellName: 'type',
      text: t('transactions-table.table-columns-names.type'),
    },
    {
      cellName: 'status',
      text: t('transactions-table.table-columns-names.status'),
    },
    {
      cellName: 'created at',
      text: t('transactions-table.table-columns-names.created at'),
    },
  ]

  return (
    <TableRow>
      {tableColumnsNames.map((columnName) =>
        !isOwnPage && columnName.cellName === 'full name' ? undefined : (
          <StyledTableHeadCell key={columnName.cellName}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography fontSize="small" fontWeight="600">
                {columnName.text}

                {columnName.cellName === 'status' &&
                  filtersValues &&
                  filtersValues.status !== 'all' &&
                  ` (${filtersValues.status})`}

                {columnName.cellName === 'type' &&
                  filtersValues &&
                  filtersValues.type !== 'all' &&
                  ` (${filtersValues.type})`}
              </Typography>
            </Stack>
          </StyledTableHeadCell>
        )
      )}
    </TableRow>
  )
}
