import { SxProps, Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IConfirmCancelExchangeProps } from '../model'
import { CancelExchangeModal } from './components'

interface IProps extends IConfirmCancelExchangeProps {
  sx?: SxProps
}

export function CancelExchange(props: IProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { t } = useTranslation('features')

  return (
    <>
      <Button
        variant="contained"
        color="error"
        onClick={() => setIsOpen(true)}
        sx={props.sx}
      >
        {t('exchange.cancel-exchange')}
      </Button>

      <CancelExchangeModal
        show={isOpen}
        onClose={() => setIsOpen(false)}
        {...props}
      />
    </>
  )
}
