import { LoadingButton } from '@mui/lab'
import { Button, Stack, SxProps } from '@mui/material'
import to from 'await-to-js'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { TWithdrawStatus } from '@shared/types'
import {
  OtpDialog,
  TOtpConfirmationMethod,
  TOtpConfirmationStatuses,
  TOtpResendCodeMethod,
} from '@shared/ui'
import { getTimeToLeftFromTimestamp } from '@shared/utils'

import { verifySmsWithdraw, sendSmsWithdraw, cancelWithdraw } from '../model'

interface IProps {
  withdrawId: string
  refetch: () => void
  status: TWithdrawStatus
  sx?: SxProps
}

export function ConfirmCancelWithdraw({
  withdrawId,
  refetch,
  status,
  sx,
}: IProps) {
  const { t } = useTranslation(['features', 'common'])
  const [isShowOtp, setIsShowOtp] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isShowButtons, setIsShowButtons] = useState<boolean>(true)
  const [TTL, setTTL] = useState<number>()

  const otpErrorResolver = (error: unknown): TOtpConfirmationStatuses => {
    switch (error) {
      case '422':
        return 'NOT_VALID'
      case '400':
        return 'EXPIRE'
      default:
        throw new Error('Something went wrong. Please try again later.')
    }
  }

  const confirmationMethod: TOtpConfirmationMethod = async ({ otp }) => {
    if (!withdrawId) {
      throw new Error('Withdraw id is not defined')
    }

    const [error] = await to(verifySmsWithdraw({ withdrawId, code: otp }))

    if (!error) {
      setIsShowButtons(false)

      return 'SUCCESS'
    }

    return otpErrorResolver(error?.message)
  }

  const onConfirm = () => {
    toast.success(t('common:success'))

    setIsShowOtp(false)

    refetch()
  }

  const resendOtp: TOtpResendCodeMethod = async () => {
    const response = await sendSmsWithdraw({ withdrawId })

    if (!response || response.type === 'fail') {
      toast.error(t('common:try again'))

      throw { result: false, ttl: 0 }
    }

    setTTL(getTimeToLeftFromTimestamp(response.confirmTo))

    return {
      result: true,
      ttl: getTimeToLeftFromTimestamp(response.confirmTo),
    }
  }

  const handleClickConfirm = async () => {
    try {
      setIsLoading(true)

      const response = await sendSmsWithdraw({ withdrawId })

      setTTL(getTimeToLeftFromTimestamp(response.confirmTo))

      setIsShowOtp(true)
    } catch {
      toast.error(t('common:oops'))
    } finally {
      setIsLoading(false)
    }
  }

  const handleClickCancelWithdraw = async () => {
    try {
      setIsLoading(true)

      await cancelWithdraw({ withdrawId })

      refetch()

      setIsShowButtons(false)

      toast.success(t('confirm-cancel-withdraw.canceled'))
    } catch {
      toast.error(t('common:oops'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isShowButtons && (
        <Button
          sx={{ gap: 2, width: 'max-content', ...sx }}
          disableRipple
          component="div"
        >
          <Stack direction="row" spacing={2}>
            {status === 'CONFIRM_BY_USER' && (
              <LoadingButton
                loading={isLoading}
                variant="contained"
                onClick={handleClickConfirm}
              >
                {t('common:confirm')}
              </LoadingButton>
            )}

            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="error"
              onClick={handleClickCancelWithdraw}
            >
              {t('common:cancel')}
            </LoadingButton>
          </Stack>
        </Button>
      )}

      <OtpDialog
        show={isShowOtp}
        onClose={() => setIsShowOtp(false)}
        confirmationMethod={confirmationMethod}
        onConfirm={onConfirm}
        resendCodeMethod={resendOtp}
        timer={TTL}
      />
    </>
  )
}
