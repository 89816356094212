import { ChipOwnProps } from '@mui/material'

import { TWithdrawStatus } from '@shared/types'

export const getWithdrawStatusColor = (
  status: TWithdrawStatus
): ChipOwnProps['color'] => {
  switch (status) {
    case 'SUCCESS':
      return 'success'
    case 'CREATED':
      return 'warning'
    case 'CONFIRM_BY_USER':
      return 'primary'
    case 'CANCELED_BY_AML':
    case 'CANCELED_BY_USER':
    default:
      return 'error'
  }
}
