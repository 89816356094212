import {
  createBrowserRouter,
  RouterProvider as ReactRouterProvider,
} from 'react-router-dom'

import { AccountPageRouter } from '@pages/account'
import { AccountsListRouter } from '@pages/accounts-list'
import { ApplicationsListRouter } from '@pages/applications-list'
import { CorporateApplicationRouter } from '@pages/corporate-application'
import { DashboardPageRouter } from '@pages/dashboard'
import { ExchangePageRouter } from '@pages/exchange'
import { ExchangesListRouter } from '@pages/exchanges-list'
import { IndividualApplicationRouter } from '@pages/individual-application'
import { TopUpPageRouter } from '@pages/top-up'
import { TopUpsListRouter } from '@pages/top-ups-list'
import { TransactionsListRouter } from '@pages/transactions-list'
import { UserRouter } from '@pages/user'
import { UsersListRouter } from '@pages/users-list'
import { WalletPageRouter } from '@pages/wallet'
import { WalletsListRouter } from '@pages/wallets-list'
import { WithdrawPageRouter } from '@pages/withdraw'
import { WithdrawsListPageRouter } from '@pages/withdraws-list'

import { MainLayout } from './layouts'

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      UsersListRouter,
      ApplicationsListRouter,
      UserRouter,
      IndividualApplicationRouter,
      CorporateApplicationRouter,
      ExchangesListRouter,
      AccountsListRouter,
      AccountPageRouter,
      TopUpsListRouter,
      TopUpPageRouter,
      WalletsListRouter,
      WithdrawsListPageRouter,
      WithdrawPageRouter,
      ...TransactionsListRouter,
      WalletPageRouter,
      ExchangePageRouter,
      DashboardPageRouter,
    ],
  },
])

export function RouterProvider() {
  return <ReactRouterProvider router={router} />
}
