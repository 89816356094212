import { useEffect, useRef, useState } from 'react'

interface IPayload {
  timer?: number
  onFinish?: () => void
}

export function useTimer({ timer = 120, onFinish }: IPayload) {
  const [time, setTime] = useState<number>(timer)
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (time <= 0) {
      onFinish && onFinish()
    } else {
      timerRef.current = setInterval(() => {
        setTime((prevTime) => prevTime - 1)
      }, 1000)
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [time])

  return { time, setTime }
}
