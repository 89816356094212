import { ChipOwnProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IUsersListItem } from '@shared/types'

type TStatus = IUsersListItem['statusOndato']

export function useOndatoStatus() {
  const { t } = useTranslation('pages')

  const getOndatoStatusColor = (status: TStatus): ChipOwnProps['color'] => {
    switch (status) {
      case 'APPROVED':
        return 'success'
      case 'REJECTED':
      case 'CANCELED':
        return 'error'
      default:
        return 'primary'
    }
  }

  const getOndatoStatus = (status: TStatus) =>
    t(`users list.ondata-statuses.${status}`)

  return { getOndatoStatusColor, getOndatoStatus }
}
